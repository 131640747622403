import TabsCard from '@components/common/TabsCard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OrdersFilterModal from './OrdersFilterModal';
import { useTranslation } from 'react-i18next';
import LimitOrderHistoryItem from './LimitOrderHistoryItem';
import { OrderType } from 'types';
import useDebounce from '@hooks/useDebounce';
import useResponseHandler from '@hooks/useResponseHandler';
import { useStores } from '@hooks/useStores';
import { OrderResponse } from 'types/order/order.response';
import { OrderStatus } from 'types/order/order.enum';
import LimitOrderDetails from './LimitOrderDetails';
import { observer } from 'mobx-react-lite';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { TradeNotificationResponse } from 'types/order/trade-notification.response';
import Cloner from '@components/common/Cloner';
import ListItemSkeleton from '@components/skeletons/ListItemSkeleton';

export const allOrderFilters = Object.values(OrderType);

const LimitOrdersHistory = () => {
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const [w] = useAmplitude();

  const { ordersStore, tokenStore, walletStore, socketStore } = useStores();
  const {
    pairId,
    tokenDetails,
    isExecuting,
    orders,
    nativeTokenPriceUsd,
    limitData,
    chain,
  } = tokenStore;

  const [activeTab, setActiveTab] = useState(t('common.active'));

  const [activeFilters, setActiveFilters] =
    useState<OrderType[]>(allOrderFilters);
  const [executedFilters, setExecutedFilters] =
    useState<OrderType[]>(allOrderFilters);

  const historyTabs: {
    [key: string]: {
      label: string;
      state: [OrderType[], React.Dispatch<React.SetStateAction<OrderType[]>>];
    };
  } = {
    active: {
      label: t('common.active'),
      state: [activeFilters, setActiveFilters],
    },
    executed: {
      label: t('trading.executed'),
      state: [executedFilters, setExecutedFilters],
    },
  };

  const [isOrdersLoaded, setIsOrdersLoaded] = useState<boolean>(false);

  const [orderDetails, setOrderDetails] = useState<OrderResponse | null>(null);

  const isPendingMarket = useMemo(() => {
    return activeTab === t('common.active') && orders && isExecuting;
  }, [activeTab, orders]);

  const currentFilters = useMemo(() => {
    const filters =
      activeTab === t('common.active') ? activeFilters : executedFilters;
    if (filters.length === 0 || filters.length === allOrderFilters.length) {
      return allOrderFilters;
    }
    return filters;
  }, [activeTab, activeFilters, executedFilters]);

  const debounceFilters = useDebounce(currentFilters, 1000);

  const getTokenBalance = () => {
    tokenStore.getTokenBalance();
    walletStore.loadBalance();
  };

  const updateOrders = useCallback(() => {
    if (!pairId || !ordersStore.isInit) return;
    setIsOrdersLoaded(false);

    // TODO: Wallet update after trade
    // walletStore.loadData().then(() => console.log('update wallet data success'));

    tokenStore
      .getOrders(
        activeTab === t('common.active') ? 'ACTIVE' : 'EXECUTED',
        debounceFilters,
        pairId,
      )
      .then((result) => {
        if (Array.isArray(result)) {
          if (
            !isOrdersLoaded &&
            activeTab === t('common.active') &&
            result.length === 0
          ) {
            setActiveTab(t('trading.executed'));
            setIsOrdersLoaded(false);
            return;
          }
          setIsOrdersLoaded(true);
        }
      })
      .catch((error) => {
        console.error('Error loading orders', error);
        handleResponse(t('common.error'), true);
      });
  }, [pairId, ordersStore.isInit, activeTab, debounceFilters, isOrdersLoaded]);

  const autoSwitchTab = () => {
    if (
      orders?.length ||
      orders?.every(
        (order) =>
          order.status === OrderStatus.EXECUTING &&
          [OrderType.MARKET_BUY, OrderType.MARKET_SELL].includes(order.type),
      )
    ) {
      setTimeout(() => {
        getTokenBalance();
        ordersStore.loadBalance();
        setActiveTab(t('trading.executed'));
      }, 200);
    }
  };

  const tradeEventHandler =
    (isSuccess: boolean, pairId: string | undefined) =>
    (data: TradeNotificationResponse) => {
      if (data.pairId.toLowerCase() === pairId?.toLowerCase()) {
        updateOrders();
        handleResponse(
          t(isSuccess ? 'trading.order-executed' : 'trading.order-failed'),
          !isSuccess,
        );
        if (isSuccess) getTokenBalance();
        autoSwitchTab();
      }
    };

  useEffect(() => {
    enum Events {
      SUCCESS_TRADE_NOTIFICATION = 'SUCCESS_TRADE_NOTIFICATION',
      FAILED_TRADE_NOTIFICATION = 'FAILED_TRADE_NOTIFICATION',
    }

    socketStore.socket.on(
      Events.SUCCESS_TRADE_NOTIFICATION,
      tradeEventHandler(true, pairId),
    );
    socketStore.socket.on(
      Events.FAILED_TRADE_NOTIFICATION,
      tradeEventHandler(false, pairId),
    );

    return () => {
      socketStore.socket.off(Events.SUCCESS_TRADE_NOTIFICATION);
      socketStore.socket.off(Events.FAILED_TRADE_NOTIFICATION);
    };
  }, [activeTab, debounceFilters, socketStore.socket, pairId]);

  useEffect(() => {
    updateOrders();
  }, [pairId, activeTab, debounceFilters, ordersStore.isInit, chain]);

  const isLoaded = useMemo(() => {
    return isPendingMarket ? true : isOrdersLoaded;
  }, [isPendingMarket, isOrdersLoaded]);

  const handleCloseDetails = () => {
    setOrderDetails(null);
  };

  return (
    <>
      <div className="row flex-1 mt-2">
        <div
          className="card d-flex flex-column pb-0 mn-ht-300"
          id="assets-card"
        >
          <TabsCard
            list={[
              { value: t('common.active') },
              { value: t('trading.executed') },
            ]}
            active={activeTab}
            onClick={(tab) =>
              w(
                setActiveTab,
                tab === t('common.active')
                  ? AmplitudeEvent.TOKEN_PAGE_ACTIVE_TAB_CLICKED
                  : AmplitudeEvent.TOKEN_PAGE_EXECUTED_TAB_CLICKED,
                { token: tokenDetails },
              )(tab)
            }
            noAnimation
          >
            <div>
              {orders &&
                Object.values(historyTabs).map(
                  ({ label, state: [filters, setFilters] }) => {
                    return (
                      <div
                        key={label}
                        className={`tab-element tab-${t(label)}`}
                      >
                        <OrdersFilterModal
                          filters={filters}
                          updateFilters={setFilters}
                          isLoading={!isLoaded}
                        />
                        {!isLoaded && (
                          <Cloner className="mb-3 pb-3" count={2}>
                            <ListItemSkeleton noCircle />
                          </Cloner>
                        )}
                        {isLoaded && !orders.length && (
                          <div className="tx-center">
                            {t('order.no-active-orders')}
                          </div>
                        )}
                        {isLoaded &&
                          orders.map((order, index) => (
                            <LimitOrderHistoryItem
                              item={order}
                              isLast={index === orders.length - 1}
                              key={`limit-order-active-${tokenDetails?.pairAddress}-${order.id}`}
                              onClick={() => setOrderDetails(order)}
                              limitData={limitData}
                              nativePrice={nativeTokenPriceUsd}
                            />
                          ))}
                      </div>
                    );
                  },
                )}
            </div>
          </TabsCard>
          <div className="mn-ht-50">&nbsp;</div>
        </div>
      </div>

      <LimitOrderDetails
        item={orderDetails}
        limitData={limitData}
        nativePrice={nativeTokenPriceUsd}
        onClose={handleCloseDetails}
        onCancel={updateOrders}
      />
    </>
  );
};

export default observer(LimitOrdersHistory);
