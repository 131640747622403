import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import BigNumber from 'bignumber.js';
import './i18n';
import { init, isTMA, backButton } from '@telegram-apps/sdk-react';
import './mock-telegram';
import AppRouter from './AppRouter';
import { isLocalhost } from '@helpers/device';
import { hasOwnProperty } from '@helpers/object';

BigNumber.config({ EXPONENTIAL_AT: 100, DECIMAL_PLACES: 36 });

const render = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <AppRouter>
      {/*<BrowserRouter>*/}
      {/*<TelegramProvider>*/}
      <App />

      <Toaster
        toastOptions={{
          style: {
            border: 'none',
          },
          success: {
            style: {
              background: '#1C1C1C',
              color: '#32D74B',
            },
          },
          error: {
            style: {
              background: '#1C1C1C',
              color: '#FF453A',
            },
          },
        }}
      />
      {/*</TelegramProvider>*/}
      {/*</BrowserRouter>*/}
    </AppRouter>,
  );
}

// Init Telegram SDK
if (!isLocalhost() && hasOwnProperty(window, 'Telegram')) {
  init({
    acceptCustomStyles: true,
  });
  // Mount the Back Button, so we will work with
  // the actual component properties.
  backButton.mount();
  setTimeout(() => {
    render();
  }, 10);
} else {
  render();
}
