import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { WalletBalance } from '../../types';
import { WalletResponse } from '../../types/wallet/wallet.response';
import UserAvatar from '@components/common/UserAvatar';
import FormattedNumber from '@components/common/FormattedNumber';
import Check from '@components/common/Check';

interface WalletListItemProps {
  wallet: WalletResponse;
  onClick: () => void;
  selected: boolean;
}

const WalletListItem = observer(({ wallet, onClick, selected }: WalletListItemProps) => {
  const { walletStore } = useStores();
  const { id, address, name } = wallet;

  const [balance, setBalance] = useState<WalletBalance>();

  useEffect(() => {
    walletStore.loadBalance(id).then((b) => {
      if (b) {
        setBalance(b);
      }
    });
  }, [id]);

  return (
    <div className="d-flex align-items-center justify-content-between tx-14 cur-pointer" onClick={onClick}>
      <UserAvatar string={address} size={30} />
      <div className="ms-2 me-auto">{name}</div>
      <FormattedNumber
        value={balance?.balanceInUsd}
        suffix="$"
        className="me-2"
      />
      <Check checked={selected} />
    </div>
  );
});

export default WalletListItem;
