import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  computed,
} from 'mobx';
import { TokenError, TokenStore } from '@stores/token-store';
import {
  AlphaOrderLastLevelReachStrategy,
  OrderTransactionType,
  OrderTrigger,
  OrderType,
  OrderVariant,
} from 'types/order/order.enum';
import { OrderResponse } from 'types/order/order.response';
import BigNumber from 'bignumber.js';
import { AxiosError } from 'axios';
import api from '@helpers/api';
import { PageRoutes } from '../constants';
import { BaseChainToken, Blockchain } from 'types/enums';
import { defaultOrderSettings, SettingsStore } from './settings-store';
import { IOrderSettings } from '../types';
import clone from '@helpers/clone';
import { getPercentageDifference } from '@helpers/numbers';

export type TradeParams = Record<'chain' | 'address' | 'id', string>;

export type TpSlValue = {
  tokenPercents: number | null;
  value: number | null;
  settings?: IOrderSettings;
};

export type TpSlValueRequest = {
  tokenPercents: number | null;
  percents: boolean;
  value: number | null;
  settings?: IOrderSettings;
};

export type TpSlOrders = {
  trigger: OrderTrigger;
  percents: boolean;
  takeProfits: TpSlValue[] | null;
  stopLosses: TpSlValue[] | null;
};

export type TpSlOrdersRequest = {
  trigger: OrderTrigger;
  takeProfits: TpSlValueRequest[] | null;
  stopLosses: TpSlValueRequest[] | null;
};

export type AlphaOrderResponse = {
  alphaLevels: { isActive: boolean; triggerValue: string }[];
  autoLevelStep?: number;
  lastLevelReachStrategy: AlphaOrderLastLevelReachStrategy;
  nextTriggerValue: string;
  trailing?: Record<'percent', number>;
};

export type AlphaOrderRequest = {
  alphaLevels: Record<'triggerValue', string>[];
  autoLevelStep?: number;
  lastLevelReachStrategy: AlphaOrderLastLevelReachStrategy;
  trailing?: Record<'percent', number>;
};

export class TokenTrade {
  private _tokenStore: TokenStore;
  private _settingStore: SettingsStore;

  @observable
  private _init: boolean | null;

  @observable
  private _error: TokenError | null;

  @observable
  private _id: string | null;

  @observable
  private _chain: string | null;

  @observable
  private _currency: number | null;

  @observable
  private _address: string | null;

  @observable
  private _order: OrderResponse | null;

  @observable
  private _buySell: OrderTransactionType | null = OrderTransactionType.BUY;

  @observable
  private _buyOrderType: OrderVariant | null = OrderVariant.MARKET;

  @observable
  private _sellOrderType: OrderVariant | null = OrderVariant.MARKET;

  @observable
  private _buyAmount: string | number | null = null;

  @observable
  private _sellAmount: string | number | null = null;

  @observable
  private _buyAmountUsd: string | number | null = null;

  @observable
  private _sellAmountUsd: string | number | null = null;

  @observable
  private _amountPercentMode: boolean = false;

  @observable
  private _triggerAmount: string | number | null = null;

  @observable
  private _triggerPercent: string | number | null = null;

  @observable
  private _triggerPercentMode: boolean = false;

  @observable
  private _triggerType: OrderTrigger | null = OrderTrigger.PRICE_IN_USD;

  @observable
  private _emptyInputsLightOn: boolean = false;

  @observable
  private _tpSlOrders: TpSlOrders = {
    trigger: OrderTrigger.PRICE_IN_USD,
    percents: true,
    takeProfits: null,
    stopLosses: null,
  };

  @observable
  private _alphaOrder: AlphaOrderRequest = {
    alphaLevels: [],
    lastLevelReachStrategy: AlphaOrderLastLevelReachStrategy.EXECUTE_DEFAULT,
  };

  @observable
  private _hasAlphaOrderError: boolean;

  constructor(tokenStore: TokenStore, settingStore: SettingsStore) {
    this._tokenStore = tokenStore;
    this._settingStore = settingStore;
    makeAutoObservable(this);

    reaction(
      () => this._tokenStore.tokenDetails?.usdPrice,
      () => {
        if (!this.triggerPercentMode) {
          this.setTriggerPercent(this.diff);
        }

        if (
          this._tokenStore.tokenDetails &&
          this.triggerPercentMode &&
          this.triggerPercent
        ) {
          const { usdPrice, priceInBaseToken } = this._tokenStore.tokenDetails;

          const price =
            this.triggerType === OrderTrigger.PRICE_IN_USD
              ? usdPrice
              : priceInBaseToken;

          const percent = new BigNumber(this.triggerPercent!)
            .abs()
            .times(this.isBuy || this.isStopLoss ? -1 : 1);

          if (price) {
            const usd = new BigNumber(price);
            const newTriggerAmount = usd
              .plus(usd.times(percent).div(100))
              .valueOf();

            this.setTriggerAmount(newTriggerAmount);
          }
        }
      },
    );
  }

  // Геттеры свойств as is
  @computed
  get orderInit() {
    return this._init;
  }

  @computed
  get error() {
    return this._error;
  }

  @computed
  get id() {
    return this._id;
  }

  @computed
  get chain() {
    return this._chain;
  }

  @computed
  get currency() {
    return this._currency;
  }

  @computed
  get address() {
    return this._address;
  }

  @computed
  get order() {
    return this._order;
  }

  @computed
  get buySell() {
    return this._buySell;
  }

  @computed
  get buyOrderType() {
    return this._buyOrderType;
  }

  @computed
  get sellOrderType() {
    return this._sellOrderType;
  }

  @computed
  get buyAmount() {
    return isNaN(Number(this._buyAmount)) ? 0 : this._buyAmount;
  }

  @computed
  get sellAmount() {
    return isNaN(Number(this._sellAmount)) ? 0 : this._sellAmount;
  }

  @computed
  get buyAmountUsd() {
    return isNaN(Number(this._buyAmountUsd)) ? 0 : this._buyAmountUsd;
  }

  @computed
  get sellAmountUsd() {
    return isNaN(Number(this._sellAmountUsd)) ? 0 : this._sellAmountUsd;
  }

  @computed
  get amountPercentMode() {
    return this._amountPercentMode;
  }

  @computed
  get triggerAmount() {
    return this._triggerAmount;
  }

  @computed
  get triggerPercent() {
    return this._triggerPercent;
  }

  @computed
  get triggerType() {
    return this._triggerType;
  }

  // Вычисляемые свойства
  @computed
  get isBuy() {
    return this.buySell === OrderTransactionType.BUY;
  }

  @computed
  get isMarket() {
    return this.isBuy
      ? this.buyOrderType === OrderVariant.MARKET
      : this.sellOrderType === OrderVariant.MARKET;
  }

  @computed
  get isLimit() {
    return this.isBuy
      ? this.buyOrderType === OrderVariant.LIMIT
      : this.sellOrderType === OrderVariant.LIMIT;
  }

  @computed
  get isStopLoss() {
    return this.isBuy ? false : this.sellOrderType === OrderVariant.STOP_LOSS;
  }

  @computed
  get diff() {
    const token = this._tokenStore.tokenDetails;
    const { price, priceBase } = {
      price: token?.usdPrice ?? 0,
      priceBase: token?.priceInBaseToken ?? 0,
    };

    const value: number | string =
      this.triggerType === OrderTrigger.PRICE_IN_USD ? price : priceBase;

    return token && this.triggerAmount
      ? new BigNumber(getPercentageDifference(this.triggerAmount, value))
          .abs()
          .toNumber()
      : 0;
  }

  @computed
  get isAlpha() {
    return this.isBuy
      ? this.buyOrderType === OrderVariant.ALPHA
      : this.sellOrderType === OrderVariant.ALPHA;
  }

  @computed
  get isStopLossesEnabled() {
    return (
      Array.isArray(this._tpSlOrders.stopLosses) &&
      !!this._tpSlOrders.stopLosses.length
    );
  }

  @computed
  get isTakeProfitsEnabled() {
    return (
      Array.isArray(this._tpSlOrders.takeProfits) &&
      !!this._tpSlOrders.takeProfits.length
    );
  }

  @computed
  get isTpSlPercentMode() {
    return this._tpSlOrders.percents;
  }

  @computed
  get isTpSlTrigger() {
    return this._tpSlOrders.trigger;
  }

  @computed
  get tpSlOrders(): TpSlOrdersRequest | undefined {
    if (!this.isBuy) return;
    if (!(this.isStopLossesEnabled || this.isTakeProfitsEnabled)) return;
    const { trigger, takeProfits, stopLosses, percents } = this._tpSlOrders;
    const { takeProfitSettings, stopLossSettings } = this._settingStore;

    const replaceDefaultSettings = (orderSettings: IOrderSettings) => {
      const settings: IOrderSettings = clone(
        orderSettings ?? defaultOrderSettings(this.chain as Blockchain),
      );

      if (settings.slippage === null) settings.slippage = 5;
      if (settings.extraGasPricePercent === null)
        settings.extraGasPricePercent = 10;
      if (settings.maxGasPrice === null) settings.maxGasPrice = 200;

      return settings;
    };

    return {
      trigger,
      takeProfits:
        takeProfits?.map((o) => ({
          ...o,
          percents,
          settings: replaceDefaultSettings(
            takeProfitSettings
              ? takeProfitSettings[this.chain!]
              : defaultOrderSettings(this.chain as Blockchain),
          ),
        })) ?? [],
      stopLosses:
        stopLosses?.map((o) => ({
          ...o,
          percents,
          settings: replaceDefaultSettings(
            stopLossSettings
              ? stopLossSettings[this.chain!]
              : defaultOrderSettings(this.chain as Blockchain),
          ),
        })) ?? [],
    };
  }

  @computed
  get stopLosses() {
    return this._tpSlOrders.stopLosses;
  }

  @computed
  get takeProfits() {
    return this._tpSlOrders.takeProfits;
  }

  @computed
  get emptyInputsLightOn() {
    return this._emptyInputsLightOn;
  }

  @computed
  get stopLossesError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.stopLosses
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.stopLosses?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return this.isAlpha || !this.isBuy
      ? []
      : [
          hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
          hasEmpty ? 'order.all-fields-required-error' : null,
        ];
  }

  @computed
  get takeProfitsError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.takeProfits
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.takeProfits?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return this.isAlpha || !this.isBuy
      ? []
      : [
          hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
          hasEmpty ? 'order.all-fields-required-error' : null,
        ];
  }

  @computed
  get orderType() {
    if (this.isBuy) {
      if (this.isMarket) return OrderType.MARKET_BUY;
      if (this.isLimit) return OrderType.LIMIT_BUY;
      if (this.isAlpha) return OrderType.STOP_BUY;
    } else {
      if (this.isMarket) return OrderType.MARKET_SELL;
      if (this.isLimit) return OrderType.LIMIT_SELL;
      if (this.isAlpha) return OrderType.STOP_LOSS;
    }
    return OrderType.STOP_LOSS;
  }

  @computed
  get triggerPercentMode() {
    return this._triggerPercentMode;
  }

  @computed
  get alphaOrder() {
    return this.isAlpha
      ? clone<typeof this._alphaOrder>(this._alphaOrder)
      : undefined;
  }

  @computed
  get hasAlphaOrderError() {
    return this.isAlpha && (this._hasAlphaOrderError ?? true);
  }

  @action.bound
  initialize() {
    if (!this.id) this.setOrderInit(true);

    if (
      this.id &&
      this.currency &&
      this._tokenStore &&
      this._tokenStore.tokenBalance &&
      this._tokenStore.balance &&
      !this.orderInit
    ) {
      if (!this.order) {
        this.getOrder(this.id);
      } else {
        const isBuy = this.order.type.toLowerCase().includes('buy');
        this.setBuySell(
          isBuy ? OrderTransactionType.BUY : OrderTransactionType.SELL,
        );
        if (isBuy) {
          if (this.order.metadata.alphaOrder)
            this.setBuyOrderType(OrderVariant.ALPHA);
          else
            this.setBuyOrderType(
              this.order.type === OrderType.MARKET_BUY
                ? OrderVariant.MARKET
                : OrderVariant.LIMIT,
            );
        } else {
          if (this.order.metadata.alphaOrder)
            this.setSellOrderType(OrderVariant.ALPHA);
          else
            this.setSellOrderType(
              this.order.type === OrderType.MARKET_SELL
                ? OrderVariant.MARKET
                : this.order.type === OrderType.LIMIT_SELL
                  ? OrderVariant.LIMIT
                  : OrderVariant.STOP_LOSS,
            );
        }
        if (this.order.trigger) {
          this.setTriggerType(this.order.trigger);
          this.setTriggerType(this.order.trigger);
          this.setTriggerAmount(this.order.targetTriggerValue);
        }
        const percent = new BigNumber(100)
          .dividedBy(
            isBuy
              ? this._tokenStore.balance!.balance
              : this._tokenStore.tokenBalance!.tokenBalance,
          )
          .multipliedBy(this.order.valueIn);
        if (isBuy) {
          this.setBuyAmount(this.order.valueIn);
          this.setBuyAmountUsd(
            new BigNumber(this.order.valueIn)
              .multipliedBy(this.currency!)
              .toString(),
          );
        } else {
          this.setSellAmount(this.order.valueIn);
          this.setSellAmountUsd(
            new BigNumber(this._tokenStore.tokenBalance!.tokenBalanceInUsd || 0)
              .multipliedBy(1 / percent.toNumber())
              .toString(),
          );
        }

        if (this._order!.metadata.tpSlOrders) {
          const { tpSlOrders } = this._order!.metadata;

          this.switchTpSlTrigger(tpSlOrders.trigger);
          if (tpSlOrders.stopLosses?.length)
            this.switchTpSlPercentMode(
              tpSlOrders.stopLosses[0]?.percents,
              true,
            );
          if (tpSlOrders.takeProfits?.length)
            this.switchTpSlPercentMode(
              tpSlOrders.takeProfits[0]?.percents,
              true,
            );

          this._tpSlOrders = {
            ...tpSlOrders,
            percents: this.isTpSlPercentMode,
          };
        }

        this.setOrderInit(true);
      }
    }
  }

  @action.bound
  async errorCatcher(response: AxiosError | TokenError) {
    if (response instanceof AxiosError && response.response?.data) {
      console.error(response.response.data);
    }
    if (response instanceof TokenError) {
      this._error = response;
    } else {
      console.error(response);
      return response.response;
    }
  }

  @action.bound
  setBuySell(v: typeof this.buySell) {
    this.setBuyOrderType(OrderVariant.MARKET);
    this.setSellOrderType(OrderVariant.MARKET);
    this.resetAmounts();

    this._buySell = v;
  }

  @action.bound
  setOrderInit(v: boolean) {
    this._init = v;
  }

  @action.bound
  setRouteParams(params: TradeParams) {
    this._chain = params.chain;
    this.getCurrency();
    this._address = params.address;
    this._id = params.id;
  }

  @action.bound
  setBuyOrderType(v: typeof this.buyOrderType) {
    this._buyOrderType = v;
  }

  @action.bound
  setSellOrderType(v: typeof this.sellOrderType) {
    this.resetAmounts();
    this._sellOrderType = v;
  }

  @action.bound
  setBuyAmount(v: typeof this.buyAmount) {
    this._buyAmount = v;
  }

  @action.bound
  setBuyAmountUsd(v: typeof this.buyAmountUsd) {
    this._buyAmountUsd = v;
  }

  @action.bound
  setSellAmount(v: typeof this.sellAmount) {
    this._sellAmount = v;
  }

  @action.bound
  switchAmountMode() {
    this._amountPercentMode = !this.amountPercentMode;
  }

  @action.bound
  setSellAmountUsd(v: typeof this.sellAmountUsd) {
    this._sellAmountUsd = v;
  }

  @action.bound
  setTriggerAmount(v: typeof this.triggerAmount) {
    this._triggerAmount = v;
  }

  @action.bound
  setTriggerPercent(v: typeof this.triggerPercent) {
    this._triggerPercent = v;
  }

  @action.bound
  setTriggerType(v: typeof this.triggerType) {
    this.resetAmounts();
    this._triggerType = v;
  }

  @action.bound
  switchTakeProfits(v: boolean) {
    this._tpSlOrders.takeProfits = v
      ? [{ value: null, tokenPercents: null }]
      : null;
  }

  @action.bound
  switchStopLosses(v: boolean) {
    this._tpSlOrders.stopLosses = v
      ? [{ value: null, tokenPercents: null }]
      : null;
  }

  @action.bound
  switchTpSlPercentMode(v: boolean, noReaction: boolean = false) {
    this._tpSlOrders.percents = v;
    const [isPercent, tokenUsdPrice] = [v, this._tokenStore.tokenUsdPrice];

    const calc = (value: number | null, isProfit: boolean) => {
      if (noReaction || value === null) return value;

      const bnValue = new BigNumber(value);
      const bnTokenUsdPrice = new BigNumber(tokenUsdPrice);

      const calculation = !isPercent
        ? bnTokenUsdPrice[isProfit ? 'plus' : 'minus'](
            bnTokenUsdPrice.times(bnValue).div(100).abs(),
          ).toNumber()
        : bnValue
            .minus(bnTokenUsdPrice)
            .abs()
            .div(tokenUsdPrice)
            .times(100)
            .decimalPlaces(2)
            .toNumber();

      return calculation;
    };

    [this._tpSlOrders.stopLosses, this._tpSlOrders.takeProfits].forEach(
      (value, isProfit) => {
        if (value)
          value = value.map((o) => {
            o.value = calc(o.value, !!isProfit);
            return o;
          });
      },
    );
  }

  @action.bound
  switchTpSlTrigger(v: OrderTrigger) {
    this._tpSlOrders.trigger = v;
    this.resetTpSlValues();
  }

  @action.bound
  resetTpSlValues() {
    this._tpSlOrders.takeProfits?.map((o) => {
      o.value = null;
      o.tokenPercents = null;
      return o;
    });
    this._tpSlOrders.stopLosses?.map((o) => {
      o.value = null;
      o.tokenPercents = null;
      return o;
    });
  }

  @action.bound
  switchTriggerMode() {
    this._triggerPercentMode = !this.triggerPercentMode;
  }

  @action.bound
  addTakeProfitValue() {
    this._tpSlOrders.takeProfits!.push({
      value: null,
      tokenPercents: null,
    });
  }

  @action.bound
  setTakeProfits(values: TpSlValue[]) {
    this._tpSlOrders.takeProfits = values;
  }

  @action.bound
  addStopLossValue() {
    this._tpSlOrders.stopLosses!.push({
      value: null,
      tokenPercents: null,
    });
  }

  @action.bound
  setStopLosses(values: TpSlValue[]) {
    this._tpSlOrders.stopLosses = values;
  }

  @action.bound
  removeTakeProfitValue(idx: number) {
    this._tpSlOrders.takeProfits!.splice(idx, 1);
  }

  @action.bound
  removeStopLossValue(idx: number) {
    this._tpSlOrders.stopLosses!.splice(idx, 1);
  }

  @action.bound
  setTakeProfitValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) =>
      runInAction(() => {
        if (
          this._tpSlOrders.takeProfits &&
          Array.isArray(this._tpSlOrders.takeProfits)
        ) {
          if (input === 'percents') {
            this._tpSlOrders.takeProfits[idx]!.value = value as number;
          } else if (input === 'value') {
            this._tpSlOrders.takeProfits[idx]!.tokenPercents = value as number;
          }
        }
      });
  }

  @action.bound
  setStopLossValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) =>
      runInAction(() => {
        if (
          this._tpSlOrders.stopLosses &&
          Array.isArray(this._tpSlOrders.stopLosses)
        ) {
          if (input === 'percents') {
            this._tpSlOrders.stopLosses[idx]!.value = value as number;
          } else if (input === 'value') {
            this._tpSlOrders.stopLosses[idx]!.tokenPercents = value as number;
          }
        }
      });
  }

  @action.bound
  setEmptyInputsLightOn() {
    this._emptyInputsLightOn = true;
  }

  @action.bound
  async getOrder(id: string) {
    return api<OrderResponse>({
      method: 'get',
      path: `/order/${id}`,
    })
      .then((response) => {
        this._order = response;
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...response.response.data,
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  @action.bound
  async getCurrency() {
    return api<any>({
      method: 'get',
      path: `/token/price`,
    })
      .then((response) => {
        //@ts-ignore
        const baseToken = BaseChainToken[this.chain!];
        this._currency = response[baseToken];
        return response;
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...response.response.data,
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  @action.bound
  async setAlphaOrder(v: typeof this._alphaOrder) {
    this._alphaOrder = v;
  }

  @action.bound
  async setHasAlphaOrderError(v: boolean) {
    this._hasAlphaOrderError = v;
  }

  @action.bound
  async resetAmounts() {
    this.setTriggerAmount(null);
    this.setTriggerPercent(null);
  }

  @action.bound
  async reset() {
    this._init = null;
    this._error = null;
    this._id = null;
    this._chain = null;
    this._currency = null;
    this._address = null;
    this._order = null;
    this._buySell = OrderTransactionType.BUY;
    this._buyOrderType = OrderVariant.MARKET;
    this._sellOrderType = OrderVariant.MARKET;
    this._buyAmount = null;
    this._sellAmount = null;
    this._buyAmountUsd = null;
    this._sellAmountUsd = null;
    this._amountPercentMode = false;
    this._triggerAmount = null;
    this._triggerPercent = null;
    this._triggerType = OrderTrigger.PRICE_IN_USD;
    this._tpSlOrders = {
      trigger: OrderTrigger.PRICE_IN_USD,
      percents: true,
      takeProfits: null,
      stopLosses: null,
    };
    this._emptyInputsLightOn = false;
    this._triggerPercentMode = false;
    this._alphaOrder = {
      alphaLevels: [],
      lastLevelReachStrategy: AlphaOrderLastLevelReachStrategy.EXECUTE_DEFAULT,
    };
  }
}
