import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from '@hooks/useStores';
import { ethers } from 'ethers';
import { LiFiWidget, RouteExecutionUpdate, useWidgetEvents, WidgetConfig, WidgetEvent } from '@lifi/widget';
import { SendFormParams } from '@pages/Send';
import { chainIdNumber } from '@helpers/chains';
import CustomWallet from '@pages/Swap/CustomWallet';
import { ChainTokenSelected } from '@lifi/widget/types/events';
import type { Route } from '@lifi/sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import PageLoader from '@components/PageLoader';
import useNotification from '@hooks/useNotification';

const HARDCODED_ANTI_MEV_ETH_RPC_URL = 'https://rpc.mevblocker.io';
const Swap = observer((props: SendFormParams) => {
  const { network, asset, assetReceive } = props;
  const { accountStore, walletStore } = useStores();
  const { connectWalletInfo } = accountStore;
  const navigate = useNavigate();
  const { state } = useLocation();
  const notify = useNotification();

  useEffect(() => {
    notify('SWAP is temporarily unavailable due to technical works', { type: 'danger', duration: 5000 });
    navigate(-1);
  }, []);

  const connect = (chainId: number) => {
    if (!connectWalletInfo) {
      return;
    }

    let rpcUrl: string | null;
    if (chainId === 1) {
      rpcUrl = HARDCODED_ANTI_MEV_ETH_RPC_URL;
    } else {
      rpcUrl = connectWalletInfo.rpcUrls.find(rpc => rpc.chainId === chainId)?.url || null;
    }

    if (!rpcUrl) {
      throw Error(`Can't find RPC for ${chainId} chain`);
    }
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl, chainId);
    const wallet = new CustomWallet(connectWalletInfo.addresses[0].privateKey, provider);

    return {
      provider,
      wallet,
    };
  };

  const widgetEvents = useWidgetEvents();
  useEffect(() => {
    const onRouteExecutionFailed = (update: RouteExecutionUpdate) => {
      alert('Swap unsuccessful. Consider adjusting the slippage higher.');
    };

    const onRouteExecutionCompleted = (route: Route) => {
      walletStore.loadData().then(() => {
        navigate(PageRoutes.WALLET);
      });
    };

    const onSourceChainTokenSelected = (update: ChainTokenSelected) => {
      // ToDo: fix toChain update
      // if (widgetConfig) {
      //   widgetConfig.toChain = update.chainId
      // }
    };

    // widgetEvents.on(WidgetEvent.RouteExecutionCompleted, onRouteExecutionCompleted);
    widgetEvents.on(WidgetEvent.RouteExecutionFailed, onRouteExecutionFailed);
    widgetEvents.on(WidgetEvent.SourceChainTokenSelected, onSourceChainTokenSelected);

    return () => widgetEvents.all.clear();
  }, [widgetEvents]);

  let connection = connect(chainIdNumber(network));

  const handleSwitch = (chainId: number) => {
    const newData = connect(chainId);
    connection = newData;
    return newData!.wallet;
  };

  const widgetConfig = useMemo((): WidgetConfig | undefined => {
    return !connectWalletInfo ? undefined : {
      integrator: 'alpha-wallet',
      // fee: 0.0065,
      fee: 0,
      hiddenUI: ['poweredBy', 'history', 'appearance', 'language'],
      disableLanguageDetector: true,
      fromChain: asset ? chainIdNumber(network) : 1,
      toChain: assetReceive ? chainIdNumber(network) : 1,
      fromToken: state?.from || asset?.address || '0x0000000000000000000000000000000000000000', // Native token
      toToken: state?.to || assetReceive?.address || (asset?.address ? '0x0000000000000000000000000000000000000000' : undefined),
      chains: {
        allow: [1, 56, 137, 42161, 43114, 8453],
      },
      containerStyle: {
        background: '#1C1C1C',
      },
      theme: {},
      appearance: 'dark',
      insurance: false,
      languageResources: {
        en: {
          header: {
            exchange: 'Swap',
          },
          button: {
            connectWallet: 'Loading...',
            startSwapping: 'Confirm',
          },
          settings: {
            resetSettings: 'Resetting the settings will restore all configurations to their original defaults',
          },
          main: {
            process: {
              crossChain: {
                actionRequired: 'Signing transaction...',
              },
              swap: {
                started: 'Preparing swap transaction',
                actionRequired: 'Signing swap transaction...',
                pending: 'Waiting for swap confirmation',
              },
              switchChain: {
                actionRequired: 'Switching chain...',
              },
              tokenAllowance: {
                pending: 'Waiting for confirmation',
              },
            },
          },
          warning: {
            title: {
              highValueLoss: 'High value loss',
            },
            message: {
              highValueLoss: 'The value of the received tokens is significantly lower than the exchanged tokens and transaction cost.',
            },
          },
        },
      },
      sdkConfig: {
        defaultRouteOptions: {
          maxPriceImpact: 1,
        },
        rpcs: connectWalletInfo.rpcUrls.reduce((result: Record<number, string[]>, rpc) => {
          result[rpc.chainId] = [rpc.chainId === 1 ? HARDCODED_ANTI_MEV_ETH_RPC_URL : rpc.url];
          return result;
        }, {}),
      },
      slippage: 0.005,
      walletManagement: {
        signer: connection!.wallet,
        connect: async () => {
          return connection!.wallet;
        },
        disconnect: async () => {
          /*do nothing*/
        },
        switchChain: async (chainId: number) => {
          return handleSwitch(chainId);
        },
      },
    };
  }, [connection, asset, assetReceive]);

  if (!connectWalletInfo || !widgetConfig) {
    return <PageLoader text="Loading wallet..." />;
  }

  return (
    <div className="full-page d-flex flex-column">
      <LiFiWidget
        integrator="alpha-wallet"
        config={widgetConfig}
      />
    </div>
  );
});

export default Swap;
