import React from 'react';
import Placeholder from '@components/common/Placeholder';
import ListItemSkeleton from '@components/skeletons/ListItemSkeleton';

export default function TradeTokenSkeleton() {
  return (
    <div className="d-flex flex-column justify-content-between card mb-3">
      <ListItemSkeleton />
      <div className="d-flex flex-row align-items-center justify-content-between gap-2 mb-2 mt-3">
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between gap-2 mb-3">
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
        <Placeholder width={100} height={52} className="flex-1" fullWidth />
      </div>
      <Placeholder width={100} height={32} fullWidth />
    </div>
  );
}
