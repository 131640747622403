import React from 'react';
import Placeholder from '@components/common/Placeholder';
import ListItemSkeleton from '@components/skeletons/ListItemSkeleton';

export default function ResearchItemSkeleton() {
  return (
    <div className="d-flex flex-column justify-content-between card">
      <ListItemSkeleton />
      <Placeholder width={100} height={52} className="wd-100p mt-3" fullWidth />
      <Placeholder width={100} height={52} className="wd-100p mt-2 mb-3" fullWidth />
      <div className="d-flex flex-row align-items-center justify-content-between gap-2">
        <Placeholder width={32} height={32} />
        <Placeholder width={100} height={32} className="flex-1" fullWidth />
        <Placeholder width={32} height={32} />
      </div>
    </div>
  )
}
