import React, { useEffect, useMemo, useState } from 'react';
import { ChainId, ethRegex } from '../../constants';
import { observer } from 'mobx-react-lite';
import SendTokenSelect from '@pages/Send/SendTokenSelect';
import SendForm from '@pages/Send/SendForm';
import { IAsset } from '../../types';
import { chainToken } from '@helpers/chains';
import { useStores } from '@hooks/useStores';
import SendConfirm, { SendConfirmProps } from '@pages/Send/SendConfirm';
import { useLocation, useNavigate } from 'react-router-dom';
import Swap from '@pages/Swap';
import BigNumber from 'bignumber.js';
import useNotification from '@hooks/useNotification';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { PortfolioToken } from 'types/portfolio.model';

interface SendParams {
  network: ChainId;
}

export enum SendPageType {
  SEND = 'send',
  SWAP = 'swap',
  CONFIRM = 'confirm',
}

interface SendPageProps {
  type?: SendPageType;
}

export interface SendTokenSelectParams extends SendParams {
  setAsset: (asset: PortfolioToken | null) => void;
  isReceiveSelection: boolean;
}

export interface SendFormParams extends SendParams {
  setNetwork: (network: ChainId) => void;
  amount: string;
  numericAmount: string;
  setAmount: (amount: string) => void;
  address: string;
  setAddress: (address: string) => void;
  asset: PortfolioToken;
  assetReceive: PortfolioToken | null;
  nativeToken?: PortfolioToken;
  slippage: string;
  setSlippage: (value: string) => void;
}

const Send = observer(({ type = SendPageType.SEND }: SendPageProps) => {
  const { accountStore, swapStore, ordersStore, walletStore } = useStores();
  const { currentWallet } = accountStore;
  const { assets, assetsLoaded } = walletStore;
  const { network } = ordersStore;
  const { isReceiveSelection, isTokenSelection } = swapStore;
  const navigate = useNavigate();
  const [w] = useAmplitude();
  const [assetSend, setAssetSend] = useState<PortfolioToken>();
  const [assetReceive, setAssetReceive] = useState<PortfolioToken | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [slippage, setSlippage] = useState<string>('');
  const [navAsset, setNavAsset] = useState<PortfolioToken | null>();
  const [navAssetReceive, setNavAssetReceive] = useState<PortfolioToken | null>();
  const [isInit, setIsInit] = useState(false);
  const location = useLocation();
  const notify = useNotification();

  const networkAssets = useMemo(() => {
    return assets;
  }, [network, assets]);

  const setNetwork = (_net: ChainId) => {
    accountStore.setNetwork(_net);
  };

  useEffect(() => {
    setAssetSend(undefined);
  }, [network, currentWallet]);

  const numericAmount = useMemo(() => {
    return amount !== ''
      ? new BigNumber(amount.replace(',', '.')).toString()
      : '';
  }, [amount]);

  const nativeToken = useMemo(() => {
    const networkToken = chainToken(network);
    return networkAssets.find(
      (asset) => asset.symbol.toLowerCase() === networkToken.toLowerCase(),
    );
  }, [networkAssets, network]);

  const handleTokenChange = (newAsset: PortfolioToken | null) => {
    if (!newAsset) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    if (!isReceiveSelection && newAsset.address !== assetSend?.address) {
      setAmount('');
      setAssetSend(newAsset);
    }
    if (isReceiveSelection) {
      setAssetReceive(newAsset);
    }
    swapStore.setIsTokenSelection(false);
    swapStore.setIsReceiveSelection(false);
    w(() => null, AmplitudeEvent.SEND_TOKEN_SELECTED, { token: newAsset })();
  };

  const handleNetworkChange = (newNetwork: ChainId) => {
    if (newNetwork !== network) {
      setNavAsset(null);
      setNavAssetReceive(null);
      setAmount('');
      setAssetReceive(null);
      setAssetSend(undefined);
    }
    setNetwork(newNetwork);
  };

  useEffect(() => {
    if (location.pathname.includes('0x')) {
      const addresses = location.pathname
        .split('/')
        .filter((p) => ethRegex.test(p));
      if (addresses.length) {
        navigate('/swap', {
          state: {
            from: addresses[0],
            to: addresses.length > 1 ? addresses[1] : undefined,
          },
          replace: true,
        });
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (navAsset === undefined) {
      if (location.state?.asset) {
        const newNavAsset = JSON.parse(location.state?.asset);
        if (newNavAsset) {
          setNavAsset(newNavAsset);
        }
        if (location.state?.chain) {
          setNetwork(location.state?.chain);
        }
      } else {
        setNavAsset(null);
      }
    }
  }, [navAsset, location.state]);

  useEffect(() => {
    if (navAssetReceive === undefined) {
      if (location.state?.assetReceive) {
        const newNavAssetReceive = JSON.parse(location.state?.assetReceive);
        if (newNavAssetReceive) {
          setNavAssetReceive(newNavAssetReceive);
        }
      } else {
        setNavAssetReceive(null);
      }
    }
  }, [navAssetReceive, location.state]);

  useEffect(() => {
    if (assetsLoaded && !assetSend && navAsset !== undefined) {
      setAssetSend(navAsset || nativeToken || networkAssets[0]);
    }
  }, [assetSend, nativeToken, networkAssets, navAsset, assetsLoaded]);

  useEffect(() => {
    if (!nativeToken && networkAssets.length) {
      if (isInit) {
        notify(
          `You have no native token on this network to make transactions. You need to send some ${chainToken(network)} tokens to your wallet first.`,
          { type: 'danger' },
        );
      } else {
        setIsInit(true);
      }
    }
  }, [nativeToken, networkAssets, network, assetSend, isInit]);

  useEffect(() => {
    if (!assetReceive && navAssetReceive !== undefined) {
      setAssetReceive(navAssetReceive);
    }
  }, [assetReceive, navAssetReceive]);

  const selectProps: SendTokenSelectParams = {
    network,
    setAsset: handleTokenChange,
    isReceiveSelection,
  };

  const formProps: SendFormParams = {
    network,
    setNetwork: handleNetworkChange,
    asset: assetSend!,
    assetReceive,
    amount,
    numericAmount,
    setAmount,
    address,
    setAddress,
    nativeToken,
    slippage,
    setSlippage,
  };

  const confirmProps: SendConfirmProps = {
    address,
    amount: numericAmount,
    assetSend: assetSend!,
    assetReceive,
    slippage,
    network,
  };

  return (
    <>
      {(type === SendPageType.SWAP || type !== SendPageType.CONFIRM) && (
        <>
          {isTokenSelection || isReceiveSelection ? (
            <SendTokenSelect {...selectProps} />
          ) : type === SendPageType.SWAP ? (
            <Swap {...formProps} />
          ) : (
            <SendForm {...formProps} />
          )}
        </>
      )}
      {type === SendPageType.CONFIRM && <SendConfirm {...confirmProps} />}
    </>
  );
});

export default Send;
