import React from 'react';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import { OrderTrigger } from 'types/order/order.enum';
import cn from 'classnames';
import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next';

const tpSlTriggers = [
  { value: OrderTrigger.PRICE_IN_USD, label: 'USD' },
  { value: OrderTrigger.MCAP, label: 'MCap' },
];

const TPSLTriggerGroup = ({ mode }: { mode?: 'quick-trade' | 'order' }) => {
  const { tokenTrade } = useStores();
  const { quickTradeStore } = useStores();
  const { t } = useTranslation();

  const store = mode === 'quick-trade' ? quickTradeStore : tokenTrade;

  const {
    isTpSlTrigger,
    isTpSlPercentMode,
    isStopLossesEnabled,
    isTakeProfitsEnabled,
  } = store;

  return (
    <>
      {(isStopLossesEnabled || isTakeProfitsEnabled) && (
        <div className={cn(['card', 'rounded-3-px', 'mg-y-2', 'gap-12px'])}>
          <div className="d-flex gap-2">
            {tpSlTriggers.map(({ value, label }) => (
              <div
                key={value}
                className={cn([
                  isTpSlTrigger === value
                    ? 'btn-primary-10 bd-primary'
                    : 'btn-semi-10 bd-transparent',
                  'bd-solid',
                  'bd-1',
                  'btn',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'wd-100p',
                  'rounded-8px',
                  'tx-13',
                  'tx-semibold',
                  'p-1',
                ])}
                onClick={() => store.switchTpSlTrigger(value)}
              >
                {label}
              </div>
            ))}
          </div>
          {mode !== 'quick-trade' && (
            <div className="d-flex gap-2 align-items-center">
              <Toggle
                icons={false}
                className="styled-toggle"
                checked={isTpSlPercentMode}
                onChange={({ target: { checked: v } }) =>
                  store.switchTpSlPercentMode(v)
                }
              />
              <span className="tx-semibold tx-13">
                {t('order.show-as-percentage')}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default observer(TPSLTriggerGroup);
