import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import {getEllipsisTxt} from "@helpers/formatters";
import TokenLogo from "@components/common/TokenLogo";
import copy from "@assets/icons/copy.svg";
import arrow from "@assets/icons/arrow-right.svg";
import CopyButton from "@components/common/CopyButton";
import { chainLogo, chainName, chainToken, chainTxExplorer } from '@helpers/chains';
import gas from "@assets/icons/gas.svg";
import {format} from "date-fns";
import sendArrow from "@assets/images/send-arrow.svg";
import BigNumber from "bignumber.js";
import { useTranslation } from 'react-i18next';
import { PortfolioTransaction, PortfolioTransactionStatus, PortfolioTransactionType } from '../../../types';

interface TransactionDetailsProps {
  onClose: () => void;
  item: PortfolioTransaction;
}

const TransactionDetails = observer(({onClose, item}: TransactionDetailsProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(true);
  const { type, rate, status, tokenApproved, tokenSent, tokenReceived, sentTo, sentFrom, fee, date, hash, isTaxPayout, blockchain } = item;
  const tokenIn = (tokenReceived || tokenApproved);
  const symbolIn = tokenIn?.symbol;
  const mainLogo = tokenIn?.image;

  const isTradeOrSend = type === PortfolioTransactionType.Trade || type === PortfolioTransactionType.Send;

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} onExited={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-capitalize">
              {t(`operation.${type}`)}
              {' '}
              {status === PortfolioTransactionStatus.Pending && <>⌛</>}
              {status === PortfolioTransactionStatus.Failed && <>🚫</>}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`card bg-semi-transparent-10 ${type === PortfolioTransactionType.Trade ? '' : 'mb-3'}`}>
            {type === PortfolioTransactionType.Trade && (
              <div className="tx-13 tx-muted mb-1">{t('common.sent')}</div>
            )}
            <div className="d-flex flex-row align-items-center justify-content-start">
              <HistoryLogo
                type={PortfolioTransactionType.Receive}
                symbolIn={tokenApproved?.symbol || (isTradeOrSend ? tokenSent?.symbol : symbolIn) || t('common.na')}
                mainLogo={tokenApproved?.image || (isTradeOrSend ? tokenSent?.image : tokenReceived?.image)}
              />
              {type === PortfolioTransactionType.Approve && (
                <div className="ms-2 me-auto">
                  {tokenApproved?.name || 'N/A'}
                </div>
              )}
              {type !== PortfolioTransactionType.Approve && (
                <div className="ms-2 me-auto">
                  <FormattedNumber
                    value={new BigNumber((isTradeOrSend ? tokenSent?.amount : tokenReceived?.amount) || 0).multipliedBy(!isTradeOrSend ? 1 : -1).toNumber()}
                    postfix={isTradeOrSend ? tokenSent?.symbol : symbolIn}
                    subZeros
                    withSign
                    className={`d-block tx-semibold tx-17 ${!isTradeOrSend ? 'tx-success' : 'tx-white'}`}
                  />
                  {new BigNumber((isTradeOrSend ? tokenSent?.amountUsd : tokenReceived?.amountUsd) || 0).isGreaterThan(0) && (
                    <FormattedNumber
                      value={isTradeOrSend ? tokenSent?.amountUsd : tokenReceived?.amountUsd}
                      suffix="$"
                      className={`d-block tx-muted tx-13`}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {type === PortfolioTransactionType.Trade && (
            <>
              <div className="swap-separator between-cards in-modal">
                <img src={sendArrow} alt="Swap" className="wd-50 ht-50" />
              </div>

              <div className="card bg-semi-transparent-10 mb-3">
                <div className="tx-13 tx-muted mb-1">{t('common.received')}</div>
                <div className="d-flex flex-row align-items-center justify-content-start">
                  <HistoryLogo
                    type={PortfolioTransactionType.Receive}
                    symbolIn={symbolIn || t('common.na')}
                    mainLogo={mainLogo}
                  />
                  <div className="ms-2 me-auto">
                    <FormattedNumber
                      value={tokenReceived?.amount || 0}
                      postfix={symbolIn}
                      withSign
                      className={`d-block tx-semibold tx-17 tx-succes`}
                    />
                    <FormattedNumber
                      value={tokenReceived?.amountUsd || 0}
                      suffix="$"
                      className={`d-block tx-muted tx-13`}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {type !== PortfolioTransactionType.Trade && (
            <div className="card bg-semi-transparent-10 py-1 mb-3">
              {(type === PortfolioTransactionType.Send || type === PortfolioTransactionType.Receive) && !!(sentTo && sentFrom) && (
                <>
                  <div className="py-2 border-bottom border-semi-transparent">
                    <div className="tx-13 tx-muted mb-1">{t('common.from')}</div>
                    <CopyButton text={sentFrom || ''} className="btn btn-link p-0 tx-left text-decoration-none wd-100p" noIcon>
                      <div className="d-flex align-items-center justify-content-start">
                        <TokenLogo address={sentFrom} name={sentFrom} size={25} />
                        <div className="ms-2 me-auto">{getEllipsisTxt(sentFrom, 4, '0x')}</div>
                        <img src={copy} alt="copy" />
                      </div>
                    </CopyButton>
                  </div>
                  <div className="py-2 border-bottom border-semi-transparent">
                    <div className="tx-13 tx-muted mb-1">{t('common.to')}</div>
                    <CopyButton text={sentTo || ''} className="btn btn-link p-0 tx-left text-decoration-none wd-100p" noIcon>
                      <div className="d-flex align-items-center justify-content-start">
                        <TokenLogo address={sentTo} name={sentTo} size={25} />
                        <div className="ms-2 me-auto">{getEllipsisTxt(sentTo, 4, '0x')}</div>
                        <img src={copy} alt="copy" />
                      </div>
                    </CopyButton>
                  </div>
                </>
              )}
              <div className="py-2">
                <div className="tx-13 tx-muted mb-1">{t('common.hash')}</div>
                <a
                  className="d-flex align-items-center justify-content-start tx-white text-decoration-none"
                  href={chainTxExplorer(blockchain, hash)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="me-auto">{getEllipsisTxt(hash, 4, '0x')}</div>
                  <img src={arrow} alt="open" />
                </a>
              </div>
            </div>
          )}

          <div className="card bg-semi-transparent-10 py-1">
            <div className="py-2 border-bottom border-semi-transparent">
              <div className="tx-13 tx-muted mb-1">{t('common.network')}</div>
              <div className="d-flex align-items-center justify-content-start">
                <img src={chainLogo(blockchain)} alt={chainName(blockchain)} width={25} />
                <div className="ms-2">{chainName(blockchain)}</div>
              </div>
            </div>

            {type === PortfolioTransactionType.Trade && (
              <>
                <div className="py-2 border-bottom border-semi-transparent">
                  <div className="tx-13 tx-muted mb-1">{t('common.rate')}</div>
                  <div className="d-flex align-items-center justify-content-start">
                    {!!mainLogo && <img src={mainLogo} alt={symbolIn} width={25} className="me-2" />}
                    <div className="d-flex justify-content-start align-items-center tx-17">
                      <FormattedNumber
                        value={new BigNumber(rate || 0).toNumber()}
                        postfix={symbolIn}
                        subZeros
                        decimals={6}
                      />
                      <span className="mx-1">=</span>
                      <span>1 {tokenSent?.symbol}</span>
                    </div>
                  </div>
                </div>
                <div className="py-2 border-bottom border-semi-transparent">
                  <div className="tx-13 tx-muted mb-1">{t('common.hash')}</div>
                  <a
                    className="d-flex align-items-center justify-content-start tx-white text-decoration-none"
                    href={chainTxExplorer(blockchain, hash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="me-auto">{getEllipsisTxt(hash, 4, '0x')}</div>
                    <img src={arrow} alt="open" />
                  </a>
                </div>
              </>
            )}

            <div className="py-2">
              <div className="tx-13 tx-muted mb-1">{t('common.fee')}</div>
              <div className="d-flex align-items-center justify-content-start">
                <img src={gas} alt="Gas" width={25} className="me-2" />
                <FormattedNumber
                  value={fee}
                  decimals={6}
                  postfix={chainToken(blockchain)}
                />
                {/*{!!feePrice && (*/}
                {/*  <FormattedNumber*/}
                {/*    value={feePrice}*/}
                {/*    suffix="($"*/}
                {/*    postfix=")"*/}
                {/*    className="ms-1"*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            </div>
          </div>

          <div className="mt-3 pb-4 tx-center">
            {status === PortfolioTransactionStatus.Confirmed && <div className="tx-success">{t('common.successful')}</div>}
            {status === PortfolioTransactionStatus.Pending && <div className="tx-warning">{t('common.processing')}</div>}
            {status === PortfolioTransactionStatus.Failed && <div className="tx-danger">{t('common.canceled-failed')}</div>}
            <div className="tx-13 tx-muted">{format(new Date(item.date), 'dd.MM.yyyy HH:mm:ss')}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default TransactionDetails;
