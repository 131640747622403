import React, { useRef, useState } from 'react';
import update from 'assets/icons/update.svg';
import techJobs from 'assets/images/tech-jobs.svg';
import { useTranslation } from 'react-i18next';
import usePopup from '@hooks/usePopup';
import useNotification from '@hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';

const TechJobs = () => {
  const { t } = useTranslation();
  const { authStore } = useStores();

  const navigate = useNavigate();
  const [passVisible, setPassVisible] = useState(false);
  const notify = useNotification();

  const input = useRef<HTMLInputElement>(null);

  const [tapCount, setTapCount] = useState(0);
  const lastTapTimeRef = useRef(0);

  const handleEnter = () => {
    const pass = input.current?.value;
    if (pass === 'ton+rulez1337') {
      authStore.setServiceMode(false);
      navigate('/wallet');
    } else notify('Wrong pass', { type: 'danger' });
  };

  const handleTap = () => {
    const currentTime = Date.now();
    if (currentTime - lastTapTimeRef.current < 500) {
      setTapCount((prev) => prev + 1);
    } else {
      setTapCount(1);
    }
    lastTapTimeRef.current = currentTime;

    if (tapCount + 1 >= 10) {
      setPassVisible(true);
    }
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">
          <img
            onClick={handleTap}
            className="mb-3"
            src={techJobs}
            alt="Tech Jobs"
          />
          <div className="tx-semibold mb-1">
            {t('tech-jobs.technical-works')}
          </div>
          <div className="tx-muted">{t('tech-jobs.we-will-be-back-soon')}</div>
        </div>
        <div
          className="btn btn-semi-10 position-fixed d-flex align-items-center gap-2"
          style={{ bottom: '50px' }}
          onClick={() => location.reload()}
        >
          <img src={update} alt="Update" />
          <span>{t('common.update-page')}</span>
        </div>
        {passVisible && (
          <div className="card position-fixed" style={{ top: '10px' }}>
            <input
              className="form-control bg-semi-transparent-10 mb-2"
              type="text"
              ref={input}
            />
            <button className="btn btn-danger" onClick={handleEnter}>
              Enter
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(TechJobs);
