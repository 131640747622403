import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { To, useNavigate, useParams } from 'react-router-dom';
import useResponseHandler from '@hooks/useResponseHandler';
import { TokenParams } from '@stores/token-store';
import LimitOrdersHistory from './components/LimitOrdersHistory';
import LimitOrderButtons from './components/LimitOrderButtons';
import LimitOrdersTokenBalance from './components/LimitOrdersTokenBalance';
import LimitOrderWalletBalance from './components/LimitOrderWalletBalance';
import TonDelayModal from './modals/TonDelayModal';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import TradeTokenSkeleton from '@components/skeletons/TradeTokenSkeleton';
import LimitOrdersTokenInfo from '@pages/LimitOrders/components/LimitOrdersTokenInfo';
import Vibrutton from '@components/common/Vibrutton';
import arrowLeft from '@assets/icons/arrow-left-primary.svg';
import { PageRoutes } from '../../constants';

const LimitOrdersToken = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const params = useParams();
  useAmplitude([AmplitudeEvent.TOKEN_PAGE_VIEWED, { params }]);

  const { accountStore, ordersStore, settingsStore, tokenStore, socketStore } = useStores();
  const { refId } = accountStore;
  const { isTon } = ordersStore;
  const { analyticsBot } = settingsStore;
  const { error, address, tokenDetails, isExecuting, chain, tokenUsdPrice } = tokenStore;

  const [isInit, setIsInit] = useState<boolean>(false);

  useEffect(() => {
    tokenStore.setRouteParams(params as TokenParams);

    return () => {
      tokenStore.reset();
    };
  }, [params]);

  useEffect(() => {
    if (error) {
      handleResponse(t(error.message), true);
      error?.navigate && navigate(error.navigate as To);
    }
  }, [error]);

  useEffect(() => {
    if (!isInit && tokenDetails) {
      setIsInit(true);
    }
  }, [isInit, tokenDetails]);

  return (
    <div className="pt-2 pb-0 full-page d-flex flex-column">
      {isTon && isExecuting && <TonDelayModal />}
      <div className="mb-2">
        <LimitOrderWalletBalance />
      </div>
      {!tokenDetails ? (
        <TradeTokenSkeleton />
      ) : (
        <LimitOrdersTokenInfo
          tokenDetails={{ ...tokenDetails, refId, address: address!, usdPrice: tokenUsdPrice }}
          chartLink={settingsStore.getChartLink(
            tokenDetails.blockchain,
            tokenDetails.pairAddress,
          )}
          analyticsLink={`https://t.me/${analyticsBot}?start=${tokenDetails.pairAddress}`}
          network={chain}
          className="card mb-2"
        />
      )}
      <LimitOrdersTokenBalance />
      {socketStore.socket && <LimitOrdersHistory />}
      <LimitOrderButtons />
    </div>
  );
});

export default LimitOrdersToken;
