import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChainId, PageRoutes, zeroAddress } from '../../constants';
import { observer } from 'mobx-react-lite';
import {
  chainLogo,
  chainToken,
  chainToShort,
  chainToWallet,
} from '@helpers/chains';
import { useStores } from '@hooks/useStores';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import useNotification from '@hooks/useNotification';
import WalletSelection from '@components/WalletSelection';
import UserAvatar from '@components/common/UserAvatar';
import { getEllipsisTxt } from '@helpers/formatters';
import NetworkSelection from '@components/NetworkSelection';
import arrow from '@assets/icons/chevron-down.svg';
import chevron from '@assets/icons/arrow-down-dark.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import swapArrow from '@assets/images/swap-arrow.svg';
import { roundDown } from '@helpers/numbers';
import { greaterThan } from '@helpers/bignumber';
import PageLoader from '@components/PageLoader';
import Badge from '@components/common/Badge';
import { EstimateBridgeRouteResponse } from '../../types/wallet/estimate-bridge-route.response';
import useDebounce from '@hooks/useDebounce';
import { BaseToken, Blockchain, WalletType } from '../../types/enums';
import useResponseHandler from '@hooks/useResponseHandler';
import IconWithLoading from '@components/common/IconWithLoading';
import { EstimateBridgeRouteRequest } from '../../types/wallet/estimate-bridge-route.request';
import { useTranslation } from 'react-i18next';
import TokenName from '@components/common/TokenName';
import { GasResponse } from '../../types/common.types';
import { hasOwnProperty } from '@helpers/object';
import { BridgeRouteResponse } from '../../types/wallet/bridge-routes.response';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const debridgeGasLimit: Record<Blockchain, number> = {
  ARBITRUM: 250_000,
  AVALANCHE: 250_000,
  BASE: 250_000,
  BSC: 250_000,
  ETHEREUM: 250_000,
  POLYGON: 250_000,
  SOLANA: 0,
  TON: 0,
  TRON: 0,
  SUI: 0,
};

interface TokensPrice {
  AVAX: number;
  BNB: number;
  DAI: number;
  ETH: number;
  MATIC: number;
  SOL: number;
  TON: number;
  TRON: number;
  USDC: number;
  USDT: number;
  SUI: number;
}

const Bridge = observer(() => {
  const { t } = useTranslation();
  const { accountStore, walletStore, blockchainStore } = useStores();
  const {
    isSolana,
    currentWallet,
    isTon,
    isTron,
    isSui,
    wallets,
    network
  } = accountStore;
  const { assets, assetsLoaded } = walletStore;
  const navigate = useNavigate();
  const [networkReceive, setNetworkReceive] = useState<ChainId>(ChainId.BASE);
  const [amount, setAmount] = useState<string>('');
  const debounceAmount = useDebounce(amount, 1000);
  const [isInit, setIsInit] = useState(false);
  const [bridges, setBridges] = useState<EstimateBridgeRouteResponse[]>([]);
  const [isEstimating, setIsEstimating] = useState(false);
  const notify = useNotification();
  const handleResponse = useResponseHandler();
  const [w, AmplitudeWrapper] = useAmplitude();
  const isSwitching = useRef(false);
  const isAccountChange = useRef(false);
  const [walletToId, setWalletToId] = useState<string>('');
  const prevWalletId = useRef<string>('');
  const [gasSend, setGasSend] = useState<GasResponse>();
  const [gasReceive, setGasReceive] = useState<GasResponse>();
  const [routes, setRoutes] = useState<BridgeRouteResponse[]>([]);
  const [routesLoading, setRoutesLoading] = useState(false);
  const [estimatingMax, setEstimatingMax] = useState(false);

  const setNetwork = useCallback((chain: ChainId) => {
    accountStore.setNetwork(chain);
  }, []);

  useEffect(() => {
    walletStore.loadAssets();
  }, [currentWallet]);

  const walletTo = useMemo(() => {
    return wallets.find((wallet) => wallet.id === walletToId);
  }, [walletToId, wallets]);

  const networkTokenLogo = useCallback((n: ChainId) => {
    const ethChains = [ChainId.ETHER, ChainId.BASE, ChainId.ARBITRUM];
    if (ethChains.includes(n)) {
      return chainLogo(ChainId.ETHER);
    }
    return chainLogo(n);
  }, []);

  const numericAmount = useMemo(() => {
    return amount !== ''
      ? new BigNumber(amount.replace(',', '.')).toString()
      : '';
  }, [amount]);

  const networkTokens = useMemo(() => {
    return assets.filter((asset) => asset.address === network);
  }, [assets, network, currentWallet]);

  const networkReceiveTokens = useMemo(() => {
    return assets.filter((asset) => asset.address === networkReceive);
  }, [assets, networkReceive]);

  const nativeToken = useMemo(() => {
    const networkToken = chainToken(network);
    return networkTokens.find(
      (asset) => asset.symbol.toLowerCase() === networkToken.toLowerCase(),
    );
  }, [networkTokens, network]);

  const nativeTokenReceive = useMemo(() => {
    const networkToken = chainToken(networkReceive);
    return networkReceiveTokens.find(
      (asset) => asset.symbol.toLowerCase() === networkToken.toLowerCase(),
    );
  }, [networkReceiveTokens, network]);

  const anyNativeToken = useMemo(() => {
    return assets.find(
      (asset) =>
        asset.symbol.toLowerCase() === chainToken(network).toLowerCase(),
    );
  }, [assets, network]);

  useEffect(() => {
    if (isAccountChange.current) return;
    if (!isInit && !nativeToken) {
      const mainNetwork = isSolana
        ? ChainId.SOLANA
        : anyNativeToken?.address || ChainId.ETHER;
      setNetwork(mainNetwork as ChainId);
      setNetworkReceive(
        mainNetwork === ChainId.SOLANA
          ? Object.values(ChainId).find(
              (value) => value !== ChainId.ALL && value !== mainNetwork,
            ) || ChainId.ETHER
          : ChainId.SOLANA,
      );
      setIsInit(true);
    } else if (!isInit && nativeToken) {
      if (isSolana && nativeToken.address !== ChainId.SOLANA) {
        setNetwork(ChainId.SOLANA);
        return;
      } else if (!isSolana && nativeToken.address === ChainId.SOLANA) {
        setNetwork(ChainId.ETHER);
        setNetworkReceive(ChainId.SOLANA);
        setIsInit(true);
        return;
      }
      setIsInit(true);
      setNetworkReceive(
        nativeToken.address === ChainId.SOLANA
          ? Object.values(ChainId).find(
              (value) =>
                value !== ChainId.ALL && value !== nativeToken.address,
            ) || ChainId.ETHER
          : ChainId.SOLANA,
      );
    }
  }, [isInit, nativeToken, anyNativeToken, isSolana]);

  const amountValue = useMemo(() => {
    let value = roundDown(amount, 4);
    const _amount = new BigNumber(amount).toNumber();

    if (!value && _amount > value) {
      value = _amount;
    }

    const stringValue = new BigNumber(value).toFixed();

    const integer = parseInt(stringValue);
    const digits = stringValue.split('.')[1];
    const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
    return `${result}${digits ? '.' + digits.substring(0, 4) : ''}`;
  }, [amount]);

  const isInterType = useMemo(() => {
    return network === ChainId.SOLANA || networkReceive === ChainId.SOLANA;
  }, [network, networkReceive]);

  const isInvalidAmount = useMemo(() => {
    return !!amount && greaterThan(amount, nativeToken?.balance.token || 0);
  }, [amount, nativeToken]);

  const totalAmount = useMemo(() => {
    if (!bridges.length) return 0;
    return new BigNumber(bridges[0].estimateGasFee)
      .plus(bridges[0].bridgeFee)
      .plus(bridges[0].amountIn)
      .toNumber();
  }, [bridges]);

  const totalAmountUsd = useMemo(() => {
    if (!bridges.length) return 0;
    return new BigNumber(bridges[0].estimateGasFeeInUsd)
      .plus(bridges[0].bridgeFeeInUsd)
      .plus(bridges[0].amountInUsd)
      .toNumber();
  }, [bridges]);

  const networkRoutes = useMemo(() => {
    return routes.filter(
      (route) =>
        route.fromBlockchain === network &&
        route.toBlockchain === networkReceive,
    );
  }, [routes, network, networkReceive]);

  const isAmountEnough = useCallback(
    (silent = false) => {
      if (!amount || !nativeToken) return false;

      const minAmount = networkRoutes.reduce(
        (acc, route) =>
          new BigNumber(route.minAmount).isLessThan(acc)
            ? route.minAmount
            : acc,
        networkRoutes[0].minAmount,
      );
      const maxAmount = networkRoutes.reduce(
        (acc, route) =>
          new BigNumber(route.maxAmount).isGreaterThan(acc)
            ? route.maxAmount
            : acc,
        networkRoutes[0].maxAmount,
      );
      const isAmountSmall = new BigNumber(amount).isLessThan(minAmount);
      const isAmountBig = new BigNumber(amount).isGreaterThan(maxAmount);

      if (isAmountSmall || isAmountBig) {
        if (!silent) {
          notify(
            t('bridge.amount-out-of-range')
              .replace('{{min}}', minAmount)
              .replace('{{max}}', maxAmount),
            { type: 'danger' },
          );
        }
        return false;
      }
      return true;
    },
    [amount, nativeToken, networkRoutes],
  );

  const handleContinue = () => {
    if (!amount || !nativeToken || !network || !networkReceive) {
      return;
    }
    if (!routes.length || !bridges.length || !networkRoutes.length) {
      notify(t('bridge.no-routes-found'), { type: 'danger' });
      return;
    }

    const minAmount = networkRoutes.reduce(
      (acc, route) =>
        new BigNumber(route.minAmount).isLessThan(acc) ? route.minAmount : acc,
      networkRoutes[0].minAmount,
    );
    const maxAmount = networkRoutes.reduce(
      (acc, route) =>
        new BigNumber(route.maxAmount).isGreaterThan(acc)
          ? route.maxAmount
          : acc,
      networkRoutes[0].maxAmount,
    );
    const isAmountSmall = new BigNumber(amount).isLessThan(minAmount);
    const isAmountBig = new BigNumber(amount).isGreaterThan(maxAmount);

    if (isAmountSmall || isAmountBig) {
      notify(
        t('bridge.amount-out-of-range')
          .replace('{{min}}', minAmount)
          .replace('{{max}}', maxAmount),
        { type: 'danger' },
      );
      return;
    }

    if (isInterType && !walletToId) {
      notify(t('bridge.select-receiver-wallet'), { type: 'danger' });
      return;
    }

    if (isInvalidAmount) {
      notify(t('bridge.amount-greater-balance'), { type: 'danger' });
      return;
    }

    if (isEstimating) {
      notify(t('bridge.wait-for-estimation'), { type: 'danger' });
      return;
    }

    navigate(PageRoutes.BRIDGE_CONFIRM, {
      state: { network, networkReceive, bridge: bridges[0], walletToId },
    });
  };

  useEffect(() => {
    if (
      estimatingMax ||
      isAccountChange.current ||
      (isInterType && !walletToId) ||
      !isAmountEnough()
    )
      return;
    if (
      !isSwitching.current &&
      debounceAmount &&
      nativeToken &&
      !new BigNumber(debounceAmount).eq(0) &&
      !greaterThan(debounceAmount, nativeToken.balance.token || 0)
    ) {
      setIsEstimating(true);
      const data: EstimateBridgeRouteRequest = {
        value: debounceAmount,
        fromBlockchain: network as unknown as Blockchain,
        fromToken: nativeToken.symbol as BaseToken,
        toBlockchain: networkReceive as unknown as Blockchain,
        toToken: chainToken(networkReceive) as BaseToken,
        fromWalletId: currentWallet?.id || '',
      };
      if (isInterType && walletToId) {
        data.toWalletId = walletToId;
      }
      accountStore
        .estimateBridge(data)
        .then((response) => {
          setIsEstimating(false);
          if (Array.isArray(response)) {
            if (!response.length) {
              notify(t('bridge.no-routes-found'), { type: 'danger' });
            }
            setBridges(response);
          } else {
            handleResponse(response, true);
          }
        })
        .catch((error) => {
          setIsEstimating(false);
          notify(error.message, { type: 'danger' });
        });
    }
  }, [
    debounceAmount,
    nativeToken,
    network,
    networkReceive,
    currentWallet,
    isSwitching,
    isInterType,
    walletToId,
  ]);

  useEffect(() => {
    if (isAccountChange.current || (isInterType && !walletToId)) return;
    setBridges([]);
    if (
      isAmountEnough(true) &&
      amount &&
      !isInvalidAmount &&
      new BigNumber(amount).isGreaterThan(0)
    ) {
      setIsEstimating(true);
    } else {
      setIsEstimating(false);
    }
  }, [amount, isInvalidAmount, isInterType, walletToId]);

  useEffect(() => {
    if (isAccountChange.current) return;
    setAmount('');
    setBridges([]);
    setIsEstimating(false);
  }, [network]);

  useEffect(() => {
    if (!prevWalletId.current) {
      prevWalletId.current = currentWallet?.id || '';
      return;
    }
    setWalletToId('');
    setIsInit(false);
  }, [currentWallet]);

  const handleSwitch = () => {
    if (isSolana) return;

    isSwitching.current = true;
    setNetworkReceive(network);
    setNetwork(networkReceive);
    setWalletToId('');
    setTimeout(() => {
      isSwitching.current = false;
    }, 100);
  };

  useEffect(() => {
    if (
      isAccountChange.current ||
      isSwitching.current ||
      !isInit ||
      !prevWalletId.current
    )
      return;
    if (!walletToId) {
      const type =
        networkReceive === ChainId.SOLANA ? WalletType.SOL : WalletType.EVM;
      setWalletToId(wallets.find((wallet) => wallet.type === type)?.id || '');
    }
  }, [networkReceive, wallets, walletToId, isInit]);

  useEffect(() => {
    if (isAccountChange.current || isSwitching.current || !isInit || !network)
      return;
    setGasSend(undefined);
    blockchainStore
      .getBlockchainGas(network)
      .then((response) => {
        if (response && hasOwnProperty(response, 'gwei')) {
          setGasSend(response);
        } else {
          handleResponse(response, true);
        }
      })
      .catch((error) => {
        handleResponse(error, true);
      });
  }, [network, isInit]);

  useEffect(() => {
    if (
      isAccountChange.current ||
      isSwitching.current ||
      !isInit ||
      !networkReceive
    )
      return;
    setGasReceive(undefined);
    blockchainStore
      .getBlockchainGas(networkReceive)
      .then((response) => {
        if (response && hasOwnProperty(response, 'gwei')) {
          setGasReceive(response);
        } else {
          handleResponse(response, true);
        }
      })
      .catch((error) => {
        handleResponse(error, true);
      });
  }, [networkReceive, isInit]);

  useEffect(() => {
    setRoutesLoading(true);
    accountStore
      .bridgeRoutes(network)
      .then((response) => {
        setRoutes(response);
        setRoutesLoading(false);
      })
      .catch((error) => {
        setRoutesLoading(false);
        handleResponse(error, true);
      });
  }, [network]);

  const amountToDecimals = useMemo(() => {
    const bn = new BigNumber(bridges?.[0]?.amountOut || 0);
    if (bn.isGreaterThan(1000)) {
      return 0;
    }
    if (bn.isGreaterThan(1)) {
      return 2;
    }
    if (bn.isGreaterThan(0.009)) {
      return 2;
    }
    if (bn.isGreaterThan(0.00009)) {
      return 4;
    }
    return 6;
  }, [bridges]);

  const networkFromGasLimit = useMemo(() => {
    if (network === ChainId.TON) {
      return '0.01';
    } else if (network === ChainId.SOLANA) {
      return '0.000005';
    }
    const gasLimit = debridgeGasLimit[network as unknown as Blockchain];
    return new BigNumber(gasLimit)
      .times(gasSend?.gwei || 0)
      .times(10 ** 9)
      .div(10 ** 18)
      .toString();
  }, [network, gasSend]);

  const networkToGasLimit = useMemo(() => {
    if (network === ChainId.TON) {
      return '0.01';
    } else if (network === ChainId.SOLANA) {
      return '0.000005';
    }
    const gasLimit = debridgeGasLimit[networkReceive as unknown as Blockchain];
    return new BigNumber(gasLimit)
      .times(gasReceive?.gwei || 0)
      .times(10 ** 9)
      .div(10 ** 18)
      .toString();
  }, [networkReceive, gasReceive]);

  const handleSetMax = () => {
    if (estimatingMax) return;
    if (!gasSend || !gasReceive || !nativeToken || !networkRoutes.length) {
      setTimeout(() => {
        handleSetMax();
      }, 250);
      return;
    }
    let minAmount = networkRoutes.reduce(
      (acc, route) =>
        new BigNumber(route.minAmount).isLessThan(acc) ? route.minAmount : acc,
      networkRoutes[0].minAmount,
    );
    if (
      new BigNumber(minAmount).multipliedBy(2).isLessThan(nativeToken.balance.token)
    ) {
      minAmount = new BigNumber(minAmount).multipliedBy(2).toString();
    }
    if (new BigNumber(minAmount).isGreaterThan(nativeToken.balance.token)) {
      handleResponse(t('errors.trading.not-enough-balance'), true);
      return;
    }
    const data: EstimateBridgeRouteRequest = {
      value: minAmount,
      fromBlockchain: network as unknown as Blockchain,
      fromToken: nativeToken.symbol as BaseToken,
      toBlockchain: networkReceive as unknown as Blockchain,
      toToken: chainToken(networkReceive) as BaseToken,
      fromWalletId: currentWallet?.id || '',
    };
    if (isInterType && walletToId) {
      data.toWalletId = walletToId;
    }
    setEstimatingMax(true);
    accountStore
      .estimateBridge(data)
      .then((response) => {
        if (Array.isArray(response)) {
          if (!response.length) {
            notify(t('bridge.no-routes-found'), { type: 'danger' });
          } else {
            const newAmount = new BigNumber(nativeToken.balance.token)
              .multipliedBy(0.99)
              .minus(response[0].bridgeFee)
              .minus(response[0].estimateGasFee)
              .toString();
            setAmount(newAmount);
          }
        } else {
          handleResponse(response, true);
        }
        setEstimatingMax(false);
      })
      .catch((error) => {
        setEstimatingMax(false);
        notify(error.message, { type: 'danger' });
      });
    // console.log({networkFromGasLimit, networkToGasLimit});
    // const newAmount = new BigNumber(nativeToken.quantity).multipliedBy(0.99).minus(networkFromGasLimit).toString();
    // setAmount(newAmount);
  };

  if (!isInit) {
    return <PageLoader text={t('bridge.building-bridges')} />;
  }

  if (isTon || isTron || isSui) {
    return (
      <div>
        <div className="py-3">
          <WalletSelection className="btn btn-transparent p-0 d-flex justify-content-between align-items-center">
            <div className="wd-40 ht-40">
              <UserAvatar string={currentWallet!.address} />
            </div>
            <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
              <div className="tx-17 lh-2">
                {currentWallet?.name ||
                  getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
                <img
                  src={chevron}
                  alt="Wallet Selection"
                  width={16}
                  className="ms-1"
                />
              </div>
              <div className="tx-muted tx-12">
                {currentWallet?.name
                  ? getEllipsisTxt(currentWallet?.address || '', 8, '0x')
                  : ''}
              </div>
            </div>
          </WalletSelection>
        </div>

        <div className="card bg-semi-warning-10 tx-warning tx-semibold">
          {t('bridge.ton-bridge-not-available')}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="py-3">
        <WalletSelection className="btn btn-transparent p-0 d-flex justify-content-between align-items-center">
          <div className="wd-40 ht-40">
            <UserAvatar string={currentWallet?.address} />
          </div>
          <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
            <div className="tx-17 lh-2">
              {currentWallet?.name ||
                getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
              <img
                src={chevron}
                alt="Wallet Selection"
                width={16}
                className="ms-1"
              />
            </div>
            <div className="tx-muted tx-12">
              {currentWallet?.name
                ? getEllipsisTxt(currentWallet?.address || '', 8, '0x')
                : ''}
            </div>
          </div>
        </WalletSelection>
      </div>

      <div className="card p-0">
        <div className="p-3">
          <div className="d-flex justify-content-end align-items-center">
            <div className="tx-17 tx-semibold ms-0 me-auto py-1">
              {t('bridge.from')}
            </div>
            {!!amount && (
              <button
                className={`btn btn-primary-10 tx-13 tx-semibold px-3 py-2 ${isSolana ? '' : 'me-2'}`}
                onClick={() => setAmount('')}
              >
                {t('common.clear')}
              </button>
            )}
            <div
              className={`${isSolana ? 'd-none' : 'card'} bg-semi-transparent-10 p-2 pe-1`}
            >
              <NetworkSelection
                network={network}
                onChange={(chain) =>
                  w(
                    () => setNetwork(chain),
                    AmplitudeEvent.BRIDGE_FROM_CHAIN_SELECTED,
                    {
                      chain,
                    },
                  )(chain)
                }
                title={t('bridge.from')}
                enabledNetworks={isSolana ? [ChainId.SOLANA] : []}
                disabledNetworks={
                  !isSolana
                    ? [ChainId.SOLANA, ChainId.TRON, ChainId.TON]
                    : [ChainId.TRON, ChainId.TON]
                }
              >
                <div className="d-flex align-items-center tx-12 wd-100p">
                  <img src={chainLogo(network)} alt="chain" width={16} />
                  <span className="ms-1 tx-uppercase">
                    {chainToShort(network)}
                  </span>
                  <img src={arrow} alt="Choose network" width={16} />
                </div>
              </NetworkSelection>
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-center mt-3">
            <div className="d-flex justify-content-start align-items-center">
              <div className="pos-relative mn-wd-35 mx-wd-35">
                <img src={networkTokenLogo(network)} alt="chain" width={35} />
                <div className="chain-label z-index-50 bottom-0">
                  <img
                    src={chainLogo(network)}
                    alt={network}
                    className="wd-20 ht-20"
                  />
                </div>
              </div>
              <div className="tx-17 ms-3 me-2">{chainToken(network)}</div>
            </div>

            <input
              type="number"
              className={`form-control bg-transparent border-0 tx-20 tx-right py-0 px-0 appearance-none ${!estimatingMax && amount ? 'tx-white' : 'tx-muted'}`}
              value={amount === nativeToken?.balance.token ? amountValue : amount}
              placeholder="0"
              onChange={(e) => setAmount(e.target.value)}
              onBlur={() => window.scrollTo(0, 0)}
              disabled={estimatingMax}
            />
            <button
              className="btn btn-link ps-2 pe-0 py-0 tx-primary text-decoration-none"
              onClick={w(
                handleSetMax,
                AmplitudeEvent.BRIDGE_MAX_BUTTON_CLICKED,
              )}
              disabled={estimatingMax || !gasReceive || !gasSend}
            >
              {t('common.max')}
            </button>
          </div>

          <div
            className={`d-flex justify-content-start align-items-center tx-13 mt-2`}
          >
            <FormattedNumber
              suffix={`${t('common.bal')}: `}
              postfix={nativeToken?.balance.token ? chainToken(network) : undefined}
              decimals={4}
              floor={true}
              value={assetsLoaded ? nativeToken?.balance.token || 0 : null}
              className={`${!nativeToken || nativeToken.balance.token === '0' ? 'tx-muted' : ''} ${isInvalidAmount ? 'tx-danger-f' : ''}`}
            />
            <FormattedNumber
              suffix="("
              value={assetsLoaded ? nativeToken?.balance.usd || 0 : null}
              postfix="$)"
              className={`tx-muted ms-1 ${!nativeToken || nativeToken.balance.token === '0' ? 'd-none' : ''}`}
            />
            <FormattedNumber
              suffix="$"
              value={
                !estimatingMax && !isEstimating
                  ? bridges?.[0]?.amountInUsd || 0
                  : null
              }
              className={`ms-auto me-0 ${!amount ? 'tx-muted' : ''}`}
              decimals={2}
              subZeros
            />
          </div>
        </div>

        <div className="swap-separator">
          <img
            src={swapArrow}
            alt="Swap"
            className="wd-40 ht-40 cur-pointer"
            onClick={handleSwitch}
          />
        </div>

        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="tx-17 tx-semibold ms-0 me-auto">
              {t('bridge.to')}
            </div>

            <div className="card bg-semi-transparent-10 p-2 pe-1">
              <NetworkSelection
                network={networkReceive}
                onChange={(chain) => {
                  setNetworkReceive(chain);
                  w(
                    () => setNetworkReceive(chain),
                    AmplitudeEvent.BRIDGE_TO_CHAIN_SELECTED,
                    { chain },
                  );
                }}
                title={t('bridge.to')}
                disabledNetworks={
                  isSolana
                    ? [ChainId.SOLANA, ChainId.TRON, ChainId.TON]
                    : [ChainId.TRON, ChainId.TON]
                }
              >
                <div className="d-flex align-items-center tx-12 wd-100p">
                  <img src={chainLogo(networkReceive)} alt="chain" width={16} />
                  <span className="ms-1 tx-uppercase">
                    {chainToShort(networkReceive)}
                  </span>
                  <img src={arrow} alt="Choose network" width={16} />
                </div>
              </NetworkSelection>
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-center mt-3">
            <div className="d-flex justify-content-start align-items-center">
              <div className="pos-relative mn-wd-35 mx-wd-35">
                <img
                  src={networkTokenLogo(networkReceive)}
                  alt="chain"
                  width={35}
                />
                <div className="chain-label z-index-50 bottom-0">
                  <img
                    src={chainLogo(networkReceive)}
                    alt={networkReceive}
                    className="wd-20 ht-20"
                  />
                </div>
              </div>
              <div className="tx-17 ms-3">{chainToken(networkReceive)}</div>
            </div>

            <FormattedNumber
              value={isEstimating ? null : bridges?.[0]?.amountOut || 0}
              className={`ms-auto me-0 tx-20 tx-right tx-muted`}
              decimals={amountToDecimals}
              floor
            />
          </div>

          <div
            className={`d-flex justify-content-start align-items-center tx-13 mt-2`}
          >
            <FormattedNumber
              suffix={`${t('common.bal')}: `}
              postfix={
                nativeTokenReceive?.balance.token
                  ? chainToken(networkReceive)
                  : undefined
              }
              decimals={4}
              floor={true}
              value={nativeTokenReceive?.balance.token || 0}
              className={`${!nativeTokenReceive || nativeTokenReceive.balance.token === '0' ? 'tx-muted' : ''}`}
            />
            <FormattedNumber
              suffix="("
              value={nativeTokenReceive?.balance.usd || 0}
              postfix="$)"
              className={`tx-muted ms-1 ${!nativeTokenReceive || nativeTokenReceive.balance.token === '0' ? 'd-none' : ''}`}
            />
            <FormattedNumber
              suffix="$"
              value={
                estimatingMax || isEstimating
                  ? null
                  : bridges?.[0]?.amountOutUsd || 0
              }
              className={`ms-auto me-0 ${!amount ? 'tx-muted' : ''}`}
              decimals={2}
              subZeros
            />
          </div>
        </div>
      </div>

      {isInterType && (
        <div className="card p-3 mt-3">
          <div className="tx-17 tx-semibold mt-2 mb-2">
            {t('common.receiver')}
          </div>
          <WalletSelection
            className="btn btn-transparent p-0 d-flex justify-content-between align-items-center"
            enabledTypes={[chainToWallet(networkReceive)]}
            enforceExternalFilter
            hideCurrent
            onChange={(address) =>
              w(setWalletToId, AmplitudeEvent.BRIDGE_RECEIVER_SELECTED, {
                address,
              })(address)
            }
          >
            <div className="wd-35 ht-35">
              {walletTo ? (
                <UserAvatar string={walletTo.address} size={35} />
              ) : (
                <TokenName
                  text="Wallet"
                  size={35}
                  fontSize={8}
                  className="tx-normal"
                />
              )}
            </div>
            <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
              <div className="tx-17 lh-2">
                {walletTo ? walletTo.name : 'Select Wallet'}
                <img
                  src={chevron}
                  alt="Wallet Selection"
                  width={16}
                  className="ms-1"
                />
              </div>
              <div className="tx-muted tx-12">
                {getEllipsisTxt(
                  walletTo ? walletTo.address : zeroAddress,
                  8,
                  '0x',
                )}
              </div>
            </div>
          </WalletSelection>
        </div>
      )}

      <div className="card p-3 mt-3">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="tx-17 tx-semibold">{t('bridge.you-will-pay')}</div>
          <Badge label="Best Route" className="tx-12 tx-semibold rounded-6px" />
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex justify-content-start align-items-center">
            <div className="pos-relative mn-wd-35 mx-wd-35">
              <img src={networkTokenLogo(network)} alt="chain" width={35} />
              <div className="chain-label z-index-50 bottom-0">
                <img
                  src={chainLogo(network)}
                  alt={network}
                  className="wd-20 ht-20"
                />
              </div>
            </div>
            <div className="ms-3 tx-normal">
              <FormattedNumber
                value={estimatingMax || isEstimating ? null : totalAmount}
                postfix={chainToken(network)}
                className="tx-17 d-block"
                decimals={4}
                subZeros
              />
              <FormattedNumber
                value={estimatingMax || isEstimating ? null : totalAmountUsd}
                suffix="$"
                className="tx-13 tx-muted d-block"
                decimals={2}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary-10 mt-3 wd-100p"
        onClick={w(handleContinue, AmplitudeEvent.BRIDGE_CONTINUE_CLICKED)}
      >
        <IconWithLoading
          isLoading={estimatingMax || isEstimating || routesLoading}
          className="me-2 op-3"
        />
        <span
          className={`${estimatingMax || !bridges.length || isEstimating || routesLoading || (isInterType && !walletTo) ? 'op-3' : ''}`}
        >
          {t('common.continue')}
        </span>
      </button>
    </div>
  );
});

export default Bridge;
