export const updateLocalStorageObject = (
  key: string,
  update: Record<string, any>,
) => {
  const lastWallet = JSON.parse(localStorage.getItem(key) ?? '{}');
  localStorage.setItem(
    key,
    JSON.stringify({
      ...lastWallet,
      ...update,
    }),
  );
};

export const getLocalStorageObject = (
  key: string,
): Record<string, any> | null => {
  const i = localStorage.getItem(key);

  return i && JSON.parse(i);
};
