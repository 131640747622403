import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SideNavItem from '@pages/Wallet/SideNavItem';
import info from '@assets/icons/info-red.svg';
import trash from '@assets/icons/delete-red.svg';
import { PageRoutes } from '../../../constants';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import useNotification from '@hooks/useNotification';
import { AxiosError } from 'axios';
import useResponseHandler from '@hooks/useResponseHandler';
import IconWithLoading from '@components/common/IconWithLoading';
import { observer } from 'mobx-react-lite';
import { chainToWallet } from '@helpers/chains';
import { WalletResponse } from 'types/wallet/wallet.response';

const DeleteModal = observer(() => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { accountStore, walletStore } = useStores();
  const navigate = useNavigate();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    const response = await walletStore.deleteWallet();

    if (!(response instanceof AxiosError)) {
      notify(t('wallet.delete-successful'), { type: 'success' });
      accountStore
        .loadUser()
        .then((data) => {
          if (data?.data) {
            accountStore.setCurrentWallet(
              data.data.find(
                ({ type }: WalletResponse) =>
                  chainToWallet(accountStore.network) === type,
              ),
            );
          } else {
            handleResponse(data);
          }
          setLoading(false);
          handleClose();
          navigate(PageRoutes.WALLET);
        })
        .catch((error) => {
          handleResponse(error);
        });
      handleClose();
      navigate(PageRoutes.WALLET);
    } else {
      handleResponse(response.response);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card p-0 text-danger overflow-hidden">
        <SideNavItem
          title={t('wallet.delete-wallet')}
          icon={trash}
          onClick={() => setShowModal(true)}
          isLast
          variant="danger"
        />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onExited={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('wallet.delete-wallet')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center">
          <img src={info} alt="info" className="mb-3 mt-1 wd-45" />

          <div className="tx-28 tx-semibold tx-center mb-2">
            {t('wallet.delete-wallet')}?
          </div>

          <div className="tx-muted tx-17">{t('wallet.delete-wallet-info')}</div>

          <button
            className="btn wd-100p mt-3 bg-danger text-white"
            onClick={handleDelete}
            disabled={loading}
          >
            <IconWithLoading isLoading={loading} className="me-2" />
            {t('wallet.delete-wallet')}
          </button>

          <button
            className="btn btn-transparent wd-100p mt-2 mb-3"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default DeleteModal;
