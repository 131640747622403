import React from 'react';
import HistoryLogo from '@pages/Wallet/components/HistoryLogo';
import FormattedNumber from '@components/common/FormattedNumber';
import { getEllipsisTxt } from '@helpers/formatters';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { chainToken, chainTokenName, chainTxExplorer } from '@helpers/chains';
import { PortfolioTransaction, PortfolioTransactionStatus, PortfolioTransactionType } from '../../../types';
import { Blockchain } from '../../../types/enums';
import BigNumber from 'bignumber.js';

interface HistoryItemProps {
  item: PortfolioTransaction;
  onClick: () => void;
  hide?: boolean;
}

const HistoryItem = observer(({ item, onClick, hide }: HistoryItemProps) => {
  const { t } = useTranslation();
  const { type, rate, status, tokenApproved, tokenSent, tokenReceived, sentTo, sentFrom, fee, date, hash, isTaxPayout, blockchain } = item;

  const tokenIn = (tokenReceived || tokenApproved);
  const symbolIn = tokenIn?.symbol;
  const mainLogo = tokenIn?.image;
  const inNative = symbolIn === chainToken(blockchain);
  const outNative = tokenSent?.symbol === chainToken(blockchain);
  const isBuy = type === PortfolioTransactionType.Trade ? tokenSent?.symbol === chainToken(blockchain) : undefined;

  const handleClick = () => {
    if (blockchain === Blockchain.TRON || blockchain === Blockchain.SUI) {
      window.open(chainTxExplorer(blockchain, hash), '_blank');
      return;
    }
    onClick();
  };

  return (
    <div className={`pb-3 mb-3 cur-pointer with-hover ${hide ? 'd-none' : ''}`}>
      <div className="d-flex justify-content-between align-items-center wd-100p" onClick={handleClick}>
        <HistoryLogo
          type={type}
          symbolIn={symbolIn || t('common.na')}
          symbolOut={type === PortfolioTransactionType.Trade ? tokenSent?.symbol : undefined}
          chain={blockchain}
          // addressIn={!mainLogo && !inNative && isBuy ? sentTo : undefined}
          // addressOut={!tokenSent?.image && !outNative && isBuy === false ? sentTo : undefined}
          mainLogo={mainLogo}
          secondLogo={tokenSent?.image}
        />
        <div className="ms-3 wd-100p">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="text-capitalize">
              {item.isTaxPayout
                ? t('operation.alpha-tax')
                : t(`operation.${type.toLowerCase()}`)}
            </div>
            {status === PortfolioTransactionStatus.Pending && (
              <div className="tx-yellow">
                {t('common.pending')} ⌛️
              </div>
            )}
            {status === PortfolioTransactionStatus.Failed && (
              <div className="tx-danger">
                {t('common.failed')} 🚫
              </div>
            )}
            {blockchain !== Blockchain.TRON && type !== PortfolioTransactionType.Approve && status === PortfolioTransactionStatus.Confirmed && (
              <>
                {(tokenReceived || tokenSent)?.amount ? (
                  <FormattedNumber
                    value={new BigNumber(tokenReceived?.amount || tokenSent?.amount || 0).multipliedBy(type !== PortfolioTransactionType.Send ? 1 : -1).toNumber()}
                    postfix={`${symbolIn || tokenSent?.symbol}`}
                    className={`tx-right ${type !== PortfolioTransactionType.Send ? 'tx-success' : 'tx-white'}`}
                    shorten={9}
                    subZeros
                    withSign
                  />
                ) : (<span>{t('common.na')}</span>)}
              </>
            )}
            {blockchain === Blockchain.TRON && (
              <>
                {type !== PortfolioTransactionType.Trade && type !== PortfolioTransactionType.Approve && status === PortfolioTransactionStatus.Confirmed && (
                  <>
                    {(tokenReceived || tokenSent)?.amount ? (
                      <FormattedNumber
                        value={new BigNumber(tokenReceived?.amount || tokenSent?.amount || 0).multipliedBy(type !== PortfolioTransactionType.Send ? 1 : -1).toNumber()}
                        postfix={`${symbolIn || tokenSent?.symbol}`}
                        className={`tx-right ${type !== PortfolioTransactionType.Send ? 'tx-success' : 'tx-white'}`}
                        shorten={9}
                        subZeros
                        withSign
                      />
                    ) : (<span>{t('common.na')}</span>)}
                  </>
                )}
                {type === PortfolioTransactionType.Trade && status === PortfolioTransactionStatus.Confirmed && (
                  <>
                    {tokenReceived ? (
                      <FormattedNumber
                        value={tokenReceived.amount}
                        postfix={symbolIn}
                        className="tx-right tx-success"
                        shorten={9}
                        subZeros
                        withSign
                      />
                    ) : (<span>{t('common.na')}</span>)}
                  </>
                )}
              </>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            {/*{type !== PortfolioTransactionType.Trade && (*/}
            {/*  <div>{getEllipsisTxt((address || ''), 4, '0x')}</div>*/}
            {/*)}*/}
            {type === PortfolioTransactionType.Trade && !!tokenSent?.amount && (
              <FormattedNumber
                suffix="-"
                value={tokenSent?.amount || 0}
                postfix={`${tokenSent?.symbol}`}
                shorten={9}
              />
            )}
            {type !== PortfolioTransactionType.Approve && new BigNumber(tokenReceived?.amountUsd || tokenSent?.amountUsd || 0).isGreaterThan(0) && (
              <FormattedNumber
                value={tokenReceived?.amountUsd || tokenSent?.amountUsd || 0}
                suffix="$"
                shorten={9}
              />
            )}
          </div>
        </div>
      </div>
      {/*{status === PortfolioTransactionStatus.Pending && type !== PortfolioTransactionType.Receive && (*/}
      {/*  <div className="mt-3">*/}
      {/*    <button className="btn btn-dark bg-semi-transparent-10 border-semi-transparent-10 tx-13 px-3">*/}
      {/*      {t('common.speed-up')}*/}
      {/*    </button>*/}
      {/*    <button className="btn btn-outline-danger border-semi-transparent ms-3 tx-13 px-3">*/}
      {/*      {t('common.cancel')}*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});

export default HistoryItem;
