import { reaction, IReactionOptions } from 'mobx';

export function effect<T>(
  dependicles: () => T[],
  effect: (currentValue: T[], previousValue: T[]) => any,
  options?: IReactionOptions<string, false>,
) {
  const depsCb = () => JSON.stringify(dependicles());
  const effectCb = (c: string, p: string) =>
    effect(...([c, p].map((o) => JSON.parse(o)) as [T[], T[]]));
  return reaction<string>(depsCb, effectCb, options);
}
