import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import clock from '@assets/icons/clock-green.svg';
import { useTranslation } from 'react-i18next';

const TonDelayModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(true);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      onExited={handleClose}
    >
      <Modal.Header className="modal-header-new with-border" closeButton />
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center pt-2">
        <img src={clock} alt="info" className="mb-3 mt-1 wd-45" color='sucsess'/>

        <div className="tx-28 tx-semibold tx-center mb-2">
          {t('trading.order-executing')}
        </div>

        <div className="tx-muted tx-17">{t('order.executing-message')}</div>

        <button
          className="btn wd-100p mt-3 bg-success text-black"
          onClick={handleClose}
        >
          {t('common.okay')}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default TonDelayModal;
