import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import TPSLInputGroup from './TPSLInputGroup';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useNotification from '@hooks/useNotification';
import OrderSettingsModal from '../OrderSettingsModal';
import { defaultOrderSettings } from '@stores/settings-store';
import { IOrderSettings } from 'types';
import { OrderTrigger } from 'types/order/order.enum';
import { Blockchain } from 'types/enums';

export interface TpSlValueProps {
  titleClassName?: string;
}

const TakeProfitValue = observer(
  ({ titleClassName = 'tx-17 tx-semibold' }: TpSlValueProps) => {
    const { t } = useTranslation();
    const notify = useNotification();
    const { tokenTrade, settingsStore, quickTradeStore } = useStores();
    const { takeProfitSettings } = settingsStore;

    const {
      chain,
      isTakeProfitsEnabled: tokenTradeEnabled,
      takeProfits: tokenTradeTp,
      takeProfitsError: ttError,
      isTpSlPercentMode,
      isTpSlTrigger,
      emptyInputsLightOn,
    } = tokenTrade;

    const {
      takeProfits: quickTradeTp,
      isTakeProfitsEnabled: quickTradeEnabled,
      takeProfitsError: qtError,
      takeProfitSettings: qtSettings,
      isTpSlPercentMode: qtIsTpSlPercentMode,
      isTpSlTrigger: qtIsTpSlTrigger,
      isInit: isQuickTrade,
    } = quickTradeStore;

    const takeProfitsError = useMemo(() => {
      if (isQuickTrade) return qtError;
      return ttError;
    }, [isQuickTrade, qtError, ttError]);

    const isTakeProfitsEnabled = useMemo(() => {
      if (isQuickTrade) return quickTradeEnabled;
      return tokenTradeEnabled;
    }, [isQuickTrade, quickTradeEnabled, tokenTradeEnabled]);

    const takeProfits = useMemo(() => {
      if (isQuickTrade) return quickTradeTp;
      return tokenTradeTp;
    }, [isQuickTrade, quickTradeTp, tokenTradeTp]);

    const settings = takeProfitSettings ?? { [chain!]: defaultOrderSettings(chain as Blockchain) };

    const tpSettings = useMemo(() => {
      if (isQuickTrade) {
        return qtSettings;
      }
      return takeProfitSettings
        ? takeProfitSettings[chain!]
        : defaultOrderSettings(chain as Blockchain);
    }, [isQuickTrade, qtSettings, takeProfitSettings, chain]);

    const updateSettings = (val: IOrderSettings) => {
      if (isQuickTrade) {
        quickTradeStore.setTakeProfitSettings(val);
        return;
      }
      settingsStore.setTakeProfitSettings({ ...settings, [chain!]: val });
    };

    const handleChange = (idx: number) => {
      if (isQuickTrade) {
        return quickTradeStore.setTakeProfitValue(idx);
      }
      return tokenTrade.setTakeProfitValue(idx);
    };

    const handleAdd = () => {
      if (isQuickTrade) {
        return quickTradeStore.addTakeProfitValue();
      }
      return tokenTrade.addTakeProfitValue();
    };

    const handleRemove = (idx: number) => {
      if (isQuickTrade) {
        return quickTradeStore.removeTakeProfitValue(idx);
      }
      return tokenTrade.removeTakeProfitValue(idx);
    };

    useEffect(() => {
      if ((takeProfits?.length ?? 0) >= 5)
        notify(t('order.max-take-profit-orders-reached'), { type: 'danger' });
    }, [takeProfits?.length]);

    return (
      <>
        {isTakeProfitsEnabled && (
          <div className={cn(['card', 'rounded-3-px', 'mg-y-2', 'gap-2'])}>
            <div className="d-flex justify-content-between">
              <span className={titleClassName}>{t('order.take-profit')}</span>
              <OrderSettingsModal
                data={tpSettings}
                updateSettings={updateSettings}
              />
            </div>

            <div className="d-flex gap-2">
              <span className="d-flex wd-50p tx-muted tx-13">
                {(isQuickTrade ? qtIsTpSlTrigger : isTpSlTrigger) ===
                OrderTrigger.MCAP
                  ? `${t('common.mcap')}`
                  : `${t('order.price-growth')} ${(isQuickTrade ? qtIsTpSlPercentMode : isTpSlPercentMode) ? '%' : '$'}`}
              </span>
              <span className="d-flex wd-50p tx-muted tx-13">
                {t('order.sell-percents-price-growth')}
              </span>
            </div>
            {takeProfits?.map((val, idx) => {
              return (
                <TPSLInputGroup
                  disableAdding={takeProfits.length >= 5}
                  lightEmptyInputs={emptyInputsLightOn}
                  hasSumError={!!takeProfitsError[0]}
                  idx={idx}
                  key={`stop-loss-${idx}`}
                  unit={
                    (isQuickTrade ? qtIsTpSlPercentMode : isTpSlPercentMode)
                      ? '%'
                      : '$'
                  }
                  values={val}
                  onChange={handleChange(idx)}
                  onAdd={handleAdd}
                  onRemove={() => handleRemove(idx)}
                />
              );
            })}
            {takeProfitsError && (
              <ul className="mb-0 mt-1 ps-4">
                {takeProfitsError.map(
                  (error, idx) =>
                    error && (
                      <li key={`${error}-${idx}`} className="tx-13 tx-danger">
                        {t(error)}
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        )}
      </>
    );
  },
);

export default TakeProfitValue;
