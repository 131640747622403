import React from 'react';
import { getPercentage } from '@helpers/numbers';

interface ProgressProps {
  value: number;
  max?: number;
  forceColor?: string;
  primaryColor?: string;
  bgColor?: string;
  className?: string;
  progressClassName?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  label?: string;
  children?: React.ReactNode;
}

const Progress = (props: ProgressProps) => {
  const {
    value,
    max = 100,
    forceColor,
    primaryColor = 'primary',
    bgColor,
    className,
    progressClassName = '',
    left,
    right,
    label,
    children,
  } = props;
  const percent = getPercentage(value, max);
  let color = forceColor || primaryColor;

  if (!forceColor) {
    if (percent < 50) {
      color = 'dark-red';
    } else if (percent < 75) {
      color = 'orange';
    } else if (percent < 100) {
      color = 'dark-green';
    }
  }

  return (
    <div className={`pos-relative ${className ?? className}`}>
      {(left || right) && (
        <div className="d-flex justify-content-between mb-1 tx-13">
          {left && <div>{left}</div>}
          {right && <div>{right}</div>}
        </div>
      )}
      <div className={`progress ${progressClassName}`}>
        <div className={`progress-bar-bg ${bgColor ? 'bg-' + bgColor : ''}`} />
        <div
          className={`progress-bar bg-${color}`}
          role="progressbar"
          style={{ width: `${percent}%` }}
          aria-valuenow={value}
          aria-valuemin={0}
          aria-valuemax={max}
        />
      </div>
      {!!(label || children) && (
        <div className="pos-absolute absolute-item d-flex align-items-center justify-content-center tx-10 z-index-10">
          {label || children}
        </div>
      )}
    </div>
  );
};

export default Progress;
