import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Progress from '@components/common/Progress';
import BigNumber from 'bignumber.js';
import FormattedNumber from '@components/common/FormattedNumber';
import Vibrutton from '@components/common/Vibrutton';

interface ReferralWhalesModalProps {
  link: string | null;
  turnover: number;
}

const ReferralWhalesModal = ({ link, turnover }: ReferralWhalesModalProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };

  const turnoverFormatted = new BigNumber(turnover).div(1000).toFixed(0);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-new" />
        <Modal.Body className="pt-0">
          <div className="tx-center tx-24 tx-semibold">
            {t('referral.exclusive-community')}
          </div>
          <div className="tx-center tx-14 tx-muted mt-2 px-3 mb-4">
            {t('referral.exclusive-community-descr')}
          </div>

          <div className="wd-230 mx-auto tx-center">
            <div className="d-flex align-items-center justify-content-center gap-1">
              <FormattedNumber
                value={turnoverFormatted}
                suffix="$"
                postfix="K"
                className="tx-primary"
                noSpace
              />
              <span>/</span>
              <FormattedNumber
                value={300}
                suffix="$"
                postfix="K"
                noSpace
              />
            </div>
            <Progress
              value={turnover}
              max={300000}
              forceColor="primary"
              bgColor="semi-primary-10"
              className="mt-2 wd-100p"
              progressClassName="ht-12"
            />
          </div>

          {!link && (
            <button className="btn btn-secondary bg-semi-transparent wd-100p mt-4" disabled>
              <span className="tx-muted">
                {t('common.join')}
              </span>
            </button>
          )}

          {!!link && (
            <a
              className="btn btn-primary wd-100p mt-4"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {t('common.join')}
            </a>
          )}

          <Vibrutton
            className="btn btn-secondary wd-100p mt-2 mb-3"
            onClick={handleClose}
          >
            {t('common.close')}
          </Vibrutton>
        </Modal.Body>
      </Modal>

      <Vibrutton
        className="btn btn-link text-decoration-none tx-white card mb-4 bg-image-chat d-block wd-100p tx-left"
        onClick={() => setShow(true)}
      >
        <div className="wd-170 tx-14 tx-semibold">{t('referral.exclusive-community-heading')}</div>
        <div className="op-4 tx-12">{t('referral.exclusive-community-sub')}</div>
        <div className="btn btn-primary btn-sm wd-auto mt-2">{t('common.join')}</div>
      </Vibrutton>
    </>
  );
};

export default ReferralWhalesModal;
