import React from 'react';
import cn from 'classnames';
import { isMobile } from '@helpers/device';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const LimitOrderButtons = () => {
  const { tokenStore } = useStores();
  const { tokenDetails: token } = tokenStore;
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goToOrder = (action: 'sell' | 'buy') => {
    navigate(
      `/limit-orders/${token!.blockchain}/${token!.pairAddress}/trade`,
      { state: { action, token, back: pathname } },
    );
  };

  return (
    <div
      id="assets-actions"
      className={cn([
        'd-flex',
        'justify-content-between',
        'align-items-center',
        'gap-3',
        !isMobile() && 'desktop',
      ])}
    >
      <button
        className="btn btn-primary wd-100p force-hover-btn"
        onClick={() =>
          w(goToOrder, AmplitudeEvent.TOKEN_PAGE_BUY_CLICKED, {
            token,
          })('buy')
        }
      >
        {t('common.buy')}
      </button>
      <button
        className="btn btn-primary wd-100p force-hover-btn"
        onClick={() =>
          w(goToOrder, AmplitudeEvent.TOKEN_PAGE_SELL_CLICKED, {
            token,
          })('sell')
        }
      >
        {t('common.sell')}
      </button>
    </div>
  );
};

export default observer(LimitOrderButtons);
