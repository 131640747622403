import React, { ReactNode } from 'react';

interface RepeatProps {
  count: number;
  children: ReactNode;
  className?: string;
}

const Cloner: React.FC<RepeatProps> = ({ count, children, className }) => {
  if (count < 1) {
    return null;
  }

  return (
    <>
      {Array.from({ length: count }, (_, index) =>
        (
          <div key={index} className={className}>
            {React.isValidElement(children) ? (
              React.cloneElement(children)
            ) : (
              <React.Fragment>{children}</React.Fragment>
            )}
          </div>
        ),
      )}
    </>
  );
};

export default Cloner;
