import React from 'react';
import LimitOrdersTokenInfo from '@pages/LimitOrders/components/LimitOrdersTokenInfo';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';

const TradeHeader = () => {
  const { accountStore, tokenStore, settingsStore, tokenTrade } = useStores();
  const { refId } = accountStore;
  const { analyticsBot } = settingsStore;
  const { tokenDetails, tokenUsdPrice } = tokenStore;
  const { address } = tokenTrade;

  return (
    tokenDetails && (
      <div className="overflow-hidden mb-3">
        <LimitOrdersTokenInfo
          tokenDetails={{ ...tokenDetails, address: address!, refId, usdPrice: tokenUsdPrice }}
          chartLink={settingsStore.getChartLink(
            tokenDetails.blockchain,
            tokenDetails.pairAddress,
          )}
          analyticsLink={`https://t.me/${analyticsBot}?start=${tokenDetails.pairAddress}`}
          shortView
        />
      </div>
    )
  );
};

export default observer(TradeHeader);
