import {action, makeAutoObservable, observable} from 'mobx';
import api from '@helpers/api';

export class SwapStore {
  @observable
  private _isTokenSelection = false;

  @observable
  private _isReceiveSelection = false;

  constructor() {
    makeAutoObservable(this);
  }

  get
  isTokenSelection() {
    return this._isTokenSelection;
  }

  get
  isReceiveSelection() {
    return this._isReceiveSelection;
  }

  @action.bound
  setIsTokenSelection(value: boolean) {
    this._isTokenSelection = value;
  }

  @action.bound
  setIsReceiveSelection(value: boolean) {
    this._isReceiveSelection = value;
  }

  @action.bound
  async getTokensPrice() {
    return api<any>({
      method: 'get',
      path: `/token/price`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        return response;
      });
  }
}
