import React, { useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import { format } from 'date-fns';
import BigNumber from 'bignumber.js';
import { getEllipsisTxt } from '@helpers/formatters';
import { useTranslation } from 'react-i18next';
import { PortfolioTransaction, PortfolioTransactionStatus, PortfolioTransactionType } from '../../types';

interface TokenHistoryItemProps {
  item: PortfolioTransaction;
  isLast: boolean;
  name: string;
  symbol: string;
  onClick: () => void;
}

const TokenHistoryItem = ({ item, isLast, name, symbol, onClick }: TokenHistoryItemProps) => {
  const { type, status, date, isTaxPayout, tokenSent, tokenReceived } = item;

  const { t } = useTranslation();

  const isCurrentTokenIn = useMemo(() => {
    return tokenReceived && tokenReceived.symbol === symbol;
  }, [tokenReceived, symbol]);

  const symbolIn = tokenReceived?.symbol || 'USD';
  const symbolOut = tokenSent?.symbol || 'USD';
  const logoIn = tokenReceived?.image || '';
  const logoOut = tokenSent?.image || undefined;
  const amountIn = new BigNumber(tokenReceived?.amount || 0).toNumber();
  const amountOut = new BigNumber(tokenSent?.amount || 0).toNumber();
  const amountInUsd = new BigNumber(tokenReceived?.amountUsd || 0).toNumber();
  const amountOutUsd = new BigNumber(tokenSent?.amountUsd || 0).toNumber();
  const priceIn = new BigNumber(amountOutUsd).div(amountOut).toNumber();
  const priceOut = new BigNumber(amountInUsd).div(amountIn).toNumber();

  const isBuy = type === PortfolioTransactionType.Trade && (symbolIn === symbol || symbolIn === name);

  const typeName = useMemo(() => {
    if (type === PortfolioTransactionType.Trade) {
      return isBuy ? 'buying' : 'selling';
    }
    return type + '';
  }, [symbol, type, symbolIn]);

  const typeClass = useMemo(() => {
    let className = 'type ';
    if (type === PortfolioTransactionType.Trade) {
      className += isBuy ? 'operation-buy' : 'operation-sell';
    } else {
      switch (type) {
        case PortfolioTransactionType.Receive:
          className += 'operation-receive';
          break;
        case PortfolioTransactionType.Send:
          className += 'operation-send';
          break;
        default:
          className += 'operation-default';
      }
    }
    return className;
  }, [symbol, type, symbolIn]);

  const operationSymbol = useMemo(() => {
    let result;
    switch (type) {
      case PortfolioTransactionType.Trade:
        result = !isBuy ? symbolOut : symbolIn;
        break;
      case PortfolioTransactionType.Send:
        result = symbolOut;
        break;
      case PortfolioTransactionType.Receive:
        result = symbolIn;
        break;
      default:
        result = symbol;
        break;
    }
    return result.length > 11 ? getEllipsisTxt(result, 4) : result;
  }, [type, symbolIn, symbolOut, symbol]);

  const symbols = [symbol, symbolIn, symbolOut];
  const isNonEvm = symbols.includes('SOLANA') || symbols.includes('TON') || symbols.includes('TRX') || symbols.includes('BTC') || symbols.includes('SUI');

  return (
    <div className={`pb-3 mb-3 ${isLast ? '' : 'border-1 border-bottom border-muted-10'} cur-pointer with-hover`}
         onClick={onClick}>
      <div className={`${typeClass}`}>
        <div className="d-flex wd-100p flex-column">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className={'text-capitalize word-break ' + typeName}>
              {isTaxPayout
                ? t('operation.alpha-tax')
                : t('common.' + typeName.toLowerCase())}

              {!isNonEvm && type === PortfolioTransactionType.Trade ? (
                <FormattedNumber
                  value={isBuy ? priceIn : priceOut}
                  suffix=": $"
                  subZeros
                  decimals={6}
                  className="me-auto"
                />
              ) : <div />}
            </div>
            {status === PortfolioTransactionStatus.Pending && (
              <div className="tx-yellow">
                {t('common.pending')} ⌛️
              </div>
            )}
            {status === PortfolioTransactionStatus.Failed && (
              <div className="tx-danger">
                {t('common.failed')} 🚫
              </div>
            )}
            {status === PortfolioTransactionStatus.Confirmed && type === PortfolioTransactionType.Approve && (
              <div className="tx-success">
                {t('common.confirmed')} ✅
              </div>
            )}
            {status === PortfolioTransactionStatus.Confirmed && type !== PortfolioTransactionType.Approve && (
              <FormattedNumber
                value={isBuy || type === PortfolioTransactionType.Receive ? amountIn : -1 * amountOut}
                postfix={` ${operationSymbol}`}
                className={`tx-right ${isBuy || type === PortfolioTransactionType.Receive ? 'tx-success' : 'tx-white'}`}
                subZeros
                withSign
              />
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            <div>
              {format(new Date(date), 'HH:mm')}
            </div>
            {!isNonEvm && type !== PortfolioTransactionType.Approve && new BigNumber(amountOutUsd || amountInUsd).isGreaterThan(0) && (
              <FormattedNumber
                value={amountOutUsd || amountInUsd}
                suffix="$"
                subZeros
              />
            )}
            {isNonEvm && type !== PortfolioTransactionType.Approve && (
              <FormattedNumber
                value={isBuy || type === PortfolioTransactionType.Receive ? amountOut * -1 : amountIn}
                postfix={` ${isBuy || type === PortfolioTransactionType.Receive ? symbolOut : symbolIn}`}
                subZeros
                withSign
              />
            )}
          </div>
        </div>
        {/*{status === PortfolioTransactionStatus.Pending && type !== PortfolioTransactionType.Receive && (*/}
        {/*  <div className="mt-3">*/}
        {/*    <button className="btn btn-dark bg-semi-transparent-10 border-semi-transparent-10 tx-13 px-3">*/}
        {/*      {t('common.speed-up')}*/}
        {/*    </button>*/}
        {/*    <button className="btn btn-outline-danger border-semi-transparent ms-3 tx-13 px-3">*/}
        {/*    {t('common.cancel')}*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default TokenHistoryItem;
