import React, { ReactNode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import clone from '@helpers/clone';

interface AppRouterProps {
  children?: ReactNode;
}

const AppRouter = ({ children }: AppRouterProps) => {
  useEffect(() => {
    const originalPushState = history.pushState;

    history.pushState = function(state, title, url) {
      return originalPushState.call(this, clone(state), title, url);
    };
  }, []);

  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  );
};

export default AppRouter;
