import {
  action,
  computed,
  makeAutoObservable,
  observable,
  reaction,
} from 'mobx';
import { hasOwnProperty } from '@helpers/object';
import BigNumber from 'bignumber.js';
import api from '@helpers/api';
import { ChainId } from '../constants';
import { dexScreenerLink, dexToolsLink } from '@helpers/chains';
import { IOrderSettings } from 'types';
import { AuthStore } from './auth-store';
import { AccountStore } from '@stores/account-store';
import { Blockchain } from 'types/enums';

const keys = [
  '_notifyEnabled',
  '_amounts',
  '_triggers',
  '_percents',
  '_avatarOptions',
  '_chartService',
  '_analyticsBot',
  '_researchApproved',
  '_orderSettings',
  '_stopLossSettings',
  '_takeProfitSettings',
  'solPriorityMode',
  'solPriorityFee',
];

const defaultAmounts = ['10', '50', '100', '1000'];
const defaultPercents = ['25', '50', '75', '90'];
const defaultTriggers = ['25', '50', '200', '300'];

export const defaultOrderSettings = (chain: Blockchain): IOrderSettings =>
  chain === Blockchain.SOLANA
    ? {
        autoApproveEnabled: true,
        antiFailEnabled: false,
        antiMevEnabled: false,
        extraGasPricePercent: 300,
        slippage: null,
        maxGasPrice: null,
      }
    : {
        autoApproveEnabled: true,
        antiFailEnabled: false,
        antiMevEnabled: true,
        extraGasPricePercent: null,
        slippage: null,
        maxGasPrice: null,
      };

interface AvatarOptions {
  [key: string]: string;
}

export class SettingsStore {
  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _notifyEnabled = false;

  @observable
  private _researchApproved = false;

  @observable
  private _chartService: 'dexscreener' | 'dextools' = 'dexscreener';

  @observable
  private _amounts: string[] = defaultAmounts.slice();

  @observable
  private _percents: string[] = defaultPercents.slice();

  @observable
  private _triggers: string[] = defaultTriggers.slice();

  @observable
  private _analyticsBot: string = 'ttfbotbot';

  @observable
  private _orderSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _stopLossSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _takeProfitSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _avatarOptions: AvatarOptions = {};

  @observable
  private _tradeScroll: { [key: string]: number } = {};

  constructor(authStore: AuthStore, accountStore: AccountStore) {
    makeAutoObservable(this);

    if (authStore.isLogged) this.restoreSettings();

    reaction(
      () => authStore.isLogged,
      (isLogged) => {
        if (isLogged) this.restoreSettings();
      },
    );

    reaction(
      () => accountStore.network,
      () => this.resetPersistentScroll(),
    );

    reaction(
      () => accountStore.currentWallet,
      () => this.resetPersistentScroll(),
    );
  }

  @computed
  get initialized() {
    return this._initialized;
  }

  @computed
  get triggerUpdate() {
    return this._triggerUpdate;
  }

  @computed
  get notifyEnabled() {
    return this._notifyEnabled;
  }

  @computed
  get amounts() {
    return this._amounts.map((amount) => new BigNumber(amount).toNumber());
  }

  @computed
  get percents() {
    return this._percents.map((amount) => new BigNumber(amount).toNumber());
  }

  @computed
  get triggers() {
    return this._triggers.map((amount) => new BigNumber(amount).toNumber());
  }

  @computed
  get chartService() {
    return this._chartService;
  }

  @computed
  get analyticsBot() {
    return this._analyticsBot;
  }

  @computed
  get orderSettings() {
    return Object.keys(this._orderSettings).length ? this._orderSettings : null;
  }

  @computed
  get stopLossSettings() {
    return Object.keys(this._stopLossSettings).length
      ? this._stopLossSettings
      : null;
  }

  @computed
  get takeProfitSettings() {
    return Object.keys(this._takeProfitSettings).length
      ? this._takeProfitSettings
      : null;
  }

  @computed
  get isSaving() {
    return this._isSaving;
  }

  @computed
  get researchApproved() {
    return this._researchApproved;
  }

  @computed
  get defaultAmounts() {
    return defaultAmounts;
  }

  @action.bound
  persistentScroll(key: string) {
    return this._tradeScroll[key] || 0;
  }

  @action.bound
  setPersistentScroll(key: string, value: number) {
    this._tradeScroll[key] = value;
  }

  @action.bound
  resetPersistentScroll() {
    this._tradeScroll = {};
  }

  @action.bound
  getChartLink(chain: ChainId | string, pairAddress: string, wallet?: string) {
    if (this._chartService === 'dextools') {
      return dexToolsLink(chain, pairAddress);
    }
    return dexScreenerLink(chain, pairAddress, wallet);
  }

  @action.bound
  setChartService(value: 'dexscreener' | 'dextools') {
    this._chartService = value;
  }

  @action.bound
  setAnalyticsBot(value: string = 'ttfbotbot') {
    this._analyticsBot = value;
  }

  @action.bound
  setOrderSettings(value: { [key: string]: IOrderSettings }) {
    this._orderSettings = value;
    this.saveSettings();
  }

  @action.bound
  setStopLossSettings(value: { [key: string]: IOrderSettings }) {
    this._stopLossSettings = value;
    this.saveSettings();
  }

  @action.bound
  setTakeProfitSettings(value: { [key: string]: IOrderSettings }) {
    this._takeProfitSettings = value;
    this.saveSettings();
  }

  @computed
  avatarOption(wallet: string) {
    return hasOwnProperty(this._avatarOptions, wallet)
      ? this._avatarOptions[wallet]
      : '';
  }

  @action.bound
  restoreAvatarOptions(options: AvatarOptions) {
    this._avatarOptions = options;
    this.saveSettings();
  }

  @action.bound
  setAvatarOption(wallet: string, value: string) {
    this._avatarOptions[wallet] = value;
    this.handleUpdate();
  }

  @action.bound
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action.bound
  setNotifyEnabled(value: boolean) {
    this._notifyEnabled = value;
    this.handleUpdate();
  }

  @action.bound
  setResearchApproved(value: boolean) {
    this._researchApproved = value;
    this.handleUpdate();
  }

  @action.bound
  setAmounts(value: string[]) {
    this._amounts = defaultAmounts.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action.bound
  setPercents(value: string[]) {
    this._percents = defaultPercents.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action.bound
  setTriggers(value: string[]) {
    this._triggers = defaultTriggers.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action.bound
  setSaved() {
    this._isSaving = false;
  }

  @computed
  get state() {
    const obj = {};
    keys.forEach((key) => {
      // @ts-ignore
      obj[key] = this[key];
    });
    return obj;
  }

  @action.bound
  async saveSettings() {
    this._isSaving = true;
    try {
      await api({
        method: 'post',
        path: '/user/settings',
        data: {
          settings: this.state,
        },
      });
      this.setSaved();
      return true;
    } catch (e) {
      console.error(e);
      this.setSaved();
      return false;
    }
  }

  @action.bound
  restoreSettings() {
    api<any>({
      method: 'get',
      path: '/user/settings',
    })
      .then((response) => {
        if (typeof response !== 'object' || !response) {
          console.error(response);
          return;
        }
        Object.keys(response).forEach((key) => {
          // @ts-ignore
          if (hasOwnProperty(this, key) && this[key] !== response[key]) {
            // @ts-ignore
            this[key] = response[key];
          }
        });
        this._initialized = true;
      })
      .catch((e) => {
        console.error(e);
        this._initialized = true;
      });
  }

  @action.bound
  restoreSettingsDeprecated(keys: string[], values: string[]) {
    keys.forEach((key, index) => {
      try {
        const value = values[index] ? JSON.parse(values[index]) : null;
        if (
          hasOwnProperty(this, key) &&
          value &&
          // @ts-ignore
          JSON.stringify(this[key]) !== JSON.stringify(value)
        ) {
          if (key === '_amounts' && values[index] === '["0.5","1","1.5"]') {
            this.setAmounts(defaultAmounts.slice());
            return;
          }
          // @ts-ignore
          this[key] = value;
        }
      } catch (e) {
        // console.log(e);
      }
    });
    this._initialized = true;
  }
}
