import arbitrum from '@assets/images/chains/arbitrum.svg';
import avalanche from '@assets/images/chains/avalanche.svg';
import bsc from '@assets/images/chains/bsc.svg';
import ether from '@assets/images/chains/ether.svg';
import polygon from '@assets/images/chains/polygon.svg';
import base from '@assets/images/chains/base.svg';
import sui from '@assets/images/chains/sui.svg';
import allChains from '@assets/images/chains/all-chains.svg';
import { ChainId, evmChainsDeprecated } from '../constants';
import { Blockchain, Dex, PairType, WalletType } from '../types/enums';
import pancakeswap from '@assets/images/dex/pancakeswap.svg';
import sushi from '@assets/images/dex/sushi.svg';
import solana from '@assets/images/solana.svg';
import uniswap2 from '@assets/images/dex/uniswap2.svg';
import raydium from '@assets/images/dex/raydium.svg';
import dedust from '@assets/images/dex/dedust.svg';
import sunswap from '@assets/images/dex/sunswap.svg';
import stonfi from '@assets/images/dex/stonfi.svg';
import sunpump from '@assets/images/dex/sunpump.png';
import meteora from '@assets/images/dex/meteora.svg';
import pumpfun from '@assets/images/dex/pumpfun.jpg';
import cetus from '@assets/images/dex/cetus.png';
import ton from '@assets/images/ton.svg';
import tron from '@assets/images/tron.svg';
import bitcoin from '@assets/images/bitcoin.svg';
import evm from '@assets/images/evm.svg';

export const chainName = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ALL:
      return 'All Chains';
    case ChainId.ETHER:
      return 'Ethereum';
    case ChainId.BSC:
      return 'BSC';
    case ChainId.POLYGON:
      return 'Polygon';
    case ChainId.AVALANCHE:
      return 'Avalanche';
    case ChainId.ARBITRUM:
      return 'Arbitrum';
    case ChainId.BASE:
      return 'Base';
    case ChainId.SOLANA:
      return 'Solana';
    case ChainId.TON:
      return 'TON';
    case ChainId.TRON:
      return 'TRON';
    case ChainId.SUI:
      return 'SUI';
    default:
      return 'Unknown';
  }
};

export const chainIdToShort = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
      return 'ether';
    case ChainId.BSC:
      return 'bsc';
    case ChainId.BASE:
      return 'base';
    default:
      return chain.toLowerCase();
  }
};

export const allowedChains = [
  ChainId.ETHER,
  ChainId.SOLANA,
  ChainId.SUI,
  ChainId.TRON,
  ChainId.BASE,
  ChainId.BSC,
  // ChainId.POLYGON,
  // ChainId.AVALANCHE,
  // ChainId.ARBITRUM,
  ChainId.TON,
];

export const allowedBlockchains = [
  Blockchain.ETHEREUM,
  Blockchain.SOLANA,
  Blockchain.TON,
  Blockchain.TRON,
  Blockchain.SUI,
  Blockchain.BSC,
  Blockchain.BASE,
];

export const allowedEvmChainsDeprecated = evmChainsDeprecated.filter((c) => allowedChains.includes(c));

export const chainLogo = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ALL:
      return allChains;
    case ChainId.ETHER:
      return ether;
    case ChainId.BSC:
      return bsc;
    case ChainId.POLYGON:
      return polygon;
    case ChainId.AVALANCHE:
      return avalanche;
    case ChainId.ARBITRUM:
      return arbitrum;
    case ChainId.BASE:
      return base;
    case ChainId.SOLANA:
      return solana;
    case ChainId.TON:
      return ton;
    case ChainId.TRON:
      return tron;
    case ChainId.SUI:
      return sui;
    default:
      return '';
  }
};

export const chainTokenLogo = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
    case ChainId.ARBITRUM:
    case ChainId.BASE:
      return ether;
    case ChainId.BSC:
      return bsc;
    case ChainId.POLYGON:
      return polygon;
    case ChainId.AVALANCHE:
      return avalanche;
    case ChainId.SOLANA:
      return solana;
    case ChainId.TON:
      return ton;
    case ChainId.TRON:
      return tron;
    case ChainId.SUI:
      return sui;
    default:
      return '';
  }
};

export const chainToken = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
    case ChainId.ARBITRUM:
    case ChainId.BASE:
      return 'ETH';
    case ChainId.BSC:
      return 'BNB';
    case ChainId.POLYGON:
      return 'MATIC';
    case ChainId.AVALANCHE:
      return 'AVAX';
    case ChainId.SOLANA:
      return 'SOL';
    case ChainId.TON:
      return 'TON';
    case ChainId.TRON:
      return 'TRX';
    case ChainId.SUI:
      return 'SUI';
    default:
      return 'All';
  }
};

export const chainTokenName = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.SOLANA:
      return 'SOLANA';
    case ChainId.ETHER:
    case ChainId.ARBITRUM:
    case ChainId.BASE:
    case ChainId.BSC:
    case ChainId.POLYGON:
    case ChainId.AVALANCHE:
    case ChainId.SUI:
    default:
      return chainToken(chain);
  }
};

export const chainIdNumber = (chain: ChainId | string): number => {
  switch (chain) {
    case ChainId.ETHER:
      return 1;
    case ChainId.BSC:
      return 56;
    case ChainId.POLYGON:
      return 137;
    case ChainId.AVALANCHE:
      return 43114;
    case ChainId.ARBITRUM:
      return 42161;
    case ChainId.BASE:
      return 8453;
    default:
      return 0;
  }
};

export const chainTxExplorer = (
  chain: ChainId | string,
  transactionHash: string,
): string => {
  switch (chain) {
    case ChainId.ETHER:
      return `https://etherscan.io/tx/${transactionHash}`;
    case ChainId.AVALANCHE:
      return `https://snowtrace.io/tx/${transactionHash}`;
    case ChainId.BSC:
      return `https://bscscan.com/tx/${transactionHash}`;
    case ChainId.ARBITRUM:
      return `https://arbiscan.io/tx/${transactionHash}`;
    case ChainId.POLYGON:
      return `https://polygonscan.com/tx/${transactionHash}`;
    case ChainId.BASE:
      return `https://basescan.org/tx/${transactionHash}`;
    case ChainId.SOLANA:
      return `https://solscan.io/tx/${transactionHash}`;
    case ChainId.TON:
      return `https://tonviewer.com/transaction/${transactionHash}`;
    case ChainId.TRON:
      return `https://tronscan.org/#/transaction/${transactionHash}`;
    case ChainId.SUI:
      return `https://suiscan.xyz/mainnet/tx/${transactionHash}`;
    default:
      return '';
  }
};

export const chainToShort = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
      return 'ETH';
    case ChainId.POLYGON:
      return 'POLY';
    case ChainId.AVALANCHE:
      return 'AVAX';
    case ChainId.ARBITRUM:
      return 'ARB';
    case ChainId.SOLANA:
      return 'SOL';
    default:
      return chain;
  }
};

export const dexToolsLink = (
  chain: ChainId | string = ChainId.ETHER,
  pairAddress: string = '',
): string => {
  let chainId = chain.toLowerCase();
  if (chain === ChainId.BSC) {
    chainId = 'bnb';
  } else if (chain === ChainId.ETHER) {
    chainId = 'ether';
  }
  return `https://www.dextools.io/app/en/${(chainId || '').toLowerCase()}/pair-explorer/${pairAddress || ''}?t=${Date.now()}`;
};

export const dexScreenerLink = (
  chain: ChainId | string = ChainId.ETHER,
  pairAddress: string = '',
  wallet?: string,
): string => {
  let chainId = chain;
  if (chain === ChainId.BSC) {
    chainId = 'bsc';
  } else if (chain === ChainId.BASE) {
    chainId = 'base';
  }
  let result = `https://dexscreener.com/${(chainId || '').toLowerCase()}/${(pairAddress || '').toLowerCase()}`;
  if (wallet) {
    result += `?maker=${wallet}`;
  }
  return result;
};

export const dexLogo = (dex: Dex | string): string => {
  switch (dex) {
    case Dex.UNISWAP:
      return uniswap2;
    case Dex.SUSHISWAP:
      return sushi;
    case Dex.PANCAKESWAP:
      return pancakeswap;
    case Dex.RAYDIUM:
      return raydium;
    case Dex.STONFI:
      return stonfi;
    case Dex.DEDUST:
      return dedust;
    case Dex.SUNSWAP:
      return sunswap;
    case Dex.SUNPUMP:
      return sunpump;
    case Dex.PUMPFUN:
      return pumpfun;
    case Dex.METEORA:
      return meteora;
    case Dex.CETUS:
      return cetus;
    default:
      return '';
  }
};

export const dexName = (dex: Dex | string): string => {
  switch (dex) {
    case Dex.UNISWAP:
      return 'UniSwap';
    case Dex.SUSHISWAP:
      return 'SushiSwap';
    case Dex.PANCAKESWAP:
      return 'PancakeSwap';
    case Dex.RAYDIUM:
      return 'Raydium';
    case Dex.STONFI:
      return 'STON.fi';
    case Dex.DEDUST:
      return 'DeDust';
    case Dex.SUNSWAP:
      return 'SunSwap';
    case Dex.PUMPFUN:
      return 'PumpFun';
    case Dex.METEORA:
      return 'Meteora';
    case Dex.CETUS:
      return 'Cetus';
    default:
      return 'Unknown';
  }
};

export const dexVersion = (pairType: PairType | string): string => {
  const noVersions: Array<PairType | string> = [PairType.RAY_CP, PairType.METEORA_DLMM, PairType.METEORA_DYN];

  if (noVersions.includes(pairType)) return '';
  if (pairType === PairType.STONFI_V1) return 'V1';
  if (pairType === PairType.UNI_V2 || pairType === PairType.DEDUST_V2)
    return 'V2';
  if (pairType === PairType.RAY_V4) return 'V4';
  return 'V3';
};

export const walletTypeName = (walletType: WalletType | 'all'): string => {
  switch (walletType) {
    case 'all':
      return 'all';
    case WalletType.SOL:
      return 'Solana';
    case WalletType.BTC:
      return 'Bitcoin';
    default:
      return walletType;
  }
};

export const walletTypeLogo = (walletType: WalletType | 'all', allEvm = false): string => {
  switch (walletType) {
    case 'all':
      return 'all';
    case WalletType.EVM:
      return allEvm ? evm : ether;
    case WalletType.SOL:
      return solana;
    case WalletType.TON:
      return ton;
    case WalletType.TRON:
      return tron;
    case WalletType.BTC:
      return bitcoin;
    case WalletType.SUI:
      return sui;
    default:
      return '';
  }
};

export const chainToWallet = (chain: ChainId) => {
  if (chain === ChainId.SOLANA) return WalletType.SOL;
  if (chain === ChainId.TON) return WalletType.TON;
  if (chain === ChainId.TRON) return WalletType.TRON;
  if (chain === ChainId.SUI) return WalletType.SUI;
  return WalletType.EVM;
};

export const walletToChain = (wallet: WalletType) => {
  if (wallet === WalletType.SOL) return ChainId.SOLANA;
  if (wallet === WalletType.TON) return ChainId.TON;
  if (wallet === WalletType.TRON) return ChainId.TRON;
  if (wallet === WalletType.SUI) return ChainId.SUI;
  return ChainId.ETHER;
}

export const isEvmChain = (chain: ChainId) => {
  return evmChainsDeprecated.includes(chain);
};
