import React from "react";
import ContentLoader from "react-content-loader";
import cn from 'classnames';

export interface PlaceholderProps {
  width?: number;
  height?: number;
  className?: string;
  circle?: boolean;
  fullWidth?: boolean;
}

export default function Placeholder({width = 80, height = 16, className = '', circle = false, fullWidth = false}: PlaceholderProps) {
  const radius = circle ? Math.min(width, height) / 2 : fullWidth ? 0 : 5;
  const style = fullWidth ? {width: '100%', height} : {};

  return (
    <span className={cn([
      className,
      fullWidth ? 'fw-rect rounded-5px overflow-hidden' : '',
    ])}>
      <ContentLoader
        viewBox={'0 0 ' + width + ' ' + height}
        width={width}
        height={height}
        style={style}
        foregroundColor="#3C3C3C"
        backgroundColor="#292929"
        preserveAspectRatio={fullWidth ? 'none' : undefined}
      >
        <rect x="0" y="0" rx={radius} ry={radius} width={width} height={height}/>
      </ContentLoader>
    </span>
  );
}
