import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getEllipsisTxt } from '@helpers/formatters';
import copy from '@assets/icons/copy-gray.svg';
import arrowDown from '@assets/icons/arrow-down.svg';
import arrowUp from '@assets/icons/arrow-up.svg';
import refresh from '@assets/icons/refresh.svg';
import gem from './assets/gem.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import TabsCard from '@components/common/TabsCard';
import ListItem from '@pages/Wallet/ListItem';
import History from '@pages/Wallet/components/History';
import { Link, useNavigate } from 'react-router-dom';
import { ChainId, PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import BadgeIcon from '@components/common/BadgeIcon';
import Preloader from '@components/common/Preloader';
import CopyButton from '@components/common/CopyButton';
import { isEvmChain } from '@helpers/chains';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import WalletSelection from '@components/WalletSelection';
import UserAvatar from '@components/common/UserAvatar';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import cn from 'classnames';
import HelpModal from '@pages/Wallet/components/HelpModal';
import { Carousel } from 'react-bootstrap';
import { Blockchain, WalletType } from '../../types/enums';
import Placeholder from '@components/common/Placeholder';
import usePersistentScroll from '@hooks/usePersistentScroll';
import ListItemSkeleton from '@components/skeletons/ListItemSkeleton';
import Cloner from '@components/common/Cloner';

const Wallet = observer(() => {
  const { t } = useTranslation();
  const [w, AmplitudeWrapper] = useAmplitude([
    AmplitudeEvent.MAIN_SCREEN_VIEWED,
  ]);
  const { accountStore, walletStore, settingsStore } = useStores();
  const { network, currentWallet, walletsByType } = accountStore;
  const {
    portfolio: _portfolio,
    assets,
    assetsLoaded,
    transactions,
    transactionsLoaded,
  } = walletStore;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(t('common.assets'));
  const [hideZero, setHideZero] = useState(true);
  // const isInitRef = useRef(false);
  // const isInit = isInitRef.current;
  const [activeIndex, setActiveIndex] = useState<number>(
    walletsByType.findIndex((w) => w.id === currentWallet?.id),
  );
  const [prevType, setPrevType] = useState<WalletType>();

  const initialCarouselIndex = useMemo(() => {
    return walletsByType.findIndex((w) => w.id === currentWallet?.id);
  }, [walletsByType, currentWallet]);

  useEffect(() => {
    if (activeIndex < 0) {
      setActiveIndex(initialCarouselIndex);
    }
  }, [activeIndex, initialCarouselIndex]);

  const handleSelect = (selectedIndex: number) => {
    const wallet = walletsByType[selectedIndex];
    if (wallet) {
      setActiveIndex(selectedIndex);
      accountStore.setCurrentWallet(wallet);
    }
  };

  const zeroFiltered = useMemo(
    () => assets/*.filter((asset) => new BigNumber(asset.price ?? 0))*/,
    [assets],
  );

  const filteredAssets = useMemo(() => {
    return zeroFiltered;
    // return network === ChainId.ALL
    //   ? zeroFiltered
    //   : zeroFiltered.filter((asset) => asset.assetChain === network);
  }, [network, zeroFiltered]);

  // useEffect(() => {
  //   if (!isInit && currentWallet) {
  //     isInitRef.current = true;
  //     accountStore.loadData();
  //   }
  // }, [isInit, currentWallet]);

  // useEffect(() => {
  //   if (isInit) accountStore.loadData();
  // }, [currentWallet]);

  useEffect(() => {
    if (!prevType && currentWallet) {
      setPrevType(currentWallet.type);
    } else if (prevType && currentWallet && prevType !== currentWallet.type) {
      setPrevType(currentWallet.type);
    }
  }, [currentWallet, prevType]);

  useEffect(() => {
    setActiveIndex((prev) => {
      if (prev !== initialCarouselIndex) {
        return initialCarouselIndex;
      }
      return prev;
    });
  }, [initialCarouselIndex]);

  const portfolio = useMemo(() => {
    // if (!assetsLoaded) {
    //   return null;
    // }

    const result = _portfolio;
    if (_portfolio && !isEvmChain(network)) {
      return _portfolio;
    }
    // filteredAssets.forEach((asset) => {
    //   result.totalUsdBalance += asset.value;
    //   result.totalDiff.usd += asset.change1d;
    //   result.totalDiff.percents += asset.change1dInPercent;
    // });

    return result;
  }, [filteredAssets, _portfolio, assetsLoaded]);

  const totalDiffUsd = useMemo(() => {
    if (!portfolio) {
      return undefined;
    }
    const diff = portfolio.totalDiff.usd;
    return diff === undefined || diff === null ? null : diff;
  }, [portfolio]);

  usePersistentScroll({ key: 'wallet-page', isReady: assetsLoaded });

  return (
    <div className="full-page d-flex flex-column pb-0">
      <div className="py-3 d-flex justify-content-between align-items-center">
        {/* <div className="ps-2 pe-2 bg-semi-transparent-10 ht-40 tx-white text-decoration-none rounded-pill overflow-hidden cur-pointer d-flex align-items-center justify-content-center me-auto"> */}
        <NetworkSelectionPill />
        {/* </div> */}
        <HelpModal />
      </div>

      <Carousel
        className="pb-4 mb-2"
        controls={false}
        interval={null}
        activeIndex={activeIndex}
        defaultActiveIndex={initialCarouselIndex}
        onSelect={handleSelect}
        indicators={walletsByType.length > 1}
        key={`wallets-slider-${walletsByType.length}-${currentWallet?.type}`}
      >
        {walletsByType.map(({ name, address, id }) => (
          <Carousel.Item key={`wallets-slider-${id}`}>
            <div className="mt-3 d-flex flex-column align-items-center justify-content-center tx-center pb-3">
              <WalletSelection className="btn btn-transparent p-0 wd-35 ht-35 mb-2">
                <AmplitudeWrapper
                  eventInput={AmplitudeEvent.ACCOUNT_ICON_CLICKED}
                >
                  <UserAvatar string={address} size={35} network={network} />
                </AmplitudeWrapper>
              </WalletSelection>
              <div className="tx-14">
                {name || getEllipsisTxt(address || '', 4, '0x')}
              </div>
              <CopyButton className="cur-pointer" text={address} noIcon>
                <div className="tx-muted tx-12 d-flex align-items-center">
                  <span>{getEllipsisTxt(address || '', 4, '0x')}</span>
                  <img src={copy} alt="Copy" width={14} className="ms-1" />
                </div>
              </CopyButton>
              {!portfolio ? (
                <Placeholder width={115} height={32} className="mt-2" />
              ) : (
                <FormattedNumber
                  value={portfolio.totalUsdBalance}
                  subZeros
                  className="tx-32 tx-semibold mt-2"
                  suffix="$"
                  floor
                />
              )}
              {totalDiffUsd === undefined &&
                network !== ChainId.TRON &&
                network !== ChainId.SUI && <Placeholder width={91} height={20} className="mt-1" />}
              {totalDiffUsd !== undefined &&
                network !== ChainId.TRON &&
                network !== ChainId.SUI && (
                  <div
                    className={cn([
                      !totalDiffUsd ||
                      totalDiffUsd === 0
                        ? 'tx-muted bg-semi-transparent-10'
                        : totalDiffUsd > 0
                          ? 'tx-success bg-semi-success-10'
                          : 'tx-danger bg-semi-danger-10',
                      !totalDiffUsd && 'd-none',
                      'tx-12',
                      'badge',
                      'badge-pill',
                      'tx-normal',
                      'px-2',
                      'lh-2',
                    ])}
                  >
                    <FormattedNumber
                      value={portfolio ? portfolio.totalDiff.usd || 0 : null}
                      suffix="$"
                      withSign
                      floor
                      hideNull
                    />
                    <span className="mx-1">•</span>
                    <FormattedNumber
                      value={
                        portfolio ? portfolio.totalDiff.percents || 0 : null
                      }
                      postfix="%"
                      floor
                      hideNull
                    />
                  </div>
                )}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="d-flex justify-content-between align-items-center gap-2">
        <div className="tx-center wd-100p">
          <button
            className="btn btn-semi-10 tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
            onClick={w(
              () => navigate(PageRoutes.BRIDGE),
              AmplitudeEvent.BRIDGE_BUTTON_CLICKED,
            )}
          >
            <img src={refresh} alt="Swap" width={20} className="py-1" />
          </button>
          <div className="tx-semibold mt-1 tx-14">{t('common.bridge')}</div>
        </div>
        <div className="tx-center wd-100p">
          <button
            className="btn btn-semi-10 tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
            onClick={w(
              () => navigate(PageRoutes.RECEIVE),
              AmplitudeEvent.RECEIVE_BUTTON_CLICKED,
            )}
          >
            <img src={arrowDown} alt="QR-Code" width={20} className="py-1" />
          </button>
          <div className="tx-semibold mt-1 tx-14">{t('common.deposit')}</div>
        </div>
        <div className="tx-center wd-100p">
          <button
            className="btn btn-semi-10 tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
            onClick={w(
              () => navigate(PageRoutes.SEND),
              AmplitudeEvent.SEND_BUTTON_CLICKED,
            )}
          >
            <img src={arrowUp} alt="Send" width={20} className="py-1" />
          </button>
          <div className="tx-semibold mt-1 tx-14">{t('common.send')}</div>
        </div>
      </div>

      <div className="row flex-1 mt-3">
        <div className="card ht-100p d-flex flex-column" id="assets-card">
          <TabsCard
            list={[
              { value: t('common.assets') },
              { value: t('common.history') },
            ]}
            active={activeTab}
            onClick={(v) =>
              w(
                () => setActiveTab(v),
                v.includes(t('common.assets'))
                  ? AmplitudeEvent.ASSETS_TAB_CLICKED
                  : AmplitudeEvent.HISTORY_TAB_CLICKED,
              )()
            }
          >
            <div>
              <div className={`tab-element tab-${t('common.assets')}`}>
                {assetsLoaded && zeroFiltered.length !== assets.length && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-link tx-primary tx-right px-0 text-decoration-none"
                      onClick={w(
                        () => setHideZero((prev) => !prev),
                        AmplitudeEvent.SPAM_TOGGLED_ASSETS,
                      )}
                    >
                      {!hideZero
                        ? t('wallet.hide-zero-tokens')
                        : t('wallet.show-all-hidden', {
                            hiddenCount: assets.length - zeroFiltered.length,
                          })}
                    </button>
                  </div>
                )}
                {!assetsLoaded && (
                  <Cloner className="my-3" count={5}>
                    <ListItemSkeleton />
                  </Cloner>
                )}
                {assetsLoaded &&
                  assets.map((asset, index) => (
                    <ListItem
                      asset={asset}
                      chartLink={settingsStore.getChartLink(
                        network,
                        asset.address,
                        currentWallet?.address,
                      )}
                      hide={
                        false
                        // (network !== ChainId.ALL &&
                        //   asset.assetChain !== network) ||
                        // (hideZero && new BigNumber(asset ?? 0))
                      }
                      key={`token-list-item-${asset.name}-${index}`}
                    />
                  ))}
                {assetsLoaded && !assets.length && (
                  <div className="tx-center my-4">
                    <img src={gem} alt="Gem" />
                    <div className="tx-muted my-3">
                      {t('wallet.portfolio-empty')}
                    </div>
                    <Link to={PageRoutes.RESEARCH} className="btn btn-primary">
                      {t('common.research')}
                    </Link>
                  </div>
                )}
                {assetsLoaded &&
                  !!assets.length &&
                  !filteredAssets.length &&
                  (hideZero || (!hideZero && !!zeroFiltered.length)) && (
                    <div className="tx-center my-4">
                      <BadgeIcon className="tx-40" badgeSize={85}>
                        👜
                      </BadgeIcon>
                      <div className="tx-28 tx-semibold my-2">
                        {t('wallet.no-assets-on-network')}
                      </div>
                      <div className="tx-17 tx-muted">
                        {t('wallet.switch-network')}
                      </div>
                    </div>
                  )}
              </div>
              <div className={`tab-element tab-${t('common.history')}`}>
                {!transactionsLoaded && (
                  <Cloner className="my-3" count={5}>
                    <ListItemSkeleton />
                  </Cloner>
                )}
                {transactionsLoaded && !!transactions.length && (
                  <History network={network as unknown as Blockchain} />
                )}
                {transactionsLoaded && !transactions.length && (
                  <div className="tx-center my-4">
                    <BadgeIcon className="tx-40" badgeSize={85}>
                      😢
                    </BadgeIcon>
                    <div className="tx-28 tx-semibold my-2">
                      {t('wallet.history-empty')}
                    </div>
                    <div className="tx-17 tx-muted">
                      {t('wallet.transaction-history-display')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabsCard>
        </div>
      </div>
    </div>
  );
});

export default Wallet;
