import React, { useMemo, useRef, useState } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import InputGroup from '@components/common/InputGroup';
import Toggle from 'react-toggle';
import OrderSettingsModal from '@pages/LimitOrders/components/OrderSettingsModal';
import swapArrow from '@assets/images/switch-values.svg';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import TakeProfitValue from '@pages/LimitOrders/components/OrderValue/TakeProfitValue';
import StopLossValue from '@pages/LimitOrders/components/OrderValue/StopLossValue';
import cn from 'classnames';
import { IOrderSettings } from '../../../types';
import warning from '@assets/icons/warning.svg';
import { chainToken, chainToWallet } from '@helpers/chains';
import { ChainId } from '../../../constants';
import WalletListItem from '@components/common/WalletListItem';
import TPSLTriggerGroup from '@pages/LimitOrders/components/OrderValue/TPSLTriggerGroup';

const QuickTradeSettings = observer(() => {
  const { t } = useTranslation();

  const { quickTradeStore, accountStore } = useStores();
  const {
    chain,
    currency,
    isStopLossesEnabled,
    isTakeProfitsEnabled,
    emptyInputsLightOn,
    amount,
    amountUsd,
    orderSettings,
    needConfirm,
    name,
  } = quickTradeStore;

  const { wallets } = accountStore;

  const changeLock = useRef(false);

  const token = useMemo(() => {
    return chainToken(chain || ChainId.ETHER);
  }, [chain]);

  const [isUsd, setIsUsd] = useState(true);

  const wallet = useMemo(() => {
    return quickTradeStore.wallet();
  }, [quickTradeStore.walletId]);

  const updateSettings = (v: IOrderSettings) => {
    quickTradeStore.setOrderSettings(v);
  };

  const amountChange = (v: string) => {
    quickTradeStore.setAmount(v);
  };

  const amountUsdChange = (v: string) => {
    quickTradeStore.setAmountUsd(v);
  };

  const ethValue = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    return new BigNumber(amount);
  }, [amount]);

  const handleChange = (
    v: string | number | null,
    force?: 'usd' | 'native' | false,
  ) => {
    const bn = new BigNumber(v || 0);
    if (force === 'usd') {
      changeLock.current = true;
      amountUsdChange(bn.toString());
      amountChange(bn.dividedBy(currency || 1).toString());
    }
    if (force === 'native') {
      changeLock.current = true;
      amountChange(bn.toString());
      amountUsdChange(bn.multipliedBy(currency || 0).toString());
    }

    if (!changeLock.current) {
      if (isUsd) {
        amountUsdChange((bn ?? '0').toString());
        amountChange(bn.dividedBy(currency || 1).toString());
      } else {
        amountChange((bn ?? '0').toString() ?? 0);
        amountUsdChange(bn.multipliedBy(currency || 0).toString());
      }
    }
    changeLock.current = false;
  };

  const switchMode = () => {
    changeLock.current = true;

    setIsUsd((prev) => !prev);
  };

  const filteredWallets = useMemo(() => {
    const type = chainToWallet(chain as ChainId);
    return wallets.filter((w) => w.type === type);
  }, [wallets, chain]);

  return (
    <>
      <div className="card mb-3 mt-4 gap-3">
        <div className="tx-14 tx-semibold">{t('quick-trade.preset-name')}</div>
        <InputGroup
          value={name || null}
          placeholder={t('quick-trade.preset-name-placeholder')}
          onChange={(v) => quickTradeStore.setName(v?.toString() || '')}
        />
      </div>

      <div
        className={`mb-3 gap-3 ${filteredWallets.length > 1 ? 'card' : 'd-none'}`}
      >
        <div className="tx-14 tx-semibold">
          {t('quick-trade.choose-wallet')}
        </div>
        {filteredWallets.map((w) => (
          <WalletListItem
            key={`QuickTrade-WalletListItem-${w.id}`}
            wallet={w}
            onClick={() => quickTradeStore.setWalletId(w.id)}
            selected={wallet?.id === w.id}
          />
        ))}
      </div>

      <div className="rounded-3 overflow-hidden">
        <div className={cn(['card', 'rounded-3-px', 'rounded-top'])}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="tx-14 tx-semibold">{t('common.buy-amount')}</div>
            <OrderSettingsModal
              data={orderSettings}
              updateSettings={updateSettings}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center gap-2">
            {!isUsd && (
              <>
                <InputGroup
                  value={amount && +amount ? amount : null}
                  onChange={(v) => handleChange(v)}
                  placeholder={`0 ${token}`}
                  unit={token}
                  isNumeric
                  isInValid={
                    emptyInputsLightOn &&
                    (ethValue ? ethValue.isLessThanOrEqualTo(0) : false)
                  }
                  className="ht-50"
                />
              </>
            )}
            {isUsd && (
              <>
                <InputGroup
                  value={amountUsd && +amountUsd ? amountUsd : null}
                  onChange={(v) => handleChange(v)}
                  placeholder={`$0`}
                  unit="$"
                  isNumeric
                  isInValid={
                    emptyInputsLightOn &&
                    (ethValue ? ethValue.isLessThanOrEqualTo(0) : false)
                  }
                  className="ht-50"
                />
              </>
            )}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between gap-2 mt-2">
            <div
              className="card bg-semi-transparent-10 py-1 ht-35 tx-14 justify-content-center tx-muted px-2 wd-100p cur-pointer"
              onClick={switchMode}
            >
              {!isUsd && (
                <FormattedNumber
                  value={amountUsd || 0}
                  suffix="$"
                  className="ms-1"
                  subZeros
                />
              )}
              {isUsd && (
                <FormattedNumber
                  value={amount || 0}
                  decimals={ethValue?.isGreaterThan(1000) ? 0 : undefined}
                  postfix={token}
                  className="ms-1"
                  subZeros
                />
              )}
            </div>
            <img
              src={swapArrow}
              alt="Swap"
              className="cur-pointer wd-30 mn-wd-30"
              onClick={switchMode}
            />
          </div>

          <div className="d-flex mt-2">
            <div className="d-flex align-items-center gap-2 wd-50p">
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={isTakeProfitsEnabled}
                onChange={(e) =>
                  quickTradeStore.switchTakeProfits(e.target.checked)
                }
              />
              <span className="tx-white tx-13 tx-bold">
                {t('order.take-profit')}
              </span>
            </div>

            <div className="d-flex align-items-center gap-2 wd-50p">
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={isStopLossesEnabled}
                onChange={(e) =>
                  quickTradeStore.switchStopLosses(e.target.checked)
                }
              />
              <span className="tx-white tx-13 tx-bold">
                {t('order.stop-loss')}
              </span>
            </div>
          </div>
        </div>
        <TPSLTriggerGroup mode="quick-trade" />
        <TakeProfitValue titleClassName="tx-14 tx-semibold" />
        <StopLossValue titleClassName="tx-14 tx-semibold" />
      </div>

      <div className="card mt-3 mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="tx-14 tx-semibold">
            {t('quick-trade.buy-confirmation-title')}
          </div>
          <Toggle
            icons={false}
            className="styled-toggle"
            checked={!needConfirm}
            onChange={(e) => quickTradeStore.setNeedConfirm(!e.target.checked)}
          />
        </div>
        <div className="tx-12 tx-muted">
          {t('quick-trade.buy-confirmation-description')}
        </div>
        <div className="card p-2 bg-semi-warning-10 tx-12 tx-warning flex-row align-items-start mt-2">
          <img src={warning} alt="warning" className="me-2" />
          <span>{t('quick-trade.buy-confirmation-alert')}</span>
        </div>
      </div>
    </>
  );
});

export default QuickTradeSettings;
