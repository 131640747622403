import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import SideNavItem from '@pages/Wallet/SideNavItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import cross from '@assets/icons/cross-filled.svg';
import pin from '@assets/icons/pin-code.svg';
import update from '@assets/icons/update.svg';
import lock from '@assets/icons/lock.svg';
import ResetPinModal from '@pages/Profile/SecuritySettings/ResetPinModal';
import { PageRoutes } from '../../constants';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const Security = observer(() => {
  const { authStore } = useStores();
  const { isPinEnabled } = authStore;
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const navigate = useNavigate();

  const [showResetModal, setShowResetModal] = useState<boolean>(false);

  const list = isPinEnabled
    ? [
        {
          title: t('profile.change-pin'),
          icon: update,
          onClick: w(
            () => navigate(PageRoutes.CHANGE_PIN),
            AmplitudeEvent.CHANGE_PIN_CLICKED,
          ),
        },
        {
          title: t('profile.disable-pin'),
          icon: cross,
          onClick: w(
            () => setShowResetModal(true),
            AmplitudeEvent.DISABLE_PIN_CLICKED,
          ),
        },
      ]
    : [
        {
          title: t('profile.enable-pin'),
          icon: pin,
          onClick: w(
            () =>
              navigate({
                pathname: PageRoutes.CHANGE_PIN,
                search: createSearchParams({ enabling: 'true' }).toString(),
              }),
            AmplitudeEvent.ENABLE_PIN_CLICKED,
          ),
        },
      ];

  return (
    <div className="tx-left full-page d-flex flex-column">
      <div className="tx-28 tx-semibold my-3 d-flex align-items-center">
        <img src={lock} alt={t('common.security')} className="wd-25 me-2" />
        <span>{t('common.security')}</span>
      </div>
      <div className="card p-0 mb-4 overflow-hidden">
        {list.map((p, idx, { length }) => (
          <SideNavItem
            {...p}
            key={`security-nav-item-${p.title}`}
            isLast={++idx === length}
          />
        ))}
      </div>

      {isPinEnabled && (
        <ResetPinModal
          show={showResetModal}
          onClose={() => setShowResetModal(false)}
        />
      )}
    </div>
  );
});

export default Security;
