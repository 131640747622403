import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TokenLogo from '@components/common/TokenLogo';
import FormattedNumber from '@components/common/FormattedNumber';
import { chainName } from '@helpers/chains';
import { SendTokenSelectParams } from '@pages/Send/index';
import BadgeIcon from '@components/common/BadgeIcon';
import { IAsset } from '../../types';
import useDebounce from '@hooks/useDebounce';
import Preloader from '@components/common/Preloader';
import { useTranslation } from 'react-i18next';
import { GetPortfolioTokensResponse, PortfolioToken } from 'types/portfolio.model';

const SendTokenSelect = observer(
  ({ network, setAsset, isReceiveSelection }: SendTokenSelectParams) => {
    const { t } = useTranslation();
    const { walletStore, accountStore } = useStores();
    const { assets } = walletStore;
    const [search, setSearch] = useState<string>('');
    const [foundAssets, setFoundAssets] = useState<GetPortfolioTokensResponse>(
      [],
    );
    const debounceSearch = useDebounce(search, 500);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const allAssets = assets;

    const includesSearch = useCallback(
      (value = '') => {
        return (value || '').toLowerCase().includes(search.toLowerCase());
      },
      [search],
    );

    const foundNetworkAssets = useMemo(() => {
      return foundAssets;
      // .filter((asset) => asset.assetChain === network);
    }, [foundAssets, network]);

    const filteredAssets = useMemo(() => {
      if (!search) return allAssets.map((asset) => asset.address);
      return allAssets
        .filter(
          ({ name, address }) =>
            includesSearch(name) ||
            includesSearch(address) ||
            includesSearch(address),
        )
        .map((asset) => asset.address);
    }, [search, allAssets, includesSearch]);

    const handleNavigate = useCallback(
      (asset: PortfolioToken) => {
        setAsset(asset);
      },
      [setAsset],
    );

    useEffect(() => {
      if (search) {
        setIsLoading(true);
      }
    }, [search]);

    useEffect(() => {
      if (isReceiveSelection && debounceSearch) {
        setIsLoading(true);
        accountStore
          .searchTokens(debounceSearch)
          .then((assets) => {
            setIsLoading(false);
            setFoundAssets(assets);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      } else {
        setFoundAssets([]);
      }
    }, [isReceiveSelection, debounceSearch]);

    return (
      <div className="d-flex flex-column wd-100p py-3">
        <div className="d-flex mb-3">
          <input
            type="text"
            className="form-control py-1 tx-gray-400 me-2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onBlur={() => window.scrollTo(0, 0)}
            placeholder="Token Name, Address"
          />
          <button
            className="btn btn-link tx-primary px-0 py-0 tx-13 text-decoration-none"
            onClick={() => setAsset(null)}
          >
            {t('common.cancel')}
          </button>
        </div>

        <div className="wd-100p mx-auto gradient-transition overflow-auto mb-3">
          <div className="d-flex align-items-center ht-100p flex-row wd-100p">
            {allAssets.map((asset, index) => {
              const { address, image, name, symbol } = asset;
              return (
                <div
                  className={`${filteredAssets.includes(address) ? 'd-flex' : 'd-none'} align-items-center px-3 py-2 rounded-2 bg-secondary ${filteredAssets.length === index + 1 ? '' : 'me-2'}`}
                  key={`send-token-list-thumb-${address}`}
                  onClick={() => handleNavigate(asset)}
                >
                  <TokenLogo
                    logo={image}
                    address={address}
                    name={name}
                    size={25}
                    chain={network}
                    hideChain
                  />
                  <div className="tx-15 tx-semibold ms-2">{symbol}</div>
                </div>
              );
            })}
          </div>
        </div>

        {!filteredAssets.length && !isLoading && !foundNetworkAssets.length && (
          <div className="tx-center">
            <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">
              🔎
            </BadgeIcon>
            <h1 className="tx-28 mt-4">{t('order.empty-message')}</h1>
            <div className="tx-muted my-3">{t('common.nothing-found')}</div>
          </div>
        )}

        {isReceiveSelection &&
          (isLoading || foundNetworkAssets.length > 0) &&
          !!search && (
            <>
              <div className="tx-semibold tx-17">
                {t('sending.network-tokens')}:
              </div>
              {isLoading && <Preloader inline />}
              {!isLoading && (
                <div className="d-flex flex-column wd-100p">
                  {foundNetworkAssets.slice(0, 10).map((asset) => {
                    const { address, name, symbol, image } = asset;
                    return (
                      <div
                        className={`card d-flex flex-row justify-content-between align-items-center wd-100p mb-3`}
                        key={`found-token-list-${address}-${symbol}`}
                        onClick={() => handleNavigate(asset)}
                      >
                        <TokenLogo
                          logo={image}
                          address={address}
                          name={name}
                          size={45}
                          chain={network}
                        />
                        <div className="wd-100p ms-3">
                          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold mb-1">
                            <div className="word-break">{name}</div>
                            <div className="word-break">{symbol}</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
                            <div>{chainName(network)}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}

        {filteredAssets.length > 0 && (
          <>
            <div className="tx-semibold tx-17 mt-3">
              {t('sending.your-tokens')}:
            </div>

            <div className="d-flex flex-column wd-100p">
              {allAssets.map((asset) => {
                const {
                  address,
                  balance: { usd: value, token: quantity },
                  name,
                  symbol,
                  image,
                } = asset;
                return (
                  <div
                    className={`card ${filteredAssets.includes(address) ? 'd-flex' : 'd-none'} flex-row justify-content-between align-items-center wd-100p mb-3`}
                    key={`send-token-list-${address}-${symbol}`}
                    onClick={() => handleNavigate(asset)}
                  >
                    <TokenLogo
                      logo={image}
                      address={address}
                      name={name}
                      size={45}
                      chain={network}
                    />
                    <div className="wd-100p ms-3">
                      <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold mb-1">
                        <div className="word-break mx-wd-50p">{name}</div>
                        <FormattedNumber value={quantity} postfix={symbol} />
                      </div>
                      <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
                        <div>{chainName(network)}</div>
                        <FormattedNumber value={value} suffix="$" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  },
);

export default SendTokenSelect;
