import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ListItem from '@pages/Wallet/ListItem';
import Preloader from '@components/common/Preloader';
import FormattedNumber from '@components/common/FormattedNumber';
import {
  PortfolioTransaction,
  PortfolioTransactionStatus,
  PortfolioTransactionType,
} from '../../types';
import { format } from 'date-fns';
import TokenHistoryItem from '@pages/Asset/TokenHistoryItem';
import swap from '@assets/icons/swap-dark.svg';
import plane from '@assets/icons/plane-dark.svg';
import qrCode from '@assets/icons/qr-code-dark.svg';
import gas from '@assets/icons/gas-white.svg';
import { Modal } from 'react-bootstrap';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import BigNumber from 'bignumber.js';
import Placeholder from '@components/common/Placeholder';
import useNotification from '@hooks/useNotification';
import CopyButton from '@components/common/CopyButton';
import { getEllipsisTxt } from '@helpers/formatters';
import TransactionDetails from '@pages/Wallet/components/TransactionDetails';
import { useTranslation } from 'react-i18next';
import { isMobile } from '@helpers/device';
import cn from 'classnames';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { Blockchain } from '../../types/enums';
import { Events } from '@stores/token-store';
import { PriceUpdateResponse } from 'types/socket/price-update.types';
import { PortfolioToken } from '../../types/portfolio.model';
import { chainName, chainToken } from '@helpers/chains';
import { ResearchPairResponse } from '../../types/research/research-pair.response';
import BadgeIcon from '@components/common/BadgeIcon';

const Asset = observer(() => {
  const { t } = useTranslation();
  const {
    tokenStore,
    accountStore,
    walletStore,
    settingsStore,
    socketStore,
    researchStore,
  } = useStores();
  const { socket } = socketStore;
  const { tokenDetails } = tokenStore;
  const { isEvm, isTron, isSui, network } = accountStore;
  const { assets } = walletStore;
  const { address } = useParams();
  const [token, setToken] = useState<PortfolioToken>();
  const [w, AmplitudeWrapper] = useAmplitude([
    AmplitudeEvent.TOKEN_VIEWED,
    { address },
  ]);
  const [tokenTransactions, setTokenTransactions] = useState<
    PortfolioTransaction[]
  >([]);
  const [times, setTimes] = useState<string[]>([]);
  const [groups, setGroups] = useState<Array<PortfolioTransaction[]>>([]);
  const [selectedTx, setSelectedTx] = useState<PortfolioTransaction | null>(
    null,
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const notify = useNotification();
  const { pathname } = useLocation();

  const isNative = useMemo(() => {
    return (
      (token && (!token.address || token.address.includes('0x000000000000'))) ||
      (address && address.toLowerCase() === chainName(network).toLowerCase())
    );
  }, [token, network, address]);

  const boughtSold = useMemo(() => {
    let boughtQty = new BigNumber(0);
    let boughtUsd = new BigNumber(0);
    let soldQty = new BigNumber(0);
    let soldUsd = new BigNumber(0);

    tokenTransactions.forEach((item) => {
      const { tokenSent, tokenReceived } = item;
      if (item.type === PortfolioTransactionType.Trade) {
        [tokenSent, tokenReceived].forEach((item, index) => {
          if (item && item.symbol === token?.symbol) {
            if (index === 1) {
              boughtQty = boughtQty.plus(item.amount);
              boughtUsd = boughtUsd.plus(item.amountUsd);
            } else {
              soldQty = soldQty.plus(item.amount);
              soldUsd = soldUsd.plus(item.amountUsd);
            }
          }
        });
      }
    });

    return {
      bought: { qty: boughtQty.toNumber(), usd: boughtUsd.toNumber() },
      sold: { qty: soldQty.toNumber(), usd: soldUsd.toNumber() },
    };
  }, [tokenTransactions, token]);

  const fee = useMemo(() => {
    let totalQty = new BigNumber(0);

    tokenTransactions.forEach((item) => {
      const { fee } = item;
      totalQty = totalQty.plus(fee);
    });

    return { qty: totalQty.toNumber(), symbol: chainToken(network) };
  }, [tokenTransactions, network]);

  const { balance, price, symbol, balanceDiff, name, image } =
    token || ({} as PortfolioToken);

  const handleBuySell = (isBuy: boolean) => {
    navigate(`/limit-orders/${network}/${token?.address}/trade`, {
      state: { action: isBuy ? 'buy' : 'sell', token, back: pathname },
    });
  };

  useEffect(() => {
    if (!token) {
      setToken(assets.find((token) => token.address === address));
    }
  }, [token, assets, address]);

  useEffect(() => {
    if (token) {
      const payload = {
        blockchain: network,
        tokenAddresses: [address],
      };

      socket?.on(Events.SUBSCRIBE_PRICE_UPDATE, (data: PriceUpdateResponse) => {
        setToken((s) => {
          if (s) {
            s.balance.usd = new BigNumber(data.tokenPriceInUsd)
              .times(token.balance.token)
              .toString();

            return s;
          }
        });
      });

      socket?.emit(Events.SUBSCRIBE_PRICE_UPDATE, payload);

      return () => {
        socket?.off(Events.SUBSCRIBE_PRICE_UPDATE);
        socket?.emit(Events.UNSUBSCRIBE_PRICE_UPDATE);
      };
    }
  }, [token]);

  const filtered = useMemo(() => {
    return tokenTransactions;
    // if (isEvm) return tokenTransactions;
    // return tokenTransactions.filter((item) =>
    //   !operations.includes(item.operation)
    //     ? true
    //     : item.transferred.every((t) => t.quantity > 0),
    // );
  }, [tokenTransactions, isEvm]);

  const sorted = useMemo(
    () =>
      filtered.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }),
    [filtered],
  );

  const queue = useMemo(
    () =>
      sorted.filter(
        (item) => item.status === PortfolioTransactionStatus.Pending,
      ),
    [sorted],
  );

  useEffect(() => {
    if (isLoaded) {
      const _times: string[] = [];
      const _groups: Array<PortfolioTransaction[]> = [];
      sorted.forEach((item) => {
        if (item.status === PortfolioTransactionStatus.Pending) return;
        const time = format(new Date(item.date), 'dd.MM.yyyy');
        if (!_times.includes(time)) {
          _times.push(time);
          _groups.push([item]);
        } else {
          const index = _times.indexOf(time);
          _groups[index].push(item);
        }
      });
      setTimes(_times);
      setGroups(_groups);
    }
  }, [sorted, isLoaded]);

  useEffect(() => {
    if (token) {
      walletStore
        .getTransactions({
          tokenAddress: address,
          blockchain: network as unknown as Blockchain,
        })
        .then((response) => {
          setTokenTransactions(response.value ?? response);
          setIsLoaded(true);
        })
        .catch(() => {
          notify(t('common.error-loading-transactions'), { type: 'danger' });
          setIsLoaded(true);
        });
    } else if (isNative) {
      setTokenTransactions([]);
      setIsLoaded(true);
    }
  }, [token, accountStore]);

  if (!token) {
    return <Preloader className="py-3" inline />;
  }

  return (
    <div className="full-page pb-0 pt-4 d-flex flex-column">
      <ListItem
        asset={token}
        inlineChange={undefined}
        chartLink={settingsStore.getChartLink(
          network,
          token.address,
          accountStore.currentWallet?.address,
        )}
        isInline
      />

      <div className="mt-3 mb-5 d-flex flex-column align-items-center justify-content-center tx-center">
        <FormattedNumber
          value={balance.usd || 0}
          className="tx-34 tx-semibold"
          suffix="$"
          floor
        />
        <div
          className={
            token.balanceDiff.percents === 0
              ? 'tx-muted'
              : (token.balanceDiff.percents || 0) > 0
                ? 'tx-success'
                : 'tx-danger'
          }
        >
          <FormattedNumber
            value={balance.token}
            postfix={token.symbol}
            className="tx-muted me-1"
          />
          {!isTron && !isSui && (
            <>
              <FormattedNumber
                value={new BigNumber(token.balanceDiff.usd || 0).toNumber()}
                className="tx-15 me-1"
                suffix="$"
                withSign
                floor
              />
              <FormattedNumber
                value={token.balanceDiff.percents || 0}
                className="tx-15"
                suffix="("
                postfix="%)"
                floor
              />
            </>
          )}
        </div>
        {!!token.address && (
          <AmplitudeWrapper
            eventInput={AmplitudeEvent.TOKEN_CONTRACT_ADDRESS_COPIED}
          >
            <CopyButton
              text={token.address}
              className="btn btn-link tx-13 p-0 tx-left text-decoration-none tx-muted"
            >
              {`CA: ${getEllipsisTxt(token.address, 6)}`}
            </CopyButton>
          </AmplitudeWrapper>
        )}
      </div>

      {isEvm && (
        <div className="d-flex justify-content-between mb-5">
          <div className="card wd-100p me-2">
            <div className="tx-15">{t('common.bought')}</div>
            {isLoaded ? (
              <>
                <FormattedNumber
                  value={boughtSold.bought.usd}
                  suffix="$"
                  className="tx-17"
                />
                <FormattedNumber
                  value={boughtSold.bought.qty}
                  postfix={token.symbol}
                  className="tx-muted me-1 tx-13"
                />
              </>
            ) : (
              <>
                <Placeholder width={80} height={17} />
                <Placeholder width={130} height={13} />
              </>
            )}
          </div>

          <div className="card wd-100p ms-2">
            <div className="tx-15">{t('common.sold')}</div>
            {isLoaded ? (
              <>
                <FormattedNumber
                  value={boughtSold.sold.usd}
                  suffix="$"
                  className="tx-17"
                />
                <FormattedNumber
                  value={boughtSold.sold.qty}
                  postfix={token.symbol}
                  className="tx-muted me-1 tx-13"
                />
              </>
            ) : (
              <>
                <Placeholder width={80} height={17} />
                <Placeholder width={130} height={13} />
              </>
            )}
          </div>
        </div>
      )}

      <div className="row flex-1">
        <div
          className="card d-flex flex-column pb-5 mn-ht-300"
          id="assets-card"
        >
          <div className="d-flex justify-content-between mb-4">
            <div className="tx-17 tx-semibold">{t('common.history')}</div>
            {isEvm && (
              <div className="tx-13">
                <span className="tx-muted me-1">
                  <img src={gas} alt="Gas" width={16} className="me-1" />
                  {t('common.total')}:
                </span>
                <FormattedNumber
                  value={fee.qty}
                  decimals={4}
                  postfix={fee.symbol}
                />
                {/*{isEvm && (*/}
                {/*  <FormattedNumber*/}
                {/*    value={fee.usd}*/}
                {/*    subZeros*/}
                {/*    suffix=" ($"*/}
                {/*    postfix=")"*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            )}
          </div>

          {!isLoaded && <Preloader className="py-3" inline />}

          {isLoaded && queue.length > 0 && (
            <div>
              <div className="tx-17 tx-semibold mb-3">
                {t('common.queue')} ({queue.length})
              </div>
              {queue.map((item, index) => (
                <TokenHistoryItem
                  item={item}
                  isLast={index === queue.length - 1}
                  key={`token-history-item-${item.hash}-${index}`}
                  name={token.name}
                  symbol={token.symbol}
                  onClick={w(
                    () => setSelectedTx(item),
                    AmplitudeEvent.TOKEN_HISTORY_ITEM_CLICKED,
                    { token, transactionHash: item.hash },
                  )}
                />
              ))}
            </div>
          )}

          {isLoaded && (
            <div className="ht-100p">
              <div className="tx-17 tx-semibold mt-3 mb-3">
                {queue.length > 0 ? t('common.history') : ''}
              </div>
              {!times.length && (
                <div className="tx-center my-4">
                  <BadgeIcon className="tx-40" badgeSize={85}>
                    😢
                  </BadgeIcon>
                  <div className="tx-28 tx-semibold my-2">
                    {t('wallet.history-empty')}
                  </div>
                  <div className="tx-17 tx-muted">
                    {t('wallet.transaction-history-display')}
                  </div>
                </div>
              )}
              {times.map((time, index) => (
                <div
                  key={`history-time-group-${time}-${index}`}
                  className="mb-5"
                >
                  <div className="tx-center tx-12 tx-muted my-2">{time}</div>
                  {groups[index].map((item, groupIndex) => (
                    <TokenHistoryItem
                      item={item}
                      isLast={groupIndex === groups[index].length - 1}
                      key={`token-history-item-${item.hash}-${index}`}
                      name={token.name}
                      symbol={token.symbol}
                      onClick={() => {
                        if (isTron) {
                          window.open(
                            `https://tronscan.org/#/transaction/${item.hash}`,
                            '_blank',
                          );
                          return;
                        }
                        setSelectedTx(item);
                      }}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}

          <div
            id="assets-actions"
            className={cn([
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'gap-3',
              !isMobile() && 'desktop',
            ])}
          >
            {isNative && (
              <>
                <button
                  className="btn btn-primary wd-100p"
                  onClick={() =>
                    navigate(PageRoutes.SEND, {
                      state: { asset: JSON.stringify(token), chain: network, back: pathname },
                    })
                  }
                >
                  {t('common.send')}
                </button>
                <button
                  className="btn btn-primary wd-100p"
                  onClick={() =>
                    navigate(PageRoutes.RECEIVE, {
                      state: { network, back: pathname },
                    })
                  }
                >
                  {t('common.receive')}
                </button>
              </>
            )}
            {!isNative && (
              <>
                <button
                  className="btn btn-primary wd-100p"
                  onClick={w(
                    () => handleBuySell(true),
                    AmplitudeEvent.TOKEN_BUY_CLICKED,
                    { token },
                  )}
                >
                  {t('common.buy')}
                </button>
                <button
                  className="btn btn-primary wd-100p"
                  onClick={w(
                    () => handleBuySell(false),
                    AmplitudeEvent.TOKEN_SELL_CLICKED,
                    { token },
                  )}
                >
                  {t('common.sell')}
                </button>
                <button
                  className="btn btn-primary smaller"
                  onClick={w(
                    () => setShowModal(true),
                    AmplitudeEvent.TOKEN_TRANSFER_CLICKED,
                    { token },
                  )}
                >
                  <img src={swap} alt="swap" width={24} className="mn-wd-25" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('common.more')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-semi-10 d-flex align-items-center px-3 py-3 wd-100p mb-3 tx-17 tx-semibold"
            onClick={w(
              () =>
                navigate(PageRoutes.SEND, {
                  state: { asset: JSON.stringify(token), back: pathname },
                }),
              AmplitudeEvent.TOKEN_TRANSFER_SEND_CLICKED,
              { token },
            )}
          >
            <div className="wd-45 ht-45 bg-white d-flex align-items-center justify-content-center rounded-circle me-3">
              <img src={plane} alt="Send" width={24} />
            </div>
            {t('common.send')}
          </button>

          <button
            className="btn btn-semi-10 d-flex align-items-center px-3 py-3 wd-100p tx-17 tx-semibold"
            onClick={w(
              () => navigate(PageRoutes.RECEIVE, { state: { back: pathname } } ),
              AmplitudeEvent.TOKEN_TRANSFER_RECEIVE_CLICKED,
              { token },
            )}
          >
            <div className="wd-45 ht-45 bg-white d-flex align-items-center justify-content-center rounded-circle me-3">
              <img src={qrCode} alt="Receive" width={24} />
            </div>
            {t('common.receive')}
          </button>
        </Modal.Body>
      </Modal>

      {selectedTx && (
        <TransactionDetails
          item={selectedTx}
          onClose={() => setSelectedTx(null)}
        />
      )}
    </div>
  );
});

export default Asset;
