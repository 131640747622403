import React, { useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import { observer } from 'mobx-react-lite';
import WalletSelection from '@components/WalletSelection';
import { getEllipsisTxt } from '@helpers/formatters';
import { useStores } from '@hooks/useStores';
import UserAvatar from '@components/common/UserAvatar';
import ListItemSkeleton from '@components/skeletons/ListItemSkeleton';

interface TradeWalletHeaderProps {
  className?: string;
  filterByType?: boolean;
}

const TradeWalletHeader = observer(
  ({ className, filterByType = false }: TradeWalletHeaderProps) => {
    const { accountStore, ordersStore } = useStores();
    const { balance } = ordersStore;
    const { currentWallet } = accountStore;

    const enabledTypes = useMemo(
      () => (filterByType && currentWallet ? [currentWallet.type] : []),
      [filterByType, currentWallet],
    );

    if (!currentWallet || !balance) {
      return (
        <div className={className}>
          <ListItemSkeleton />
        </div>
      );
    }

    return (
      <div
        className={`d-flex justify-content-between align-items-center ${className ?? className}`}
      >
        <WalletSelection
          className="btn btn-transparent p-0"
          filterTypes={enabledTypes}
        >
          <div className="d-flex align-items-center">
            <div className="wd-40 ht-40 mn-wd-40 mx-wd-40">
              <UserAvatar string={currentWallet?.address || ''} />
            </div>
            <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
              <div className="tx-17 lh-3">
                {currentWallet?.name ||
                  getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
              </div>
              <div className="tx-12 tx-muted">
                {getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
              </div>
            </div>
          </div>
        </WalletSelection>

        <div className="ms-auto me-0 d-flex flex-column tx-right">
          <div className="tx-14 tx-semibold">
            <FormattedNumber
              value={balance?.balanceInUsd}
              suffix="$"
              className="ms-1"
              noSpace
              floor
              subZeros
            />
          </div>
          <FormattedNumber
            className="tx-muted tx-12"
            value={balance?.balance}
            decimals={4}
            subZeros
            floor
            postfix={balance?.token}
          />
        </div>
      </div>
    );
  },
);

export default TradeWalletHeader;
