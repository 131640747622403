import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { greaterThan } from '@helpers/bignumber';
import { useStores } from '@hooks/useStores';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import BottomNav from '@components/BottomNav';
import cn from 'classnames';
import { isMobile } from '@helpers/device';
import {
  PageRoutes,
  portfolioRoutes,
  profileRoutes,
  referralRoutes,
  researchRoutes,
  tokenTradeRoutes,
} from '../constants';
import { useGesture } from 'react-use-gesture';
import { animated, useSpring } from '@react-spring/web';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';
import QuickTradeButtons from '@pages/QuickTrade/components/QuickTradeButtons';

interface MainLayoutProps {
  children: React.ReactNode;
}

const fullPageRoutes = ['/swap', '/'];

const bottomNavRoutesExact = [
  ...referralRoutes,
  ...portfolioRoutes,
  ...researchRoutes,
  ...profileRoutes,
  ...tokenTradeRoutes,
];

const rootPages: PageRoutes[] = [
  PageRoutes.WALLET,
  PageRoutes.RESEARCH,
  PageRoutes.LIMIT_ORDERS,
  PageRoutes.PROFILE,
  PageRoutes.REFERRAL,
];

const MainLayout = observer(
  forwardRef(function MainLayout(
    props: MainLayoutProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) {
    const { children } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const { settingsStore, ordersStore, accountStore } = useStores();
    const { checkDone } = accountStore;
    const { initialized, triggerUpdate, state } = settingsStore;
    const {
      initialized: ordersInitialized,
      triggerUpdate: ordersUpdate,
      state: ordersState,
    } = ordersStore;
    const lastTriggerUpdateRef = useRef(0);
    const lastOrdersUpdateRef = useRef(0);
    const WebApp = useWebApp();
    const navigate = useNavigate();
    const notify = useNotification();

    const MIN_DISTANCE = document.documentElement.clientWidth * 0.1;
    const SWIPE_VELOCITY = 1;

    const [properties, api] = useSpring(() => ({ x: 0 }));

    const [swiped, setSwiped] = useState<number | false>(false);
    const [hasModal, setHasModal] = useState<boolean>(false);

    const handleSwipe = () => {
      swiped && navigate(swiped);
    };

    const bindGestures = useGesture(
      {
        onDragEnd: ({ lastOffset: [lx], offset: [cx] }) => {
          Math.abs(cx - lx) > MIN_DISTANCE && handleSwipe();
          api.start({ x: 0 });
        },
        onDrag: (e) => {
          const {
            lastOffset: [lx],
            offset: [cx],
            direction: [dx],
            velocity,
          } = e;

          if (
            location.pathname !== PageRoutes.WALLET &&
            Math.abs(cx - lx) > MIN_DISTANCE &&
            velocity > SWIPE_VELOCITY &&
            Math.abs(cx - lx) > 0
          ) {
            setSwiped(dx > 0 ? -1 : 1);
            api.start({ x: (cx - lx) / 4 });
          }
        },
      },
      {
        drag: {
          filterTaps: true,
          experimental_preventWindowScrollY: true,
        },
      },
    );

    const bindMobileSwipes = isMobile() && !hasModal ? bindGestures() : {};

    useEffect(() => {
      if (initialized && lastTriggerUpdateRef.current < triggerUpdate) {
        settingsStore
          .saveSettings()
          .then((success) => {
            if (success) {
              notify(t('profile.settings-saved'));
            } else {
              notify(t('profile.error-saving-settings'), { type: 'danger' });
            }
          })
          .catch((e) => {
            console.error(e);
            notify(t('profile.error-saving-settings'), { type: 'danger' });
          })
          .finally(() => {
            lastTriggerUpdateRef.current = triggerUpdate;
          });
      }
    }, [initialized, triggerUpdate, lastTriggerUpdateRef, settingsStore]);

    useEffect(() => {
      if (ordersInitialized && lastOrdersUpdateRef.current < ordersUpdate) {
        lastOrdersUpdateRef.current = ordersUpdate;

        ordersState.forEach(({ value, key }) => {
          localStorage.setItem(key, value);
        });
        ordersStore.setSaved();
        // }
      }
    }, [
      WebApp,
      ordersState,
      ordersInitialized,
      ordersUpdate,
      lastOrdersUpdateRef,
      ordersStore,
    ]);

    useEffect(() => {
      const element = document.body;

      if (!element) return;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'class') {
            setHasModal(element.classList.contains('modal-open'));
          }
        });
      });

      observer.observe(element, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }, []);

    useEffect(() => {
      if (!ordersInitialized) {
        const keys = Object.keys(localStorage);
        const values: string[] = [];
        keys.forEach((key) => {
          values.push(localStorage.getItem(key) || '');
        });
        ordersStore.restoreSettings(keys, values);
      }
    }, [WebApp, ordersInitialized, ordersStore]);

    const className = useMemo(() => {
      return fullPageRoutes.some((route) => location.pathname === route)
        ? ''
        : 'container';
    }, [location.pathname]);

    const withBottomNav = useMemo(() => {
      return bottomNavRoutesExact.some((route) => location.pathname === route);
    }, [location.pathname]);

    return (
      <>
        <animated.div
          id="main-layout"
          className={cn(className)}
          ref={ref}
          style={{ transform: properties.x.to((v) => `translateX(${v}px)`) }}
          {...bindMobileSwipes}
        >
          {children}
        </animated.div>
        {withBottomNav && <BottomNav />}
        {rootPages.includes(location.pathname as PageRoutes) && checkDone && (
          <QuickTradeButtons />
        )}
      </>
    );
  }),
);

export default MainLayout;
