import React, { FocusEventHandler, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

type NumericType = string | number | null;

interface NumericInputProps {
  placeholder?: string;
  disabled?: boolean;
  onChange: (_v: NumericType) => void;
  isInvalid?: boolean;
  value: string | null;
  className?: string;
  asString?: boolean;
  noDecimal?: boolean;
  prefix?: string;
  postfix?: string;
}

const NumericInput = (props: NumericInputProps) => {
  const {
    value,
    placeholder,
    disabled,
    onChange,
    isInvalid,
    asString,
    noDecimal,
    prefix = '',
    postfix = '',
    className = 'form-control appearance-none',
  } = props;
  const [inputValue, setInputValue] = useState<string | null>(value);
  const randomId = useMemo(
    () => Math.random().toString(36).substring(7) + '' + Date.now(),
    [],
  );

  useEffect(() => {
    let resultValue: NumericType = inputValue;
    if (inputValue) {
      const hasCommas = inputValue.includes(',');
      const sign = hasCommas ? ',' : '.';
      const lastChar = inputValue[inputValue.length - 1];
      if (lastChar === sign) {
        return;
      }
      if (inputValue.split(sign).length > 2) {
        const splitted = inputValue.split(sign);
        console.log('splitted', splitted);
        onChange(`${splitted[0]}.${splitted[1]}`);
        return;
      }
      if (inputValue.indexOf(sign) === inputValue.length - 1) {
        console.log('inputValue', inputValue.split(sign)[0]);
        onChange(inputValue.split(sign)[0]);
        return;
      }
      resultValue =
        inputValue !== '' ? (inputValue || '0').replace(',', '.') : null;
    }
    
    onChange(resultValue);
  }, [asString, inputValue]);

  useEffect(() => {
    if (value === null || value === '') {
      setInputValue(null);
    }
  }, [value]);

  useEffect(() => {
    setInputValue((prev) => {
      const prevDot = /[.,]/g.test(prev || '');
      const valueDot = /[.,]/g.test(value || '');
      if (
        (prev && [`${value}.`, `${value},`].includes(prev)) ||
        (new BigNumber(value || '').eq(0) && prevDot && !valueDot)
      ) {
        return prev;
      }
      return value;
    });
  }, [value]);

  useEffect(() => {
    const input = document.getElementById(randomId);
    const prevent = (e: any) => e.preventDefault();
    input?.addEventListener('mousewheel', prevent);
    return () => input?.removeEventListener('mousewheel', prevent);
  }, [randomId]);

  return (
    <input
      type="text"
      inputMode="decimal"
      className={`${className} ${isInvalid ? 'is-invalid' : ''}`}
      placeholder={placeholder}
      value={`${prefix}${inputValue === undefined || inputValue === null ? '' : inputValue}${postfix}`}
      onChange={(e) => {
        e.target.value = e.target.value.replace(',', '.');
        const original = e.target.value
          .replace(/[^0-9.,]/g, '')
          .replace(prefix, '')
          .replace(postfix, '');
        if (original.length && !/^\d+(\.\d*)?$/.test(original))
          return;
        let value = original !== '' ? original : null;
        const hasCommas = value?.includes(',');
        const sign = hasCommas ? ',' : '.';
        if (noDecimal && value?.includes(sign)) {
          value = value.split(sign)[0];
        }

        setInputValue(value);
      }}
      onBlur={() => window.scrollTo(0, 0)}
      disabled={disabled}
      id={randomId}
    />
  );
};

export default NumericInput;
