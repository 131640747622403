import { Manager } from 'socket.io-client';
import { ENV } from '../constants';

export const URL = ENV.DEX_WSS_URL;

export const createSocket = (extraHeaders?: Record<string, string> | undefined, query?: Record<string, string> | undefined) =>
  new Manager(URL, {
    extraHeaders,
    query,
    transports: [ "websocket" ],
    transportOptions: {

    }
  }).socket('/');
