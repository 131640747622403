import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOrderSettings } from '../../../types';
import { Modal } from 'react-bootstrap';
import settingsIcon from '@assets/icons/settings-teal.svg';
import { numberRegex } from '../../../constants';
import usePopup from '@hooks/usePopup';
import useNotification from '@hooks/useNotification';
import { ApiError } from '@helpers/api';
import SnipeRow from '@pages/Profile/SnipeRow';
import SettingsInput from '@components/common/SettingsInput';
import Toggle from 'react-toggle';
import clone from '@helpers/clone';
import { smallerThan } from '@helpers/bignumber';
import { useTranslation } from 'react-i18next';
import { defaultOrderSettings } from '@stores/settings-store';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useStores } from '@hooks/useStores';
import { Blockchain } from 'types/enums';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';
import { SolPriorityMode } from '../../../types/user/settings.types';
import BigNumber from 'bignumber.js';
import TabsCard from '@components/common/TabsCard';
import solana from '@assets/images/solana.svg';
import NumericInput from '@components/common/NumericInput';

interface OrderSettingsModalProps {
  data: IOrderSettings;
  updateSettings: (data: IOrderSettings) => void;
}

const OrderSettingsModal = ({
                              data,
                              updateSettings,
                            }: OrderSettingsModalProps) => {
  const { tokenTrade, quickTradeStore } = useStores();
  const { chain: tradeChain } = tokenTrade;
  const { chain: quickChain } = quickTradeStore;
  const showPopup = usePopup();
  const notify = useNotification();
  const { t } = useTranslation();
  const [w] = useAmplitude();

  const chain = useMemo(() => quickChain ?? tradeChain, [tradeChain, quickChain]);

  const [show, setShow] = useState<boolean>(false);
  const [settings, setSettings] = useState<IOrderSettings>(
    clone(data ?? defaultOrderSettings(tradeChain as Blockchain)),
  );
  const [error, setError] = useState<ApiError | null>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isSolChecked, setIsSolChecked] = useState<boolean>(false);

  useEffect(() => {
    setSettings(clone(data ?? defaultOrderSettings(tradeChain as Blockchain)));
  }, [data]);

  useEffect(() => {
    if (!isSolChecked && chain === Blockchain.SOLANA) {
      setSettings((prev) => {
        if (!data) {
          return {
            ...prev,
            antiMevEnabled: false,
          };
        }
        return prev;
      });
      setIsSolChecked(true);
    }
  }, [chain, data, isSolChecked]);

  const handleClose = () => {
    if (!isSaved) {
      data && setSettings(clone(data));
    }
    setIsSaved(false);
    setShow(false);
  };

  const isInvalid = useCallback(
    (key: string, isReq = false) => {
      if (!settings) {
        return false;
      }
      // @ts-ignore
      const value = settings[key];
      if (isReq && (value === undefined || value === null || value === '')) {
        return true;
      }
      if (!isReq && (value === undefined || value === null || value === '')) {
        return false;
      }
      if (value && smallerThan(value, 0)) {
        return true;
      }
      if (key === 'slippage' && value && (value < 0 || value > 100)) {
        return true;
      }
      return (
        isNaN(value) ||
        !numberRegex.test(value.toString()) ||
        !!(error && error.error?.includes(key))
      );
    },
    [settings, error],
  );

  const solFeeInvalid = useMemo(() => {
    if (settings.solPriorityMode !== SolPriorityMode.CUSTOM) {
      return false;
    }
    return new BigNumber(settings.solPriorityFee || 0).isLessThan(0.0001);
  }, [settings.solPriorityFee, settings.solPriorityMode]);

  const isSolana = useMemo(() => {
    return chain === Blockchain.SOLANA;
  }, [chain]);

  const setSolMode = (mode: SolPriorityMode) => {
    setSettings({
      ...settings,
      solPriorityMode: mode,
    });
  };

  const setSolFee = (fee: number | null) => {
    setSettings({
      ...settings,
      solPriorityFee: fee,
    });
  };

  const handleOnChange = useCallback(
    (key: string, value: string | number | null) => {
      if (error && error.error?.includes(key)) {
        setError(null);
      }
    },
    [error],
  );

  const handleSubmit = () => {
    const checks = [
      {
        check: isInvalid('slippage'),
        label: t('trading.slippage'),
      },
      {
        check: isSolana ? solFeeInvalid : isInvalid('extraGasPrice'),
        label: isSolana ? t('profile.solana-priority-mode') : t('order.extra-gas-price'),
      },
    ];

    if (checks.some(({ check }) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid', {
        type: 'danger',
      });
      return;
    }

    setIsSaved(true);
    setTimeout(() => {
      updateSettings(clone(settings));
      handleClose();
    }, 50);
  };

  const handleReset = () => {
    showPopup({
      title: t('order.reset-settings'),
      message: t('order.confirm-reset'),
      buttons: [
        {
          text: t('common.reset'),
          type: 'danger',
          id: 'reset',
        },
        {
          text: t('common.no'),
          id: 'close',
        },
      ],
    }).then((result) => {
      if (result === 'reset') {
        setSettings(clone(defaultOrderSettings(tradeChain as Blockchain)));
      }
    });
  };

  const isInputHidden = (key: string) => {
    const disabledChains = {
      extraGasPricePercent: [Blockchain.TON, Blockchain.TRON, Blockchain.SOLANA],
      antiMevEnabled: [
        Blockchain.BSC,
        Blockchain.BASE,
        Blockchain.TON,
        Blockchain.TRON,
      ],
      maxGasPrice: [
        Blockchain.BSC,
        Blockchain.BASE,
        Blockchain.SOLANA,
        Blockchain.TON,
        Blockchain.TRON,
        Blockchain.SUI,
      ],
      autoApproveEnabled: [Blockchain.SOLANA, Blockchain.TON, Blockchain.TRON],
    }[key];

    return (
      disabledChains?.includes(tradeChain as Blockchain) ||
      disabledChains?.includes(quickChain as Blockchain)
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between wd-100p">
            <span>{t('order.order-settings')}</span>
            <button
              className="btn btn-transparent tx-primary tx-normal p-0 bd-none"
              onClick={w(
                handleReset,
                AmplitudeEvent.RESET_TO_DEFAULT_BUTTON_CLICKED,
              )}
            >
              {t('common.reset')}
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-column gap-12px mb-1">
            <SnipeRow>
              <SettingsInput
                className="tx-muted"
                data={settings}
                setHandler={setSettings}
                dataKey="slippage"
                isInvalidHandler={isInvalid}
                placeholder="5%"
                unit="%"
                onChange={handleOnChange}
                // tooltip={t('order.percentage-profit-sell-executed')}
              >
                {t('trading.slippage')}
              </SettingsInput>
            </SnipeRow>

            <SnipeRow>
              <SettingsInput
                data={settings}
                className={cn([
                  'tx-muted',
                  isInputHidden('extraGasPricePercent') && 'd-none',
                ])}
                setHandler={setSettings}
                dataKey="extraGasPricePercent"
                isInvalidHandler={isInvalid}
                placeholder={tradeChain === Blockchain.SOLANA ? "300%" : "10%"}
                unit="%"
                onChange={handleOnChange}
                // tooltip={t('order.extra-gas-price-description')}
              >
                {t('order.extra-gas-price')}
              </SettingsInput>

              <SettingsInput
                data={settings}
                className={cn([
                  'tx-muted',
                  isInputHidden('maxGasPrice') && 'd-none',
                ])}
                setHandler={setSettings}
                dataKey="maxGasPrice"
                isInvalidHandler={isInvalid}
                placeholder="200 GWEI"
                unit="GWEI"
                onChange={handleOnChange}
                // tooltip={t('order.extra-gas-price-description')}
              >
                {t('trading.max-gas-price')}
              </SettingsInput>
            </SnipeRow>

            <div className={chain === Blockchain.SOLANA ? 'd-block' : 'd-none'}>
              <div className="tx-13 tx-muted mb-2">
                <img src={solana} alt="Solana" className="wd-15 me-2" />
                {t('profile.solana-priority-mode')}
              </div>

              <TabsCard
                className="wd-100p"
                list={Object.values(SolPriorityMode).map((value: string) => ({
                  value,
                  label: t(`common.${value.toLowerCase()}${value === SolPriorityMode.CUSTOM ? '-short' : ''}`),
                }))}
                active={settings.solPriorityMode || SolPriorityMode.FAST}
                onClick={setSolMode}
              />

              <div className={cn([
                'card',
                'bd-gray-800 rounded-10-px bd-solid bd-1 p-2',
                'flex-row',
                'align-items-center',
                settings.solPriorityMode === SolPriorityMode.CUSTOM ? 'd-flex' : 'd-none',
              ])}>
                <div className="tx-muted me-2">SOL</div>
                <NumericInput
                  placeholder="0.001"
                  className="form-control appearance-none tx-left"
                  value={settings.solPriorityFee?.toString() || null}
                  onChange={(v) => setSolFee(v ? new BigNumber(v).toNumber() : null)}
                  isInvalid={solFeeInvalid}
                />
              </div>
            </div>

            <SnipeRow className="gap-2">
              <SnipeRow
                className={cn([
                  isInputHidden('antiMevEnabled') && 'd-none',
                  'flex-nowrap flex-grow-1 justify-content-between align-items-center pd-12 bd-gray-800 rounded-10-px bd-solid bd-1',
                ])}
              >
                <div>
                  <div className="tx-semibold">{t('trading.mev-guard')}</div>
                  <div className="tx-13 tx-muted">
                    {t('trading.mev-guard-description')}
                  </div>
                </div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.antiMevEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      antiMevEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeRow>
              <SnipeRow
                className={cn([
                  isInputHidden('autoApproveEnabled') && 'd-none',
                  'flex-nowrap flex-grow-1 justify-content-between align-items-center pd-12 bd-gray-800 rounded-10-px bd-solid bd-1',
                ])}
              >
                <div>
                  <div className="tx-semibold">{t('trading.auto-approve')}</div>
                  <div className="tx-13 tx-muted">
                    {t('trading.auto-approve-description')}
                  </div>
                </div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApproveEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      autoApproveEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeRow>
            </SnipeRow>
          </div>

          <div className="mt-auto mb-0">
            <button
              className="btn btn-primary wd-100p mt-3"
              onClick={w(
                handleSubmit,
                AmplitudeEvent.SAVE_SETTINGS_BUTTON_CLICKED,
                { settings },
              )}
            >
              {t('common.save-settings')}
            </button>

            <button
              className="btn btn-semi-10 wd-100p mt-2"
              onClick={() => setShow(false)}
            >
              {t('common.close')}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Vibrutton
        className="btn btn-link tx-primary bg-transparent border-1 d-flex justify-content-center align-items-center cur-pointer tx-13 px-0 py-0 text-decoration-none"
        onClick={w(() => setShow(true), AmplitudeEvent.ORDER_SETTINGS_CLICKED)}
      >
        <img
          src={settingsIcon}
          alt="settings"
          width={16}
          height={16}
          className="me-1"
        />
        {t('trading.slippage')}
        {': '}
        {settings.slippage !== null ? settings.slippage : 5}%
      </Vibrutton>
    </>
  );
};

export default observer(OrderSettingsModal);
