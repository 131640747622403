import React from 'react';
import { TpSlValue } from '@stores/token-trade';
import growth from '@assets/icons/arrow-growth.svg';
import fall from '@assets/icons/arrow-fall.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import { useTranslation } from 'react-i18next';

interface TpSlPreviewProps {
  value: TpSlValue;
  profit: boolean;
}

const TpSlPreview = ({value: val, profit}: TpSlPreviewProps) => {
  const { value, tokenPercents } = val;
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-between tx-12">
      <img src={profit ? growth : fall} alt="Growth / Fall" />
      <FormattedNumber
        value={value}
        postfix="%"
        className={profit ? 'tx-success' : 'tx-danger'}
      />
      <span className="tx-muted mx-1">•</span>
      <FormattedNumber
        value={tokenPercents}
        postfix="%"
        suffix={t('common.sell').toLowerCase() + ' '}
        className="tx-white"
      />
    </div>
  );
};

export default TpSlPreview;
