import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import TPSLInputGroup from './TPSLInputGroup';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import useNotification from '@hooks/useNotification';
import OrderSettingsModal from '../OrderSettingsModal';
import { defaultOrderSettings } from '@stores/settings-store';
import { IOrderSettings } from 'types';
import { TpSlValueProps } from '@pages/LimitOrders/components/OrderValue/TakeProfitValue';
import { OrderTrigger } from 'types/enums';
import { Blockchain } from 'types/enums';

const StopLossValue = observer(
  ({ titleClassName = 'tx-17 tx-semibold' }: TpSlValueProps) => {
    const { t } = useTranslation();
    const notify = useNotification();
    const { tokenTrade, settingsStore, quickTradeStore } = useStores();
    const { stopLossSettings } = settingsStore;

    const {
      chain,
      isStopLossesEnabled: tokenTradeEnabled,
      isTpSlPercentMode,
      isTpSlTrigger,
      stopLosses: tokenTradeSl,
      stopLossesError: ttError,
      emptyInputsLightOn,
    } = tokenTrade;

    const {
      stopLosses: quickTradeSl,
      isStopLossesEnabled: quickTradeEnabled,
      stopLossesError: qtError,
      stopLossSettings: qtSettings,
      isTpSlPercentMode: qtIsTpSlPercentMode,
      isTpSlTrigger: qtIsTpSlTrigger,
      isInit,
    } = quickTradeStore;

    const stopLossesError = useMemo(() => {
      if (isInit) return qtError;
      return ttError;
    }, [isInit, qtError, ttError]);

    const isStopLossesEnabled = useMemo(() => {
      if (isInit) return quickTradeEnabled;
      return tokenTradeEnabled;
    }, [isInit, quickTradeEnabled, tokenTradeEnabled]);

    const stopLosses = useMemo(() => {
      if (isInit) return quickTradeSl;
      return tokenTradeSl;
    }, [isInit, quickTradeSl, tokenTradeSl]);

  const settings = stopLossSettings ?? { [chain!]: defaultOrderSettings(chain as Blockchain) };

  const slSettings = useMemo(() => {
    if (isInit) {
      return qtSettings;
    }
    return stopLossSettings ? stopLossSettings[chain!] : defaultOrderSettings(chain as Blockchain);
  }, [isInit, qtSettings, stopLossSettings, chain]);

    const updateSettings = (val: IOrderSettings) => {
      if (isInit) {
        quickTradeStore.setStopLossSettings(val);
        return;
      }
      settingsStore.setStopLossSettings({ ...settings, [chain!]: val });
    };

    const handleChange = (idx: number) => {
      if (isInit) {
        return quickTradeStore.setStopLossValue(idx);
      }
      return tokenTrade.setStopLossValue(idx);
    };

    const handleAdd = () => {
      if (isInit) {
        return quickTradeStore.addStopLossValue();
      }
      return tokenTrade.addStopLossValue();
    };

    const handleRemove = (idx: number) => {
      if (isInit) {
        return quickTradeStore.removeStopLossValue(idx);
      }
      return tokenTrade.removeStopLossValue(idx);
    };

    useEffect(() => {
      if ((stopLosses?.length ?? 0) >= 3)
        notify(t('order.max-stop-loss-orders-reached'), { type: 'danger' });
    }, [stopLosses?.length]);

    return (
      <>
        {isStopLossesEnabled && (
          <div className={cn(['card', 'rounded-3-px', 'mg-y-2', 'gap-2'])}>
            <div className="d-flex justify-content-between mb-1">
              <span className={titleClassName}>{t('order.stop-loss')}</span>
              <OrderSettingsModal
                data={slSettings}
                updateSettings={updateSettings}
              />
            </div>

            <div className="d-flex gap-2">
              <span className="d-flex wd-50p tx-muted tx-13">
                {(isInit ? qtIsTpSlTrigger : isTpSlTrigger) ===
                OrderTrigger.MCAP
                  ? `${t('common.mcap')}`
                  : `${t('order.price-drop')} ${(isInit ? qtIsTpSlPercentMode : isTpSlPercentMode) ? '%' : '$'}`}
              </span>
              <span className="d-flex wd-50p tx-muted tx-13">
                {t('order.sell-percents-price-growth')}
              </span>
            </div>
            {stopLosses?.map((val, idx) => {
              return (
                <TPSLInputGroup
                  disableAdding={stopLosses.length >= 3}
                  lightEmptyInputs={emptyInputsLightOn}
                  hasSumError={!!stopLossesError[0]}
                  idx={idx}
                  key={`stop-loss-${idx}`}
                  values={val}
                  unit={
                    (isInit ? qtIsTpSlPercentMode : isTpSlPercentMode)
                      ? '%'
                      : '$'
                  }
                  onChange={handleChange(idx)}
                  onAdd={handleAdd}
                  onRemove={() => handleRemove(idx)}
                />
              );
            })}
            {stopLossesError && (
              <ul className="mb-0 mt-1 ps-4">
                {stopLossesError.map(
                  (error, idx) =>
                    error && (
                      <li key={`${error}-${idx}`} className="tx-13 tx-danger">
                        {t(error)}
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        )}
      </>
    );
  },
);

export default StopLossValue;
