import { action, makeAutoObservable, computed } from 'mobx';
import api from '@helpers/api';
import { AxiosError } from 'axios';
import { ChainId } from '../constants';
import {
  ApiAllowanceParams,
  ApiBlockchainTransaction,
  ApiCalculateGas,
  ApiGasPrice,
  ApiGetAllowanceParams,
  ApiQueue,
  ApiQueueParams,
  ApiSimulateParams,
  ApiSwapParams,
  ApiTransactionHash,
  BlockchainTransaction,
  PositionModel,
  TokenDetails,
  TransactionDetails,
} from '../types';
import { AccountStore } from '@stores/account-store';
import { GasResponse } from '../types/common.types';
import { TransferEstimateGasResponse } from '../types/trading/transfer-estimate-gas.response';
import { SendTokensRequest } from '../types/trading/send-tokens.request';

export class BlockchainStore {
  private accountStore: AccountStore;

  constructor(_accountStore: AccountStore) {
    this.accountStore = _accountStore;
    makeAutoObservable(this);
  }

  @action.bound
  async getBlockchainTransactions(walletAddress: string) {
    return api<BlockchainTransaction[]>({
      method: 'get',
      path: `/blockchain/transactions/${walletAddress}`,
    })
      .then((response) => {
        return response || [];
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getBlockchainGas(network: string = ChainId.ETHER) {
    return api<GasResponse>({
      method: 'get',
      path: `/gas`,
      data: {
        blockchain: network.toUpperCase(),
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async calculateGas(data: ApiCalculateGas) {
    return api<TransferEstimateGasResponse>({
      method: 'get',
      path: `/gas/estimate/transfer`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async calculateAllowanceGas(data: ApiSimulateParams) {
    return api<ApiGasPrice>({
      method: 'post',
      path: `/token/allowance/simulate`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getQueue(data: ApiQueueParams) {
    return api<ApiQueue>({
      method: 'get',
      path: `/token/quote`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getAllowance(data: ApiGetAllowanceParams) {
    return api<{ allowance: string }>({
      method: 'get',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async setAllowance(data: ApiAllowanceParams) {
    return api<{ transactionHash: string }>({
      method: 'post',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async sendToken(data: SendTokensRequest) {
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/send`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async swapTokens(data: ApiSwapParams) {
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/swap`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getBlockchainTransaction(transactionHash: string, network: ChainId) {
    return api<TransactionDetails>({
      method: 'get',
      path: `/blockchain/transaction/${network}/${transactionHash}`,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getApiTransaction(transactionHash: string) {
    return api<ApiBlockchainTransaction>({
      method: 'get',
      path: `/blockchain/transaction/${transactionHash}`,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getTokenDetails(address: string) {
    try {
      return await api<TokenDetails>({
        method: 'get',
        path: `/token/details/${address}`,
      });
    } catch (error) {
      return this.errorHandler(error);
    }
  }

  @action.bound
  async getPosition(positionId: string) {
    return api<PositionModel>({
      method: 'get',
      path: `/snipe-engine/position/${positionId}`,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  errorHandler(error: AxiosError | any) {
    return this.accountStore.errorHandler(error);
  }
}
