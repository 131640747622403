import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  when,
  computed,
} from 'mobx';
import { TokenStore } from '@stores/token-store';
import {
  AlphaOrderLastLevelReachStrategy,
  OrderTrigger,
} from 'types/order/order.enum';
import BigNumber from 'bignumber.js';
import {
  isUsdInputValid,
  isPercentInputValid,
  changeInPercents,
  percentDiff,
  adjustArray,
  replaceInvalidValues,
  generateTriggerValues,
  limitSign,
} from '@helpers/calculations';
import { isNaN } from '@helpers/bignumber';
import {
  AlphaOrderRequest as TAlphaOrderRequest,
  TokenTrade,
} from '@stores/token-trade';

type PriceSource = OrderTrigger.PRICE_IN_USD | OrderTrigger.MCAP;
type IAlphaLevels = [null | string | number, null | string | number][];

export type TradeParams = Record<'chain' | 'address' | 'id', string>;

export class AlphaOrderModel {
  private _tokenStore: TokenStore;
  private _tokenTrade: TokenTrade;

  @observable
  private _priceSource: OrderTrigger = OrderTrigger.PRICE_IN_USD;

  @observable
  private _upperLevel: null | string | number = null;

  @observable
  private _lowerLevel: null | string | number = null;

  @observable
  private _upperLevelPercents: null | string | number = null;

  @observable
  private _lowerLevelPercents: null | string | number = null;

  @observable
  private _isAutoLevel: boolean = true;

  @observable
  private _autoLevelStep: null | string | number = null;

  @observable
  private _levelsQty: null | number = null;

  @observable
  private _alphaLevels: IAlphaLevels = [];

  @observable
  private _lastLevelReachStrategy: AlphaOrderLastLevelReachStrategy =
    AlphaOrderLastLevelReachStrategy.EXECUTE_ORDER;

  @observable
  private _trailingStep: null | string | number = null;

  constructor(tokenStore: TokenStore, tokenTrade: TokenTrade) {
    this._tokenStore = tokenStore;
    this._tokenTrade = tokenTrade;

    makeAutoObservable(this);

    when(
      () => !!this.order?.id,
      () => {
        if (this.order) this.init();
      },
    );

    reaction(
      () => this.priceSource,
      (priceSource) => {
        tokenTrade.setTriggerType(priceSource);
      },
    );

    reaction(
      () => !!tokenTrade.orderInit,
      (init) => {
        if (!init) {
          this.reset();
        }
      },
    );

    reaction(
      () => {
        return JSON.stringify({
          hasErrors: this.hasErrors,
          alphaOrder: this.alphaOrder,
        });
      },
      () => {
        if (tokenTrade.orderInit && this.hasErrors) {
          tokenTrade.setHasAlphaOrderError(this.hasErrors);
        } else {
          tokenTrade.setHasAlphaOrderError(this.hasErrors);
          tokenTrade.setAlphaOrder(this.alphaOrder);
        }
      },
    );
  }

  @computed
  get token() {
    return this._tokenStore.tokenDetails;
  }

  @computed
  get isBuy() {
    return this._tokenTrade.isBuy;
  }

  @computed
  get orderType() {
    return this._tokenTrade.orderType;
  }

  @computed
  get order() {
    return this._tokenTrade.order;
  }

  @computed
  get priceSource() {
    return this._priceSource;
  }

  @computed
  get upperLevel() {
    return this._upperLevel;
  }

  @computed
  get lowerLevel() {
    return this._lowerLevel;
  }

  @computed
  get upperLevelPercents() {
    return limitSign(this._upperLevelPercents, this.isBuy);
  }

  @computed
  get lowerLevelPercents() {
    return limitSign(this._lowerLevelPercents, this.isBuy);
  }

  @computed
  get levelsQty() {
    return this._levelsQty;
  }

  @action.bound
  init() {
    const { trigger } = this.order!;
    const { alphaLevels, lastLevelReachStrategy, trailing, autoLevelStep } =
      this.order!.metadata.alphaOrder!;

    this.setLastLevelReachStrategy(lastLevelReachStrategy);
    this.setPriceSource(trigger);

    if (autoLevelStep) {
      this.isBuy
        ? this.setUpperLevel(alphaLevels[0].triggerValue)
        : this.setLowerLevel(alphaLevels[0].triggerValue);
      this.setAutoLevel(true);
      this.setAutoLevelStep(autoLevelStep);
    } else {
      if (this.isBuy) {
        this.setUpperLevel(alphaLevels[0].triggerValue);
        this.setLowerLevel(alphaLevels[alphaLevels.length - 1].triggerValue);
      } else {
        this.setUpperLevel(alphaLevels[alphaLevels.length - 1].triggerValue);
        this.setLowerLevel(alphaLevels[0].triggerValue);
      }

      this.setAutoLevel(false);
      this.setLevelsQty(alphaLevels.length);
      trailing?.percent &&
        this.setTrailingStep(
          this.isBuy ? -Number(trailing?.percent) : Number(trailing?.percent),
        );

      const lvls: IAlphaLevels = alphaLevels.map(({ triggerValue }) => [
        triggerValue,
        changeInPercents([triggerValue, this.currentValue])
          .decimalPlaces(1)
          .valueOf(),
      ]);

      this.setAlphaLevels(this.isBuy ? lvls : [...lvls].reverse());
    }

    this._tokenTrade.setAlphaOrder(this.alphaOrder);
    this._tokenTrade.setHasAlphaOrderError(this.hasErrors);
  }

  @computed
  get isLevelsShow() {
    return !!(
      ((this.upperLevel && this.lowerLevel) || this.alphaLevels.length) &&
      !this.isAutoLevel
    );
  }

  @computed
  get label() {
    return {
      [OrderTrigger.PRICE_IN_USD]: 'order-trigger.current-price',
      [OrderTrigger.MCAP]: 'order-trigger.current-mcap',
    }[this._priceSource as PriceSource];
  }

  @computed
  get alphaOrder(): TAlphaOrderRequest {
    const customLevels = this.alphaLevels.map(([usd]) => ({
      triggerValue: String(usd!),
    }));

    const alphaLevels = this.isAutoLevel
      ? generateTriggerValues(
          this.currentValue,
          this.autoLevelStep,
          this.isBuy ? this.upperLevelPercents : this.lowerLevelPercents,
        )
      : this.isBuy
        ? customLevels
        : [...customLevels].reverse();

    return {
      alphaLevels,
      autoLevelStep:
        this.isAutoLevel && this.autoLevelStep
          ? Number(this.autoLevelStep)
          : undefined,
      lastLevelReachStrategy: this.lastLevelReachStrategy,
      trailing:
        this.lastLevelReachStrategy ===
        AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING
          ? { percent: Math.abs(Number(this.trailingStep!)) }
          : undefined,
    };
  }

  @computed
  get currentValue() {
    const { price, mCap } = {
      price: this.token?.usdPrice ?? 0,
      mCap: this.token?.mcap ?? 0,
    };

    return {
      [OrderTrigger.PRICE_IN_USD]: price,
      [OrderTrigger.MCAP]: mCap,
    }[this._priceSource as PriceSource];
  }

  @computed
  get alphaLevels() {
    return [
      [this._upperLevel, this.upperLevelPercents],
      ...this._alphaLevels
        .map(([v, p]) => [v, limitSign(p, this.isBuy)])
        .map((v) => (v.some((i) => i === 'NaN') ? [null, null] : v))
        .slice(1, -1),
      [this.lowerLevel, this.lowerLevelPercents],
    ];
  }

  @computed
  get isAutoLevel() {
    return this._isAutoLevel;
  }

  @computed
  get autoLevelStep() {
    return limitSign(this._autoLevelStep, this.isBuy);
  }

  @computed
  get lastLevelReachStrategy() {
    return this._lastLevelReachStrategy;
  }

  @computed
  get trailingStep() {
    return this.lastLevelReachStrategy ===
      AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING
      ? limitSign(this._trailingStep, this.isBuy)
      : undefined;
  }

  @computed
  get errors() {
    const bnLvls = [...this.alphaLevels].map((i) =>
      new BigNumber(i[0]!).toNumber(),
    );

    const hasThresholdSignError = (
      percents: string | number | null,
      isUpper: boolean,
    ) => {
      if (isUpper !== this.isBuy && this.isAutoLevel) return false;
      return (
        !!percents &&
        !isNaN(percents) &&
        !(this.isBuy
          ? new BigNumber(percents).isLessThanOrEqualTo(0)
          : new BigNumber(percents).isGreaterThanOrEqualTo(0))
      );
    };

    const upperThresholdSignError = hasThresholdSignError(
      this.upperLevelPercents,
      true,
    );

    const lowerThresholdSignError = hasThresholdSignError(
      this.lowerLevelPercents,
      false,
    );

    const thresholdRequiredError =
      !this.isAutoLevel &&
      [this.upperLevelPercents, this.lowerLevelPercents].includes(
        limitSign(null, this.isBuy),
      );

    const upperLevelError =
      !(this.isAutoLevel && !this.isBuy) && this.upperLevel === null;

    const lowerLevelError =
      !(this.isAutoLevel && this.isBuy) && this.lowerLevel === null;

    const levelsQtyError = !(this.isAutoLevel || this.levelsQty);

    const levelOrderError = bnLvls.findIndex((value, index, arr) => {
      if (this.isAutoLevel || value === null) return false;

      const previousValue = arr
        .slice(0, index)
        .reverse()
        .find((v) => v !== null);

      return previousValue !== undefined && value > previousValue;
    });

    const levelsEmptyError = this.isAutoLevel
      ? []
      : this.alphaLevels
          .map(([value], idx) => (value === null || isNaN(value) ? idx : -1))
          .filter((index) => index !== -1);

    const excessLimitError =
      !this.isAutoLevel &&
      ![this.lowerLevel, this.upperLevel].includes(null) &&
      new BigNumber(this.upperLevel ?? 0).isLessThanOrEqualTo(
        this.lowerLevel ?? 0,
      );

    const levelsOutOfLimits = this.isAutoLevel
      ? []
      : bnLvls
          .map((value, index, arr) =>
            value > arr[0] || value < arr[arr.length - 1] ? index : null,
          )
          .filter((index) => index !== null);

    const autoLevelError =
      this.isAutoLevel &&
      (new BigNumber(this.autoLevelStep).toNumber() == 0 ||
        isNaN(this.autoLevelStep) ||
        (this.isBuy
          ? new BigNumber(this.autoLevelStep ?? 0).isGreaterThan(0)
          : new BigNumber(this.autoLevelStep ?? 0).isLessThan(0)));

    const trailingStepError =
      this.lastLevelReachStrategy ===
        AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING &&
      !Number(this.trailingStep);

    return {
      upperLevelError,
      lowerLevelError,
      levelsQtyError,
      levelOrderError,
      excessLimitError,
      levelsOutOfLimits,
      levelsEmptyError,
      autoLevelError,
      trailingStepError,
      thresholdRequiredError,
      upperThresholdSignError,
      lowerThresholdSignError,
    };
  }

  @computed
  get hasErrors() {
    return !Object.values(this.errors)
      .flat()
      .every((e) => [-1, false, null].includes(e));
  }

  @action.bound
  setPriceSource(src: OrderTrigger) {
    this._priceSource = src;

    this.setUpperLevel(null);
    this.setLowerLevel(null);
    this.setLevelsQty(null);
  }

  @action.bound
  adjustLevels(
    usd: [number | undefined, number | undefined],
    percent: [number | undefined, number | undefined],
  ): IAlphaLevels {
    const usdVals = adjustArray(
      this._alphaLevels.map(([usd, prc]) =>
        usd && new BigNumber(usd).isNegative() ? null : usd,
      ),
      usd,
    );
    const prcVals = adjustArray(
      this._alphaLevels.map(([usd, prc]) => prc),
      percent,
      1,
    );

    return usdVals.map((val, index) =>
      !['NaN'].includes(String(prcVals[index]))
        ? [val, prcVals[index]]
        : [null, null],
    );
  }

  @action.bound
  setUpperLevel(value: number | string | null, noReaction?: boolean) {
    if (isUsdInputValid(value)) {
      this._upperLevel = value;
    }

    if (!noReaction) {
      const change = changeInPercents([
        this._upperLevel ?? 0,
        this.currentValue,
      ]);

      this.setUpperLevelPercents(
        limitSign(
          [null, '', NaN].includes(value)
            ? null
            : change.decimalPlaces(1).valueOf(),
          this.lowerLevel === null || change.isZero()
            ? this.isBuy
            : change.isNegative(),
        ),
        true,
      );
    }
  }

  @action.bound
  setLowerLevel(value: number | string | null, noReaction?: boolean) {
    if (isUsdInputValid(value)) {
      this._lowerLevel = value;
    }

    if (!noReaction) {
      const change = changeInPercents([
        this.lowerLevel ?? 0,
        this.currentValue,
      ]);

      this.setLowerLevelPercents(
        limitSign(
          [null, '', NaN].includes(value)
            ? null
            : change.decimalPlaces(1).valueOf(),
          this.lowerLevel === null || change.isZero()
            ? this.isBuy
            : change.isNegative(),
        ),
        true,
      );
    }
  }

  @action.bound
  setUpperLevelPercents(value: number | string | null, noReaction?: boolean) {
    if (!/^[+-]/.test(String(value))) return;

    if (isPercentInputValid(value)) {
      this._upperLevelPercents = value;
    }

    const usdValue = percentDiff(
      this.currentValue,
      this.upperLevelPercents ?? 0,
    ).valueOf();

    if (!noReaction)
      this.setUpperLevel(
        isPercentInputValid(value) && !/^[+-]$/.test(String(value))
          ? usdValue
          : null,
        true,
      );

    if (this._alphaLevels.length && !this.errors.excessLimitError) {
      const lvls = this.adjustLevels(
        [Number(usdValue), undefined],
        [Number(value), undefined],
      );

      this.setAlphaLevels(lvls);
    }
  }

  @action.bound
  setLowerLevelPercents(value: number | string | null, noReaction?: boolean) {
    if (!/^[+-]/.test(String(value))) return;

    if (isPercentInputValid(value)) {
      this._lowerLevelPercents = value;
    }
    const usdValue = percentDiff(
      this.currentValue,
      this.lowerLevelPercents ?? 0,
    ).valueOf();

    if (!noReaction)
      this.setLowerLevel(
        isPercentInputValid(value) && !/^[+-]$/.test(String(value))
          ? usdValue
          : null,
        true,
      );

    if (this.alphaLevels.length && !this.errors.excessLimitError) {
      const lvls = this.adjustLevels(
        [undefined, Number(usdValue)],
        [undefined, Number(value)],
      );

      this.setAlphaLevels(lvls);
    }
  }

  @action.bound
  setAutoLevel(value: boolean) {
    this._isAutoLevel = value;
  }

  @action.bound
  setLastLevelReachStrategy(value: AlphaOrderLastLevelReachStrategy) {
    this._lastLevelReachStrategy = value;
  }

  @action.bound
  setAutoLevelStep(value: string | number | null) {
    this._autoLevelStep = value;
  }

  @action.bound
  setTrailingStep(value: string | number | null) {
    this._trailingStep = value;
  }

  @action.bound
  setLevelsQty(value: number | null) {
    this._levelsQty = value;

    this.setAlphaLevels(
      Array.from(Array(this.levelsQty).keys()).map((idx) => {
        const cv = new BigNumber(this.currentValue);

        const step = new BigNumber(this.upperLevelPercents ?? 0)
          .minus(this.lowerLevelPercents!)
          .div(this.levelsQty! - 1)
          .toNumber();
        const stepChange = new BigNumber(step * idx)
          .decimalPlaces(1, 1)
          .toNumber();
        const levelChange = new BigNumber(this.upperLevelPercents!)
          .minus(stepChange)
          .decimalPlaces(1, 1)
          .toNumber();
        const usdLevelPrice = cv.plus(cv.multipliedBy(levelChange / 100));

        return [usdLevelPrice.valueOf(), levelChange];
      }),
    );
  }

  @action.bound
  setAlphaLevels(levels: IAlphaLevels) {
    if (
      ![this.upperLevelPercents, this.lowerLevelPercents].some((v) =>
        isNaN(Number(v)),
      )
    )
      this._alphaLevels = levels;
  }

  @action.bound
  editAlphaLevel(
    idx: number,
    value: [
      number | string | null | undefined,
      string | number | null | undefined,
    ],
  ) {
    const [newPrice, newChange] = value;
    const lastIdx = this.alphaLevels.length - 1;

    if (newPrice !== undefined) {
      if (!isUsdInputValid(newPrice)) return this.alphaLevels[idx];

      const newPriceInPercents = new BigNumber(newPrice ?? 0)
        .minus(this.currentValue)
        .div(this.currentValue)
        .multipliedBy(100)
        .decimalPlaces(1);

      if ([0, lastIdx].includes(idx)) {
        return idx
          ? this.setLowerLevel(newPrice)
          : this.setUpperLevel(newPrice);
      } else
        return (this._alphaLevels[idx] = [
          newPrice,
          limitSign(
            newPriceInPercents.valueOf(),
            newPriceInPercents.isNegative(),
          ),
        ]);
    }

    if (newChange !== undefined) {
      if (!isPercentInputValid(newChange)) return this.alphaLevels[idx];

      if (/^[+-]$|^[^+-]*$/.test(String(newChange))) {
        if ([0, lastIdx].includes(idx)) {
          return idx
            ? this.setLowerLevelPercents(limitSign(null, this.isBuy))
            : this.setUpperLevelPercents(limitSign(null, this.isBuy));
        } else
          return (this._alphaLevels[idx] = [null, limitSign(null, this.isBuy)]);
      }

      const changeInUsd = isNaN(newChange)
        ? null
        : new BigNumber(newChange ?? 0)
            .div(100)
            .multipliedBy(this.currentValue)
            .plus(this.currentValue)
            .toString();

      if ([0, lastIdx].includes(idx)) {
        return idx
          ? this.setLowerLevelPercents(newChange)
          : this.setUpperLevelPercents(newChange);
      } else return (this._alphaLevels[idx] = [changeInUsd, newChange]);
    }
  }

  @action.bound
  autoFix() {
    const usds = this.alphaLevels.map(([usd, per]) =>
      new BigNumber(usd!).toNumber(),
    );
    const prcs = this.alphaLevels.map(([usd, per]) =>
      new BigNumber(per!).toNumber(),
    );

    const invalidIndices = Array.from(
      new Set([this.errors.levelOrderError, ...this.errors.levelsOutOfLimits]),
    ).sort();

    const fixedUsds = replaceInvalidValues(usds, invalidIndices);
    const fixedPrcs = replaceInvalidValues(prcs, invalidIndices, 1);

    const fixedLevels: IAlphaLevels = fixedPrcs.map((value, index) => [
      fixedUsds[index],
      value,
    ]);

    this.setAlphaLevels(fixedLevels);
  }

  @action.bound
  reset() {
    this._priceSource = OrderTrigger.PRICE_IN_USD;
    this._upperLevel = null;
    this._lowerLevel = null;
    this._upperLevelPercents = null;
    this._lowerLevelPercents = null;
    this._isAutoLevel = true;
    this._autoLevelStep = null;
    this._levelsQty = null;
    this._alphaLevels = [];
    this._lastLevelReachStrategy =
      AlphaOrderLastLevelReachStrategy.EXECUTE_ORDER;
    this._trailingStep = null;
  }
}
