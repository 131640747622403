import React, { useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import gasIcon from '@assets/icons/gas-white.svg';
import { useStores } from '@hooks/useStores';
import { ChainId } from '../../../constants';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Placeholder from '@components/common/Placeholder';

const LimitOrderWalletBalance = () => {
  const { tokenStore, ordersStore, walletStore } = useStores();
  const { isEvm, balance: ordersBalance } = ordersStore;
  const { balance: walletBalance } = walletStore;
  const { chain, gas } = tokenStore;

  const balance = useMemo(
    () => walletBalance || ordersBalance,
    [ordersBalance, walletBalance],
  );

  return (
    <div className="d-flex align-items-center justify-content-between tx-13 flex-1">
      <div
        className={`${chain === ChainId.ALL || !isEvm ? 'd-none' : 'd-flex'} justify-content-end align-items-center`}
      >
        <img src={gasIcon} alt="gas" width={16} height={16} className="me-1" />
        <FormattedNumber value={gas?.gwei} postfix="GWEI" />
      </div>

      <div className="d-flex align-items-center justify-content-start tx-muted ms-auto">
        <div>{t('common.balance')}</div>
        {balance ? (
          <>
            <FormattedNumber
              value={balance?.balance}
              decimals={4}
              subZeros
              floor
              postfix={balance?.token}
              className="tx-white ms-1"
            />
            <FormattedNumber
              value={balance?.balanceInUsd}
              decimals={2}
              className="tx-white ms-1"
              suffix="($"
              postfix=")"
              noSpace
              floor
              subZeros
            />
          </>
        ) : (
          <Placeholder width={142} className="ms-1" />
        )}
      </div>
    </div>
  );
};

export default observer(LimitOrderWalletBalance);
