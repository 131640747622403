import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeIcon from '@components/common/BadgeIcon';
import { PageRoutes } from '../../constants';
import logo from '@assets/images/logo.svg';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';

const WalletReady = () => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
      <BadgeIcon>
        <img src={logo} alt="Alpha Wallet" className="wd-30" />
      </BadgeIcon>
      <h1 className="tx-28 mt-4">Wallet Ready</h1>

      <div className="tx-muted my-3">
        Now you are a full-fledged user of the Alpha wallet. Successful web3
        journey!
      </div>

      <button
        className="btn btn-primary-10 wd-200"
        onClick={() => {
          authStore.login();
          navigate(PageRoutes.WALLET);
        }}
      >
        Go Wallet
      </button>
    </div>
  );
};

export default observer(WalletReady);
