import { action, makeAutoObservable, computed } from 'mobx';
import api, { ApiServer, ResponseData } from '@helpers/api';
import { AxiosError } from 'axios';
import { ChainId } from '../constants';
import { HotToken } from '../types';
import { AccountStore } from '@stores/account-store';
import { TopTokensFilter } from '../types/research/research.request';
import { ResearchPairResponse } from '../types/research/research-pair.response';
import { InviteLink } from '../types/research/invite-links.response';

export class ResearchStore {

  private accountStore: AccountStore;

  constructor(_accountStore: AccountStore) {
    this.accountStore = _accountStore;
    makeAutoObservable(this);
  }

  @action.bound
  async getHotTokensDeprecated() {
    return api<ResponseData<HotToken[]>>(
      {
        method: 'get',
        path: `/pairs/top`,
      },
      ApiServer.DEX,
    )
      .then((response) => {
        return response.data || [];
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getHotTokens(blockchain: ChainId = ChainId.ETHER) {
    // if (blockchain === ChainId.ETHER) {
    //   return this.getHotTokensDeprecated();
    // }

    return api<ResponseData<HotToken[]>>({
      method: 'get',
      path: `/research/tokens`,
      data: {
        blockchain,
        filter: TopTokensFilter.TRENDING_POOLS,
      },
    })
      .then((response) => {
        return response || [];
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getPairDetails(address: string) {
    return api<ResearchPairResponse[]>({
      method: 'get',
      path: `/pair/search`,
      data: {
        q: address,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getResearchInviteLinks() {
    return api<InviteLink[]>({
      method: 'get',
      path: `/research/invite-links`,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  errorHandler(error: AxiosError | any) {
    return this.accountStore.errorHandler(error);
  }
}
