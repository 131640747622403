import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormattedNumber from '@components/common/FormattedNumber';
import TokenLogo from '@components/common/TokenLogo';
import { chainLogo, chainName, chainTxExplorer, dexLogo, dexName, dexVersion } from '@helpers/chains';
import gas from '@assets/icons/gas-white.svg';
import copy from '@assets/icons/copy.svg';
import arrow from '@assets/icons/arrow-right.svg';
import { format } from 'date-fns';
import BigNumber from 'bignumber.js';
import { ReactComponent as Clock } from './clock.svg';
import CopyButton from '@components/common/CopyButton';
import { getEllipsisTxt } from '@helpers/formatters';
import { OrderResponse } from '../../../types/order/order.response';
import useOrderData from '@hooks/useOrderData';
import IconWithLoading from '@components/common/IconWithLoading';
import { OrderTrigger } from '../../../types/order/order.enum';
import usePopup from '@hooks/usePopup';
import { useStores } from '@hooks/useStores';
import useNotification from '@hooks/useNotification';
import { Blockchain } from '../../../types/enums';
import { LimitData } from '../../../types/common.types';
import LabelValueListItem from '@components/common/LabelValueListItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlphaLink from '@components/common/AlphaLink';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { alphaOrderStrategies } from '../../../constants';
import { SolPriorityMode } from '../../../types/user/settings.types';

interface LimitOrderDetailsProps {
  onClose: () => void;
  // item: ApiWalletTransactionsData;
  item: OrderResponse | null;
  onCancel: () => void;
  limitData: LimitData;
  nativePrice: number;
}

const LimitOrderDetails = ({
                             onClose,
                             item,
                             onCancel,
                             limitData,
                             nativePrice,
                           }: LimitOrderDetailsProps) => {
  const { t } = useTranslation();
  const { ordersStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const showPopup = usePopup();
  const notify = useNotification();
  const navigate = useNavigate();
  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  const {
    isMarket,
    isBuy,
    isAlpha,
    alphaData,
    isError,
    isExecuting,
    isOpen,
    isCompleted,
    errorLongText,
    errorDescription,
    typeName,
    statusText,
    statusColor,
    amountOut,
    amountIn,
    tokenName,
    tokenSymbol,
    baseToken,
    valueIn,
    usdValue,
    pairAddress,
    usdPrice,
    createdAt,
    updatedAt,
    triggerName,
    trigger,
    targetTriggerValue,
    nextTriggerValue,
    nativeTokenUsdPrice,
    tokenNativePrice,
    fee,
    feeInUsd,
    blockchain,
    dex,
    walletAddress,
    tokenAddress,
    id,
    pairType,
    currentData,
    transactions,
    approveTransaction,
    buyTransaction,
    sellTransaction,
    limitPrice,
    settings,
    tpSettings,
    slSettings,
  } = useOrderData(item, limitData, nativePrice);

  const { pathname } = useLocation();

  const isTon = blockchain === Blockchain.TON;

  const contactSupportLink = useMemo(() => {
    let link = `https://t.me/alpha_dex_support`;
    link += `?text=${t('order.support-message').replace('{{orderId}}', id)}`;
    link += `${isError ? `. ${t('common.error')}: ${errorLongText}` : ''}`;
    link += `. Order info: token ${tokenSymbol} / ${tokenName}, chain: ${blockchain}, order type: ${typeName}, status: ${statusText}, slippage: ${settings.slippage || 5}%, extra gas price: ${settings.extraGasPricePercent || settings.extraGasPricePercent === 0 ? settings.extraGasPricePercent : 10}%, MEV-guard: ${settings.antiMevEnabled ? 'enabled' : 'disabled'}, auto-approve: ${settings.autoApproveEnabled ? 'enabled' : 'disabled'}.`;
    return link;
  }, [id, isError, errorLongText]);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  useEffect(() => {
    if (item) {
      setShowModal(true);
    }
  }, [item]);

  const handleCancel = () => {
    if (item) {
      setIsCanceling(true);
      ordersStore
        .cancelOrder(item.id)
        .then((response) => {
          setIsCanceling(false);
          if (response) {
            notify(t('order.order-canceled'));
            handleClose();
            onCancel();
          } else {
            notify(t('order.order-cancel-failed'), { type: 'danger' });
          }
        })
        .catch((e) => {
          setIsCanceling(false);
          notify(t('order.order-cancel-failed'), { type: 'danger' });
          console.error(e);
        });
    }
  };

  const isSettingHidden = (key: string) => {
    const disabledChains = {
      extraGasPricePercent: [Blockchain.TON, Blockchain.TRON, Blockchain.SOLANA],
      antiMevEnabled: [
        Blockchain.BSC,
        Blockchain.BASE,
        Blockchain.TON,
        Blockchain.TRON,
      ],
      maxGasPrice: [
        Blockchain.BSC,
        Blockchain.BASE,
        Blockchain.SOLANA,
        Blockchain.TON,
        Blockchain.TRON,
        Blockchain.SUI,
      ],
      autoApproveEnabled: [Blockchain.SOLANA, Blockchain.TON, Blockchain.TRON],
    }[key];

    return disabledChains?.includes(blockchain);
  };

  const transactionsExecuted = transactions.filter((tx) => !!tx.hash);

  if (!item) {
    return null;
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onExited={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{typeName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center tx-center my-5">
            <TokenLogo
              address={tokenAddress}
              name={tokenSymbol}
              chain={blockchain}
              size={45}
            />
            {!isCompleted && (
              <div
                className={`tx-28 tx-semibold tx-${statusColor} d-flex align-items-center`}
              >
                <span className={`${isError ? '' : 'me-2'}`}>{statusText}</span>
                {!isError && <Clock />}
              </div>
            )}
            {isError && <div className="tx-danger tx-16">{errorLongText}</div>}
            {isError && !!errorDescription && (
              <div className="card wd-100p mt-3 bg-semi-danger-10 tx-danger tx-16">
                {errorDescription}
              </div>
            )}
            {isCompleted && (
              <FormattedNumber
                value={amountIn.toNumber()}
                postfix={tokenSymbol}
                decimals={
                  new BigNumber(amountIn.toNumber()).isLessThan(1)
                    ? 6
                    : undefined
                }
                className={`d-block tx-28 tx-semibold ${isBuy ? 'tx-success' : 'tx-white'}`}
                subZeros
                withSign
              />
            )}
            {!isError && !isMarket && !isCompleted && (
              <div className="tx-muted">
                <FormattedNumber
                  value={valueIn}
                  postfix={isBuy ? baseToken : tokenSymbol}
                  decimals={
                    isBuy
                      ? 4
                      : new BigNumber(valueIn).isLessThan(0.000001)
                        ? 10
                        : 6
                  }
                  useFormat={new BigNumber(valueIn).isGreaterThan(99000)}
                  suffix="-"
                  subZeros
                />
                {!!limitPrice && (
                  <FormattedNumber
                    value={limitPrice}
                    suffix="($"
                    postfix=")"
                    subZeros
                    useFormat={new BigNumber(limitPrice).isGreaterThan(99000)}
                    className="tx-muted ms-1"
                  />
                )}
              </div>
            )}
            {!isError && (isMarket || isCompleted) && (
              <div className={`${!isBuy ? 'tx-success' : 'tx-muted'}`}>
                <FormattedNumber
                  value={amountOut.toNumber()}
                  postfix={isExecuting ? tokenSymbol : baseToken}
                  decimals={
                    !isExecuting ? 4 : amountOut.isLessThan(0.000001) ? 10 : 6
                  }
                  withSign
                  subZeros
                  useFormat={amountOut.isGreaterThan(99000)}
                />
                {!!usdValue && (
                  <FormattedNumber
                    value={usdValue}
                    suffix="($"
                    postfix=")"
                    decimals={4}
                    className={`ms-2`}
                    subZeros
                  />
                )}
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            {!isMarket && isOpen && (
              <div className="mb-3 d-flex gap-2">
                <button
                  className="btn btn-semi-10 wd-100p border-2"
                  onClick={() => {
                    navigate(
                      `/limit-orders/${item?.blockchain}/${item?.pairAddress}/update/${item?.id}`,
                      { state: { back: pathname } }
                    );
                  }}
                >
                  <IconWithLoading
                    icon={faPencil}
                    isLoading={isCanceling}
                    className="me-2"
                  />
                  {t('common.edit')}
                </button>
                <button
                  className="btn btn-danger bg-danger tx-white wd-100p border-2"
                  onClick={() => {
                    showPopup({
                      title: t('order.cancel-order'),
                      message: t('order.cancel-order-confirm'),
                      buttons: [
                        {
                          text: t('common.cancel'),
                          type: 'danger',
                          id: 'cancel-order',
                        },
                        {
                          text: t('common.close'),
                          id: 'cancel',
                        },
                      ],
                    }).then((result) => {
                      if (result === 'cancel-order') {
                        handleCancel();
                      }
                    });
                  }}
                >
                  <IconWithLoading
                    icon={faTimes}
                    isLoading={isCanceling}
                    className="me-2"
                  />
                  {t('common.cancel')}
                </button>
              </div>
            )}

            {isCompleted && (
              <div className="card bg-semi-transparent-10 mb-3 order-last">
                <div className="tx-13 tx-muted mb-1">
                  {isBuy ? t('order.buy-price') : t('order.sell-price')}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                  <span>1 {tokenSymbol} =</span>
                  <FormattedNumber
                    value={usdPrice.toNumber()}
                    suffix="$"
                    decimals={25}
                    className="mx-1"
                    subZeros
                  />
                  <FormattedNumber
                    value={tokenNativePrice.toNumber()}
                    suffix="("
                    postfix={` ${baseToken})`}
                    decimals={4}
                    className="tx-muted"
                    subZeros
                  />
                </div>
              </div>
            )}

            {isExecuting && (
              <div className="card bg-semi-transparent-10 mb-3 order-last">
                <div className="tx-13 tx-muted mb-1">
                  {isBuy ? t('order.buy-price') : t('order.sell-price')}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between flex-wrap">
                  {t('order.process')}
                  <IconWithLoading isLoading />
                </div>
              </div>
            )}

            {!isMarket && (
              <div className="card bg-semi-transparent-10 mb-3 order-last">
                <div className="tx-13 tx-muted mb-1">
                  {t('order.trigger-type')}
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start flex-wrap pb-2 mb-2 border-bottom border-semi-transparent">
                  {triggerName}
                </div>

                <div className="tx-13 tx-muted mb-1">
                  {t('order.current-trigger-value')}
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start flex-wrap pb-2 mb-2 border-bottom border-semi-transparent">
                  <FormattedNumber
                    value={currentData.value}
                    decimals={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? 25 : 6}
                    suffix={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? '$' : undefined}
                    postfix={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? undefined : baseToken}
                    subZeros
                  />
                </div>

                <div className="tx-13 tx-muted mb-1">
                  {t('order.target-trigger-value')}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                  <FormattedNumber
                    value={nextTriggerValue || targetTriggerValue}
                    decimals={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? 25 : 6}
                    suffix={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? '$' : undefined}
                    postfix={[OrderTrigger.PRICE_IN_USD, OrderTrigger.MCAP].includes(trigger) ? undefined : baseToken}
                    subZeros
                  />
                </div>

                {!!alphaData?.autoLevelStep && (
                  <>
                    <div className="tx-13 tx-muted mb-1 border-top border-semi-transparent pt-2 mt-2">
                      {t('order.alpha.intermediate-step')}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                      <FormattedNumber
                        value={alphaData.autoLevelStep}
                        postfix="%"
                      />
                    </div>
                  </>
                )}

                {!!alphaData?.lastLevelReachStrategy && (
                  <>
                    <div className="tx-13 tx-muted mb-1 border-top border-semi-transparent pt-2 mt-2">
                      {t('order.alpha.action-on-lower-threshold')}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                      {t('order.alpha.' +
                        alphaOrderStrategies.find(([_label, key]) => key === alphaData.lastLevelReachStrategy)?.[0]
                      )}
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="card bg-semi-transparent-10 mb-3 order-last">
              {!isCompleted && (
                <>
                  <div className="tx-13 tx-muted mb-1">
                    {t('order.created-at')}
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                    {format(new Date(createdAt), 'dd.MM.yyyy, HH:mm')}
                  </div>
                </>
              )}

              {isCompleted && (
                <>
                  <div className="tx-13 tx-muted mb-1">
                    {t('order.executed-at')}
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                    {format(new Date(updatedAt), 'dd.MM.yyyy, HH:mm')}
                  </div>
                </>
              )}
            </div>

            {isCompleted && (
              <div className="card bg-semi-transparent-10 mb-3 order-last">
                <div className="tx-13 tx-muted mb-1">{t('order.fee')}</div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start flex-wrap pb-2 mb-2 border-bottom border-semi-transparent">
                  <img
                    src={gas}
                    alt="gas"
                    width={24}
                    height={24}
                    className="me-1"
                  />
                  <FormattedNumber
                    value={fee.toNumber()}
                    decimals={6}
                    postfix={baseToken}
                  />
                  <FormattedNumber
                    value={feeInUsd.toNumber()}
                    suffix="($"
                    postfix=")"
                    decimals={4}
                    className={`ms-1`}
                    subZeros
                  />
                </div>

                <div className="tx-13 tx-muted mb-1">
                  {baseToken} {t('order.price')}
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start flex-wrap pb-2 mb-2 border-bottom border-semi-transparent">
                  1 {baseToken} =
                  <FormattedNumber
                    value={nativeTokenUsdPrice}
                    decimals={25}
                    suffix="$"
                    className="ms-1"
                  />
                </div>

                <div className="tx-13 tx-muted mb-1">{t('common.network')}</div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start flex-wrap pb-2 mb-2 border-bottom border-semi-transparent">
                  <img
                    src={chainLogo(blockchain)}
                    alt={chainName(blockchain)}
                    width={24}
                    height={24}
                    className="me-2"
                  />
                  {chainName(blockchain)}
                </div>

                <div className="tx-13 tx-muted mb-1">DEX</div>
                <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                  <img
                    src={dexLogo(dex)}
                    alt={dex}
                    width={24}
                    height={24}
                    className="me-2"
                  />
                  {dexName(dex)} {dexVersion(pairType)}
                </div>
              </div>
            )}

            <div className="card bg-semi-transparent-10 mb-3 order-last">
              <div className="tx-13 tx-muted mb-1">
                {t('order.your-wallet')}
              </div>
              <div className="pb-2 mb-2 border-bottom border-semi-transparent">
                <CopyButton
                  text={walletAddress}
                  className="d-flex align-items-center justify-content-between"
                  noIcon
                >
                  <span>{getEllipsisTxt(walletAddress, 4, '0x')}</span>
                  <img
                    src={copy}
                    alt="copy"
                    width={24}
                    height={24}
                    className="ms-auto"
                  />
                </CopyButton>
              </div>

              <div className="tx-13 tx-muted mb-1">
                {t('order.token-address')}
              </div>
              <div className="pb-2 mb-2 border-bottom border-semi-transparent">
                <CopyButton
                  text={tokenAddress}
                  className="d-flex align-items-center justify-content-between"
                  noIcon
                >
                  <span>{getEllipsisTxt(tokenAddress, 4, '0x')}</span>
                  <img
                    src={copy}
                    alt="copy"
                    width={24}
                    height={24}
                    className="ms-auto"
                  />
                </CopyButton>
              </div>

              <div className="tx-13 tx-muted mb-1">
                {t('order.pair-address')}
              </div>
              <div>
                <CopyButton
                  text={pairAddress}
                  className="d-flex align-items-center justify-content-between"
                  noIcon
                >
                  <span>{getEllipsisTxt(pairAddress, 4, '0x')}</span>
                  <img
                    src={copy}
                    alt="copy"
                    width={24}
                    height={24}
                    className="ms-auto"
                  />
                </CopyButton>
              </div>
            </div>

            {transactions.length > 0 && transactionsExecuted && (
              <div className="card bg-semi-transparent-10 mb-3 order-last">
                {approveTransaction && !!approveTransaction.hash && (
                  <>
                    <div className="tx-13 tx-muted mb-1">
                      {t('order.approve-transaction')}
                    </div>
                    <div>
                      <a
                        href={chainTxExplorer(
                          blockchain,
                          approveTransaction.hash,
                        )}
                        className="d-flex align-items-center justify-content-between text-decoration-none tx-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {getEllipsisTxt(approveTransaction.hash, 4, '0x')}
                        </span>
                        <img
                          src={arrow}
                          alt="arrow"
                          width={24}
                          height={24}
                          className="ms-auto"
                        />
                      </a>
                    </div>
                  </>
                )}
                {buyTransaction && !!buyTransaction.hash && (
                  <>
                    <div
                      className={`tx-13 tx-muted mb-1 ${approveTransaction ? 'pt-2 mt-2 border-top border-semi-transparent' : ''}`}
                    >
                      {t('order.buy-transaction')}
                    </div>
                    <div>
                      <a
                        href={chainTxExplorer(blockchain, buyTransaction.hash)}
                        className="d-flex align-items-center justify-content-between text-decoration-none tx-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {getEllipsisTxt(buyTransaction.hash, 4, '0x')}
                        </span>
                        <img
                          src={arrow}
                          alt="arrow"
                          width={24}
                          height={24}
                          className="ms-auto"
                        />
                      </a>
                    </div>
                  </>
                )}
                {sellTransaction && !!sellTransaction.hash && (
                  <>
                    <div
                      className={`tx-13 tx-muted mb-1 ${buyTransaction ? 'pt-2 mt-2 border-top border-semi-transparent' : ''}`}
                    >
                      {t('order.sell-transaction')}
                    </div>
                    <div>
                      <a
                        href={chainTxExplorer(blockchain, sellTransaction.hash)}
                        className="d-flex align-items-center justify-content-between text-decoration-none tx-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {getEllipsisTxt(sellTransaction.hash, 4, '0x')}
                        </span>
                        <img
                          src={arrow}
                          alt="arrow"
                          width={24}
                          height={24}
                          className="ms-auto"
                        />
                      </a>
                    </div>
                  </>
                )}
              </div>
            )}

            <div
              className={`card bg-semi-transparent-10 mb-3 ${isError ? 'order-1' : 'order-last'}`}
            >
              <div className="tx-13 tx-muted mb-1">
                {t('order.support-order-id')}
              </div>
              <div>
                <CopyButton
                  text={id}
                  className="d-flex align-items-center justify-content-between pos-relative overflow-hidden"
                  noIcon
                >
                  <span className="text-nowrap text-truncate">{id}</span>
                  <img
                    src={copy}
                    alt="copy"
                    width={24}
                    height={24}
                    className="ms-auto"
                  />
                </CopyButton>
              </div>
              <div className="mb-1 pt-2 mt-2 border-top border-semi-transparent">
                <AlphaLink
                  path={contactSupportLink}
                  className={`d-flex align-items-center justify-content-between pos-relative overflow-hidden ${!isError ? 'text-decoration-none tx-white' : 'tx-white'}`}
                >
                  <span className="text-nowrap text-truncate">
                    {t('common.contact-support')}
                  </span>
                  <img
                    src={arrow}
                    alt="arrow"
                    width={24}
                    height={24}
                    className="ms-auto"
                  />
                </AlphaLink>
              </div>
            </div>

            <div className="card bg-semi-transparent-10 mb-3 order-last">
              <LabelValueListItem
                label={t('trading.slippage')}
                value={
                  settings.slippage || settings.slippage === 0
                    ? settings.slippage
                    : 5
                }
                unit="%"
                borderBottom={!isTon}
              />
              {tpSettings && (
                <LabelValueListItem
                  label={t('trading.tp-slippage')}
                  value={
                    tpSettings.slippage || tpSettings.slippage === 0
                      ? tpSettings.slippage
                      : 5
                  }
                  unit="%"
                  borderBottom={!isTon}
                />
              )}
              {slSettings && (
                <LabelValueListItem
                  label={t('trading.sl-slippage')}
                  value={
                    slSettings.slippage || slSettings.slippage === 0
                      ? slSettings.slippage
                      : 5
                  }
                  unit="%"
                  borderBottom={!isTon}
                />
              )}
              {settings.solPriorityMode && (
                <LabelValueListItem
                  label={t('profile.solana-priority-mode')}
                  value={`${t(`common.${settings.solPriorityMode.toLowerCase()}`)}${settings.solPriorityMode === SolPriorityMode.CUSTOM ? ` (${settings.solPriorityFee} SOL)` : ''}`}
                  borderBottom
                />
              )}
              <LabelValueListItem
                className={isSettingHidden('extraGasPricePercent') ? 'd-none' : ''}
                label={t('order.extra-gas-price')}
                value={
                  settings.extraGasPricePercent ||
                  settings.extraGasPricePercent === 0
                    ? settings.extraGasPricePercent
                    : 10
                }
                unit="%"
                borderBottom
              />
              <LabelValueListItem
                className={isSettingHidden('antiMevEnabled') ? 'd-none' : ''}
                label={t('trading.mev-guard')}
                value={
                  settings.antiMevEnabled
                    ? t('order.enabled')
                    : t('order.disabled')
                }
                borderBottom
              />
              <LabelValueListItem
                className={isSettingHidden('autoApproveEnabled') ? 'd-none' : ''}
                label={t('trading.auto-approve')}
                value={
                  settings.autoApproveEnabled
                    ? t('order.enabled')
                    : t('order.disabled')
                }
              />
            </div>
          </div>

          <div className="my-4 tx-center">
            <div className={`tx-${statusColor}`}>{statusText}</div>
            {/*{status === Status.Failed && <div className="tx-danger">Canceled / Failed</div>}*/}
            <div className="tx-13 tx-muted">
              {t('order.created')}{' '}
              {format(new Date(createdAt), 'dd.MM.yyyy, HH:mm')}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LimitOrderDetails;
