import { useEffect, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import { useStores } from '@hooks/useStores';

interface Props {
  target?: string;
  key: string;
  isReady?: boolean;
}

const usePersistentScroll = ({ target = 'main-layout', key, isReady = true }: Props) => {
  const { settingsStore } = useStores();
  const [scrollInit, setScrollInit] = useState<boolean>(false);
  const [sc, setSc] = useState(0);
  const debounceScroll = useDebounce(sc, 10);

  useEffect(() => {
    const layout = document.getElementById(target);
    if (!scrollInit || !layout) return;

    const onScroll = () => {
      setSc(layout.scrollTop || 0);
      // settingsStore.setTradeScroll(layout.scrollTop);
    };

    layout.addEventListener('scroll', onScroll);

    return () => {
      layout.removeEventListener('scroll', onScroll);
      layout.scroll(0, 0);
    };
  }, [scrollInit, target]);

  useEffect(() => {
    if (scrollInit) {
      settingsStore.setPersistentScroll(key, debounceScroll);
    }
  }, [debounceScroll, key, scrollInit]);

  useEffect(() => {
    if (!isReady || scrollInit) return;

    document.getElementById(target)?.scrollTo(0, settingsStore.persistentScroll(key));
    setSc(settingsStore.persistentScroll(key));
    setScrollInit(true);
  }, [isReady, target, key, scrollInit]);
};

export default usePersistentScroll;
