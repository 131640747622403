import React, { useMemo } from 'react';
import cn from 'classnames';
import info from 'assets/icons/info-circle.svg';
import { observer } from 'mobx-react-lite';
import InputGroup from '@components/common/InputGroup';
import { AlphaOrderLastLevelReachStrategy } from 'types/order/order.enum';
import usePopup from '@hooks/usePopup';
import { useTranslation } from 'react-i18next';
import LevelStep from '@components/AlphaOrder/LevelStep';
import { useStores } from '@hooks/useStores';
import { AlphaOrderModel } from '@pages/LimitOrders/models/AlphaOrderModel';
import { kebabToPascal } from '@helpers/formatters';

import AutoCloseBuyRu from '@assets/images/alpha-order/auto-close-buy-ru.svg';
import AutoCloseBuyEn from '@assets/images/alpha-order/auto-close-buy-en.svg';
import AutoCloseSellRu from '@assets/images/alpha-order/auto-close-sell-ru.svg';
import AutoCloseSellEn from '@assets/images/alpha-order/auto-close-sell-en.svg';
import WaitReverseBuyRu from '@assets/images/alpha-order/waiting-reverse-buy-ru.svg';
import WaitReverseBuyEn from '@assets/images/alpha-order/waiting-reverse-buy-en.svg';
import WaitReverseSellRu from '@assets/images/alpha-order/waiting-reverse-sell-ru.svg';
import WaitReverseSellEn from '@assets/images/alpha-order/waiting-reverse-sell-en.svg';
import WaitBestPriceBuyRu from '@assets/images/alpha-order/waiting-best-buy-ru.svg';
import WaitBestPriceBuyEn from '@assets/images/alpha-order/waiting-best-buy-en.svg';
import WaitBestPriceSellRu from '@assets/images/alpha-order/waiting-best-sell-ru.svg';
import WaitBestPriceSellEn from '@assets/images/alpha-order/waiting-best-sell-en.svg';
import { alphaOrderStrategies } from '../../../../constants';
import { limitSign } from '@helpers/calculations';

type Props = {
  state: AlphaOrderModel;
};

const getIllustration = (action: string, isBuy: boolean, language: string) => {
  const illustrations = {
    AutoCloseBuyRu,
    AutoCloseBuyEn,
    AutoCloseSellRu,
    AutoCloseSellEn,
    WaitReverseBuyRu,
    WaitReverseBuyEn,
    WaitReverseSellRu,
    WaitReverseSellEn,
    WaitBestPriceBuyRu,
    WaitBestPriceBuyEn,
    WaitBestPriceSellRu,
    WaitBestPriceSellEn,
  };

  const lang = language.includes('ru') ? 'ru' : 'en';
  const type = isBuy ? 'buy' : 'sell';

  const asset: keyof typeof illustrations =
    kebabToPascal(action) + kebabToPascal(type) + kebabToPascal(lang);

  return illustrations[asset];
};

const SubtotalLevelsGroup = (props: Props) => {
  const { tokenTrade } = useStores();
  const { state } = props;
  const {
    errors,
    levelsQty,
    alphaLevels,
    lastLevelReachStrategy,
    trailingStep,
  } = state;
  const { isBuy, emptyInputsLightOn } = tokenTrade;
  const {
    levelOrderError,
    excessLimitError,
    levelsOutOfLimits,
    levelsEmptyError,
  } = errors;

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const showPopup = usePopup();

  const showStrategyInfo = (strategy: string) => {
    const [label, description] = [
      t(`order.alpha.${strategy}`),
      t(`order.alpha.${strategy}-description-${isBuy ? 'min' : 'max'}`),
    ];

    const src = getIllustration(strategy, isBuy, language);

    return showPopup({
      message: (
        <div className="d-flex flex-column gap-1 mb-3">
          <div className="tx-semibold mb-2">{label}</div>
          <div className="tx-13 tx-muted mb-2">{description}</div>
          <img {...{ src }} draggable="false" />
        </div>
      ),
      buttons: [
        {
          text: t('common.close'),
          type: 'secondary',
          id: 'cancel',
        },
      ],
    });
  };

  const disabled = useMemo(
    () =>
      !!(
        errors.excessLimitError ||
        errors.upperThresholdSignError ||
        errors.lowerThresholdSignError ||
        errors.thresholdRequiredError
      ),
    [
      errors.excessLimitError,
      errors.upperThresholdSignError,
      errors.lowerThresholdSignError,
      errors.thresholdRequiredError,
    ],
  );

  const hasErrors = useMemo(() => {
    return ![
      levelOrderError,
      excessLimitError,
      ...levelsOutOfLimits,
      ...levelsEmptyError,
    ].every((e) => [-1, false, null].includes(e));
  }, [errors]);

  return (
    <div className="d-flex flex-column gap-12px">
      <div className="tx-15 tx-semibold">
        {t('order.alpha.number-of-intermediate-levels')}
      </div>
      <div className="d-flex gap-2px">
        {Array.from(Array(11).keys())
          .slice(2)
          .map((n) => (
            <div
              className={cn([
                'tx-13 tx-semibold pd-x-2 pd-y-8 btn btn-semi-10 wd-100p',
                'rounded-2px',
                'bd-solid',
                'bd-1',
                disabled && 'disabled',
                n === 2 && 'rounded-l-8-px',
                n === 10 && 'rounded-r-8-px',
                n === levelsQty
                  ? 'btn-primary-10 bd-primary'
                  : 'btn-semi-10 bd-transparent',
                emptyInputsLightOn &&
                  errors.levelsQtyError &&
                  'btn-danger-10 bd-danger',
              ])}
              key={n}
              onClick={() => state.setLevelsQty(n)}
            >
              {n}
            </div>
          ))}
      </div>
      {levelsQty && (
        <div>
          <div className="d-flex gap-2 mb-1">
            <div className="d-flex wd-65p tx-13 tx-muted">
              {t('common.price')}
            </div>
            <div className="d-flex wd-35p tx-13 tx-muted">
              {t('signal.change')}
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            {(alphaLevels as Array<[string, number]>).map(
              ([usdValue, percentage], idx) => {
                const isInValid =
                  [
                    errors.levelOrderError,
                    ...errors.levelsOutOfLimits,
                    ...errors.levelsEmptyError,
                  ].includes(idx) && !disabled;

                return (
                  <div
                    className="d-flex gap-2"
                    key={`subtotal-level-${idx}-of-${levelsQty}`}
                  >
                    <div className="d-flex wd-65p">
                      <InputGroup
                        isInValid={isInValid}
                        disabled={![0, levelsQty - 1].includes(idx) && disabled}
                        inputClassName={cn([
                          'form-control',
                          'appearance-none',
                          'py-1',
                          'ht-32',
                          disabled && 'tx-muted',
                        ])}
                        unitClassName="ht-32"
                        inputMode="decimal"
                        onChange={(v) =>
                          state.editAlphaLevel(idx, [
                            limitSign(v, isBuy),
                            undefined,
                          ])
                        }
                        value={usdValue}
                        placeholder="0$"
                        unit="$"
                      />
                    </div>
                    <div className="d-flex wd-35p">
                      <InputGroup
                        isInValid={isInValid}
                        disabled={![0, levelsQty - 1].includes(idx) && disabled}
                        inputClassName={cn([
                          'form-control',
                          'appearance-none',
                          'py-1',
                          'ht-32',
                          disabled && 'tx-muted',
                        ])}
                        unitClassName="ht-32"
                        inputMode="decimal"
                        noCross
                        onChange={(v) =>
                          state.editAlphaLevel(idx, [
                            undefined,
                            limitSign(v, isBuy),
                          ])
                        }
                        value={percentage}
                        placeholder="0%"
                        unit="%"
                      />
                    </div>
                  </div>
                );
              },
            )}
          </div>

          {!errors.excessLimitError && hasErrors && (
            <div className="flex-row justify-content-between card bg-semi-danger-10 mt-2 pd-12 py-2">
              {errors.thresholdRequiredError ? (
                <span className="tx-13 tx-danger">
                  {t('order.alpha.thresholds-required-error')}
                </span>
              ) : (
                <>
                  <span className="tx-13 tx-danger">
                    {t('order.alpha.range-error')}
                  </span>
                  <span
                    onClick={() => state.setLevelsQty(levelsQty)}
                    className="tx-13 tx-danger tx-semibold text-decoration-underline"
                  >
                    {t('common.reset')}
                  </span>
                </>
              )}
            </div>
          )}

          <div className="d-flex flex-column gap-12px mg-t-12">
            <span className="tx-15 tx-semibold">
              {t('order.alpha.action-on-lower-threshold')}
            </span>
            <div className="d-flex flex-column gap-2">
              {alphaOrderStrategies.map(([label, key]) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={key}
                >
                  <div
                    className={cn(
                      'd-flex gap-2 align-items-center',
                      disabled && 'opacity-02',
                    )}
                    onClick={() =>
                      !disabled && state.setLastLevelReachStrategy(key)
                    }
                  >
                    <div
                      className={cn([
                        'check',
                        lastLevelReachStrategy === key && 'checked',
                      ])}
                    />
                    <span className="tx-15">{t(`order.alpha.${label}`)}</span>
                  </div>
                  <button
                    onClick={() => showStrategyInfo(label)}
                    className="d-flex btn pd-0"
                  >
                    <img src={info} alt="Info" width={20} height={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {lastLevelReachStrategy ===
            AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING && (
            <div className="d-flex flex-column gap-1 mg-t-12">
              <span className="tx-muted tx-13">
                {t('order.alpha.additional-intermediate-step')}
              </span>
              <LevelStep
                isInValid={emptyInputsLightOn && errors.trailingStepError}
                disabled={disabled}
                value={trailingStep!}
                onChange={(v) => state.setTrailingStep(v)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(SubtotalLevelsGroup);
