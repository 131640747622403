import React, { useEffect, useMemo, useState } from 'react';
import Canvas from '@components/common/Canvas';
import { format, formatDistanceToNow } from 'date-fns';
import { ChainId, ENV } from '../../constants';
import QRCode from 'qrcode.react';
import logo from '@assets/images/logo-dark.svg';
import profitBg from './profitBg.png';
import profitTron from './profitTron.png';
import { numberWithCommas } from '@helpers/numbers';
import { Blockchain } from '../../types/enums';
import { chainLogo } from '@helpers/chains';
import downloadURI from '@helpers/download';
import BigNumber from 'bignumber.js';

// interface Sources {
//   [key: string]: any;
// }

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

// function loadImages(
//   sources: any,
//   callback: (images: HTMLImageElement[]) => void,
// ) {
//   const images: Sources = {};
//   let loadedImages = 0;
//   let numImages = 0;
//   // get num of sources
//   for (const _src in sources) {
//     numImages++;
//   }
//   for (const src in sources) {
//     images[src] = new Image();
//     images[src].onload = () => {
//       if (++loadedImages >= numImages) {
//         callback(images as HTMLImageElement[]);
//       }
//     };
//     images[src].src = sources[src];
//     images[src].crossOrigin = '';
//   }
// }

interface ShareProfitProps {
  data: {
    asStory?: boolean;
    profit: string;
    profitUsd: string;
    duration?: string;
    token: string;
    isPositive: boolean;
    refId: string;
    chain: ChainId | Blockchain;
  } | null;
  onShare: () => void;
}

const ShareProfit = ({ data, onShare }: ShareProfitProps) => {
  const { asStory, profit, profitUsd, duration, token, isPositive, refId } =
    data || {
      profit: '',
      profitUsd: '',
      duration: '',
      token: '',
      isPositive: true,
      refId: '',
    };

  const [timeAgo, setTimeAgo] = useState<string>('');
  const [today, setToday] = useState<string>('');

  const formattedProfit = useMemo(() => {
    const bn = new BigNumber(profit);
    return (
      numberWithCommas(bn.toNumber(), true, bn.isGreaterThan(100) ? 0 : 2) + '%'
    );
    // return `${formatNumber(profit, '%', 2)}`;
    // return `${formatNumber(profit, '%', 2)} (${formatNumber(profitUsd, '', 2)}$)`;
  }, [profit]);

  useEffect(() => {
    if (data && duration) {
      setTimeAgo(
        formatDistanceToNow(new Date(duration), {
          includeSeconds: false,
          addSuffix: false,
        }),
      );
    } else {
      setTimeAgo('');
    }
    setToday(format(new Date(), 'dd MMM yyyy'));
  }, [data, duration]);

  const referralLink = useMemo(() => {
    return `${ENV.BOT_URL}?start=${refId}`;
  }, [refId]);

  const handleDraw = (
    context: CanvasRenderingContext2D,
    frameCount: number,
  ) => {
    //Our first draw
    context.fillStyle = '#627EEA';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.drawImage(
      document.getElementById('bg') as HTMLImageElement,
      0,
      0,
      750,
      520,
    );
    // const v = Canvg.fromString(context, testSvg);
    // v.start();

    // Token name
    context.font = 'bold 28px "SF Pro Display"';
    context.fillStyle = '#FFF';
    context.fillText(token, 100, 150);

    // Profit %
    context.font = 'bold 34px "SF Pro Display"';
    context.fillStyle = isPositive ? '#32D74B' : '#FF453A';
    context.fillText(formattedProfit, 145, 190);

    // Profit label
    context.font = 'bold 28px "SF Pro Display"';
    context.fillStyle = '#FFF';
    context.fillText('Profit: ', 60, 190);

    if (timeAgo) {
      // Profit label
      context.font = 'bold 28px "SF Pro Display"';
      context.fillStyle = '#FFF';
      context.fillText('Time: ' + timeAgo, 60, 230);
    }

    // Date
    context.font = '13px "SF Pro Display"';
    context.fillStyle = '#626262';
    context.fillText('Date: ' + today, 60, 330);

    // Referral link
    context.font = '13px "SF Pro Display"';
    context.fillStyle = '#626262';
    context.fillText(referralLink.replace('https://', ''), 132, 445);

    // var sources = {
    //   tokenLogo,
    // };

    // context.drawImage(document.getElementById('tokenLogo') as HTMLImageElement, 450, 75, 32, 32);
    context.drawImage(
      document.getElementById('qr-code') as HTMLCanvasElement,
      64,
      405,
      51,
      51,
    );

    // context.drawImage(document.getElementById('tokenLogo') as HTMLImageElement, 450, 75, 32, 32);
    context.drawImage(
      document.getElementById('chain') as HTMLCanvasElement,
      60,
      123,
      35,
      35,
    );

    // if (!logoDrawn) {
    //   loadImages(sources, function(images) {
    //     // @ts-ignore
    //     // context.drawImage(images['testSvgFile'], 0, 0, 750, 520);
    //     // @ts-ignore
    //     // context.drawImage(images['tokenLogo'], 450, 75, 32, 32);
    //     setLogoDrawn(true);
    //   });
    // }
  };

  const handleDownload = (dataUrl: string) => {
    downloadURI(dataUrl, token + '-profit.png');
    // onShare();
  };

  const handleClick = () => {
    if (asStory) return;
    const dataUrl = (
      document.getElementById('share-canvas') as HTMLCanvasElement
    )?.toDataURL('image/png');
    dataUrlToFile(dataUrl, token + '-profit.png').then(async (file) => {
      try {
        if (
          typeof navigator.share !== 'undefined' &&
          navigator.canShare({ files: [file] })
        ) {
          const shareData = {
            files: [file],
          };
          try {
            await navigator.share(shareData);
            onShare();
          } catch (err) {
            handleDownload(dataUrl);
          }
        } else {
          handleDownload(dataUrl);
        }
      } catch (err) {
        handleDownload(dataUrl);
      }
    });
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        handleClick();
      }, 100);
    }
  }, [data]);

  return (
    <div className="d-none">
      {data?.chain === Blockchain.TRON && <img
        src={profitTron}
        id="bg"
      />}
      {data?.chain !== Blockchain.TRON && <img
        src={profitBg}
        id="bg"
      />}
      <img
        src={chainLogo(data?.chain || ChainId.ALL)}
        id="chain"
        style={{ display: 'none' }}
      />
      <div className="bg-white rounded-10 d-flex p-4 qr-code my-4 wd-70p mx-auto pos-relative">
        <QRCode
          value={referralLink}
          size={200}
          bgColor="#FFFFFF"
          fgColor="#252525"
          imageSettings={{ src: logo, excavate: true, width: 49, height: 37 }}
          id="qr-code"
        />
        <img
          src={logo}
          alt="Alpha Wallet"
          className="qr-code-logo"
          id="qr-logo"
        />
      </div>
      <Canvas
        draw={handleDraw}
        width={750}
        height={520}
        onClick={handleClick}
        id="share-canvas"
        className=""
      />
    </div>
  );
};

export default ShareProfit;
