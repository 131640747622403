import React, { useMemo, useState } from 'react';
import HistoryItem from '@pages/Wallet/components/HistoryItem';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import {
  ApiWalletTransactionsData,
  OperationType,
  Status,
} from '../../../types/transactions.types';
import TransactionDetails from '@pages/Wallet/components/TransactionDetails';
import { ChainId } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { PortfolioTransaction, PortfolioTransactionStatus, PortfolioTransactionType } from '../../../types';
import { Blockchain } from '../../../types/enums';

const spamWords: string[] = [
  'claim',
  'clalm',
  'СLАlМ',
  'official',
  'website',
  'link',
  'reward',
  'giveaway',
  'free',
  'airdrop',
  'promotion',
  'bonus',
  'discount',
  'sale',
  'offer',
  'prize',
  'win',
  'lottery',
  'entry',
  'ticket',
  'www',
  'bonus',
  'promo',
  'give',
  'visit ',
  'to swap',
  'swap to',
  '.xyz',
  '.com',
  '.net',
  '.bi',
  '.pay',
  '.org',
  '.info',
  '.io',
  '.us',
  '.co',
  'pay.',
  'pay:',
  'pay,',
  'pay ',
  '.c0m',
  'unknown',
  'unclaimed',
  'claimable',
].map((v) => v.toLowerCase());

interface TxWithSpam extends PortfolioTransaction {
  isSpam: boolean;
}

interface TimeGroup {
  time: string;
  group: Array<TxWithSpam>;
  chains: Blockchain[];
  allSpam: boolean;
}

interface HistoryProps {
  network?: Blockchain;
}

const operations = [PortfolioTransactionType.Receive, PortfolioTransactionType.Send];

const History = observer(({ network = Blockchain.ETHEREUM }: HistoryProps) => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { accountStore, walletStore } = useStores();
  const { isSolana } = accountStore;
  const { transactions } = walletStore;
  // const [times, setTimes] = useState<string[]>([]);
  // const [groups, setGroups] = useState<Array<ApiWalletTransactionsData[]>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedTx, setSelectedTx] =
    useState<PortfolioTransaction | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);

  const filtered = useMemo(() => {
    return transactions;
    // if (!isSolana) return transactions;
    // return transactions.filter((item) =>
    //   !operations.includes(item.type)
    //     ? true
    //     : item.transferred.every((t) => t.quantity > 0),
    // );
  }, [transactions, isSolana]);

  const networkFiltered = useMemo(() => {
    return filtered.filter((item) => item.blockchain === network);
  }, [filtered, network]);

  const spamFiltered = useMemo(() => {
    return networkFiltered.filter(({tokenSent, tokenApproved, tokenReceived}) => {
      return !spamWords.some((word) =>
        [tokenSent, tokenApproved, tokenReceived].some(
          (t) => t?.symbol?.toLowerCase().includes(word),
        ),
      );
    });
  }, [networkFiltered]);

  const sorted = useMemo(
    () =>
      filtered.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }),
    [filtered],
  );

  const queue = useMemo(
    () => sorted.filter((item) => item.status === PortfolioTransactionStatus.Pending),
    [sorted],
  );

  const sortedIsSpam = useMemo(() => {
    return sorted.map((item) => {
      const {tokenSent, tokenApproved, tokenReceived} = item;
      return {
        ...item,
        isSpam: spamWords.some((word) =>
          [tokenSent, tokenApproved, tokenReceived].some(
            (t) => t?.symbol?.toLowerCase().includes(word),
          ),
        ),
      };
    });
  }, [sorted]);

  const timeGroups: TimeGroup[] = useMemo(() => {
    const times: string[] = [];
    const groups: Array<TxWithSpam[]> = [];
    const chains: Array<Blockchain[]> = [];
    sorted.forEach((tx) => {
      if (tx.status === PortfolioTransactionStatus.Pending) return;
      const {tokenSent, tokenApproved, tokenReceived} = tx;
      const item: TxWithSpam = {
        ...tx,
        isSpam: spamWords.some((word) =>
          [tokenSent, tokenApproved, tokenReceived].some(
            (t) => t?.symbol?.toLowerCase().includes(word),
          ),
        ),
      };
      const time = format(new Date(item.date), 'dd.MM.yyyy');
      if (!times.includes(time)) {
        times.push(time);
        groups.push([item]);
        chains.push([item.blockchain]);
      } else {
        const index = times.indexOf(time);
        groups[index].push(item);
        if (!chains[index].includes(item.blockchain)) {
          chains[index].push(item.blockchain);
        }
      }
    });
    return times.map((t, i) => ({
      time: t,
      chains: chains[i],
      group: groups[i],
      allSpam: groups[i].every((item) => item.isSpam),
    }));
  }, [sorted]);

  return (
    <div>
      {queue.length > 0 && (
        <div>
          <div className="tx-17 tx-semibold mb-3">
            {t('common.queue')} ({queue.length})
          </div>
          {queue.map((item, index) => (
            <HistoryItem
              item={item}
              key={`token-history-item-${item.hash}-${index}`}
              onClick={() => setSelectedTx(item)}
            />
          ))}
        </div>
      )}

      <div>
        <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
          <div className="tx-17 tx-semibold">{t('common.history')}</div>

          {spamFiltered.length !== sorted.length && (
            <button
              className="btn btn-link tx-primary tx-right px-0 text-decoration-none"
              onClick={w(
                () => setShowAll(!showAll),
                AmplitudeEvent.SPAM_TOGGLED_HISTORY,
              )}
            >
              {showAll
                ? t('wallet.hide-spam')
                : t('wallet.show-all-spam', {
                    hiddenCount: sorted.length - spamFiltered.length,
                  })}
            </button>
          )}
        </div>
        {sortedIsSpam.map((item, index) => (
          <HistoryItem
            item={item}
            key={`token-history-item-${item.hash}-${index}`}
            onClick={w(
              () => setSelectedTx(item),
              AmplitudeEvent.HISTORY_ITEM_CLICKED,
            )}
            hide={
              (item.isSpam && !showAll) ||
              (item.blockchain !== network)
            }
          />
        ))}
        {/*{timeGroups.map(({time, group, allSpam, chains}, index) => (*/}
        {/*  <div key={`history-time-group-${time}-${index}`} className={`mb-5 ${(allSpam && !showAll) || (network !== ChainId.ALL && !chains.includes(network)) ? 'd-none' : ''}`}>*/}
        {/*    <div className="tx-center tx-12 tx-muted my-2">*/}
        {/*      {time}*/}
        {/*    </div>*/}
        {/*    {group.map((item, groupIndex) => (*/}
        {/*      <HistoryItem*/}
        {/*        item={item}*/}
        {/*        isLast={groupIndex === group.length - 1}*/}
        {/*        key={`token-history-item-${item.transactionHash}-${index}`}*/}
        {/*        onClick={() => setSelectedTx(item)}*/}
        {/*        hide={(spamFiltered.length !== transactions.length && item.isSpam && !showAll) || (network !== ChainId.ALL && item.chain !== network)}*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>

      {selectedTx && (
        <TransactionDetails
          item={selectedTx}
          onClose={() => setSelectedTx(null)}
        />
      )}
    </div>
  );
});

export default History;
