import React from 'react';
import Placeholder from '@components/common/Placeholder';

interface ListItemSkeletonProps {
  noCircle?: boolean;
}

export default function ListItemSkeleton({ noCircle = false }: ListItemSkeletonProps) {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between">
      {!noCircle && <Placeholder width={44} height={44} className="me-2" circle />}
      <div className="d-flex flex-column justify-content-between align-items-start me-auto">
        <Placeholder width={83} />
        <Placeholder width={62} height={12} />
      </div>
      <div className="d-flex flex-column justify-content-between align-items-end">
        <Placeholder width={83} />
        <Placeholder width={62} height={12} />
      </div>
    </div>
  )
}
