import React, { useCallback } from 'react';
import TokenLogo from '@components/common/TokenLogo';
import verified from '@assets/icons/verified.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import { useNavigate } from 'react-router-dom';
import { IAsset } from '../../types';
import { PortfolioToken } from 'types/portfolio.model';
import BigNumber from 'bignumber.js';
import tokenNameClass from '@helpers/tokenNameClass';
import AlphaLink from '@components/common/AlphaLink';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import cn from 'classnames';
import { ChainId } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';

interface ListItemProps {
  asset: PortfolioToken;
  isInline?: boolean;
  inlineChange?: number;
  hide?: boolean;
  chartLink: string;
}

const ListItem = ({
  asset,
  isInline,
  inlineChange,
  hide,
  chartLink,
}: ListItemProps) => {
  const navigate = useNavigate();
  const [w] = useAmplitude();
  const { accountStore } = useStores();
  const { network } = accountStore;
  const { name, address, symbol, price, balance, balanceDiff, image } = asset;

  const handleNavigate = useCallback(() => {
    if (isInline) return;

    navigate(`/asset/${address}`);
  }, [isInline, address, navigate]);

  const inlineChangeInUsd = new BigNumber(inlineChange || 0)
    .dividedBy(100)
    .multipliedBy(balance.token)
    .toNumber();

  return (
    <div
      className={`${hide ? 'd-none' : 'd-flex'} justify-content-between align-items-center wd-100p pb-3 ${isInline ? '' : 'pt-3 border-muted-10 cur-pointer with-hover'}`}
      onClick={w(handleNavigate, AmplitudeEvent.ASSET_CLICKED_MAIN_SCREEN)}
    >
      <TokenLogo
        logo={image}
        address={address}
        name={name}
        size={45}
        chain={network}
      />

      <div
        className={tokenNameClass(
          name,
          'd-flex flex-column align-items-start ms-2 me-auto',
        )}
      >
        <div className="tx-17 mb-1">
          {name}
          {/* {flags.verified && (
            <img src={verified} alt="Verified" className="ms-1" />
          )} */}
          {isInline && !!address && !address.includes('0x000000000') && (
            <AlphaLink
              path={chartLink}
              className="tx-16 lh-1 ms-1 text-decoration-none"
            >
              📈
            </AlphaLink>
          )}
        </div>
        <FormattedNumber
          value={balance.token}
          postfix={symbol}
          subZeros
          useFormat={new BigNumber(balance.token).isGreaterThan(10000)}
          className="tx-13 tx-muted word-break"
        />
      </div>

      <div className="ms-auto me-0 d-flex flex-column align-items-end tx-right mn-wd-35p">
        <div className="tx-17">
          <FormattedNumber
            suffix="$"
            value={isInline ? price : (balance.usd && balance.usd !== 'null' ? balance.usd : 0)}
            decimals={isInline ? 6 : undefined}
            subZeros
            className="word-break"
          />
        </div>
        <div
          className={cn([
            'tx-13',
            (isInline && inlineChange === undefined) && 'd-none',
            (!isInline ? balanceDiff.usd === null : inlineChangeInUsd === null)
              ? 'd-none'
              : '',
            (!isInline ? (balanceDiff.usd ?? 0) >= 0 : inlineChangeInUsd >= 0)
              ? 'tx-success'
              : 'tx-danger',
          ])}
        >
          {!isInline ? (
            <>
              <FormattedNumber
                value={balanceDiff.usd}
                postfix="$"
                className={`word-break`}
                withSign={balanceDiff.usd !== 0}
                hideNull
                subZeros
              />
              <span className="mx-1">•</span>
              <FormattedNumber
                value={balanceDiff.percents}
                postfix="%"
                className={`word-break`}
                hideNull
              />
            </>
          ) : (
            network !== ChainId.SUI && (
              <>
                <FormattedNumber
                  value={inlineChangeInUsd}
                  postfix="$"
                  className={`word-break`}
                  withSign={inlineChangeInUsd !== 0}
                  subZeros
                  hideNull
                />
                <span className="mx-1">•</span>
                <FormattedNumber
                  value={inlineChange}
                  postfix="%"
                  className={`word-break`}
                  hideNull
                />
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ListItem);
